const FeaturGroupCodes = {
  ToGo: "TOGO",
  FrozenRange: "FROZEN_RANGE",
  Supplements: "SUPPLEMENTS",
  F45: "F45",
  Affiliate: "AFFILIATE",
  WeeklyPayment: "WEEKLY_PAYMENT",
  Flexi: "FLEXI",
  ApplePay: "APPLEPAY",
  Ambassador: "AMBASSADOR",
  FoodBox: "FOOD_BOX",
}
export default FeaturGroupCodes;