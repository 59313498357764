import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { DeliveryScheduleDto } from '../../types/delivery-dto.types';
import { PaymentPlan } from '../../types/fff.enums';
import { NutritionCalculatorValuesDto } from '../../types/macro-dto.types';
import { AddressDto, DeliveryInfoStagingDto, ExtraCategorySelectionsMap, MealPlanDto, MetadataQuantityMap, PaymentOption } from '../../types/order-dto.types';
import { MetadataDictionary, OrderStaging } from '../../types/order-store.types';
import { PostcodeCheckDto } from '../../types/postcode-dto.types';
import { MIN_MEAL_COUNT } from './../../constants';
import { OrderEditState } from './order-edit.types';

const store = (set: any, get: any) => ({
  // values
  includeBreakfast: true,
  includeVeggieMeals: false,
  dailyCalories: 0,
  billingAddressSameAsDelivery: true,
  isTermsAndConditionsChecked: false,
  filteredMetadata: {} as MetadataDictionary,

  // actions: setters
  updateMealExtras: (extraCategoryHeaderCode: string, mealExtraId: string, quantity: number) => set((state: OrderEditState) => (
    {
      mealExtras: {
        ...state.mealExtras,
        [extraCategoryHeaderCode]: [...(state.mealExtras && state.mealExtras[extraCategoryHeaderCode] || []).filter(e => e != mealExtraId), ...Array(quantity).fill(mealExtraId)]
      },
      mealExtraQuantities: {
        ...state.mealExtraQuantities,
        [mealExtraId]: quantity
      }
    }
  )),
  setMealExtras: (mealExtras: ExtraCategorySelectionsMap) => set(() => ({
    mealExtras: mealExtras
  })),
  setMealExtraQuantities: (mealExtraQuantities: MetadataQuantityMap) => set(() => ({
    mealExtraQuantities: mealExtraQuantities
  })),
  setExclusionIds: (exclusionIdList: string[]) => set(() => ({
    exclusionIds: exclusionIdList
  })),
  setMealCount: (mealCount?: number) => set(() => ({
    mealCount: mealCount
  })),
  setIncludeBreakfast: (includeBreakfast: boolean) => set(() => ({
    includeBreakfast: includeBreakfast
  })),
  setIncludeVeggieMeals: (includeVeggieMeals: boolean) => set(() => ({
    includeVeggieMeals: includeVeggieMeals
  })),
  setRegularCalorieAllowance: (regularCalories?: number) => set(() => ({
    regularCalorieAllowance: regularCalories
  })),
  setDerivedCalories: (derivedCalories: number) => set(() => ({
    derivedCalories: derivedCalories
  })),
  setIsTimedDelivery: (isTimedDelivery?: boolean) => set(() => ({
    isTimedDelivery: isTimedDelivery
  })),
  setMealPlanInfo: (mealPlanInfo: MealPlanDto) => set((state: OrderEditState) => ({
    mealCount: mealPlanInfo.mealsPerday,
    includeVeggieMeals: mealPlanInfo.includeVeggieMeals || state.includeVeggieMeals,
    dailyCalories: mealPlanInfo.dailyCalories,
    derivedCalories: mealPlanInfo.derivedCalories,
    regularCalorieAllowance: mealPlanInfo.regularCalories,
    customMealOptionId: mealPlanInfo.customMealOptionId,
    nutritionPreferenceId: mealPlanInfo.nutritionPreferenceId,
  })),
  setNutritionDetails: (values: NutritionCalculatorValuesDto) => set(() => ({
    heightInCm: values.heightInCm,
    weightInKg: values.weightInKg,
    heightInFt: values.heightInFt,
    weightInLbs: values.weightInLbs,
    genderId: values.genderId,
    birthDay: values.birthDay,
    goalId: values.goalId,
    nutritionPreferenceId: values.nutritionPreferenceId,
    activeLevelId: values.activeLevelId,
    workoutHoursPerWeekId: values.workoutHoursPerWeekId,
    isWorkingOut: values.isWorkingOut,
    dailyCalories: values.dailyCalories,
    derivedCalories: values.derivedCalories,
    dailyProteinInGrams: values.dailyProteinInGrams,
    dailyCarbsInGrams: values.dailyCarbsInGrams,
    dailyFatInGrams: values.dailyFatInGrams,
    dailyAdditionalCalories: values.dailyAdditionalCalories,
    knownMacros: values.knownMacros,
    maternityStatusId: values.maternityStatusId,
    maternityStatus: values.maternityStatus,
    pregnancyStatusId: values.pregnancyStatusId,
    breastfeedingStatusId: values.breastfeedingStatusId,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    subscribeToNewsletter: values.subscribeToNewsletter,
    emailMarketingConsentMessage: values.emailMarketingConsentMessage,
    emailMarketingConsentDate: values.emailMarketingConsentDate,
    subscribeToSmsMarketing: values.subscribeToSmsMarketing,
    smsMarketingConsentMessage: values.smsMarketingConsentMessage,
    smsMarketingConsentDate: values.smsMarketingConsentDate,
  })),
  setCustomMealOptionId: (customMealOptionId?: string) => set(() => ({
    customMealOptionId: customMealOptionId,
  })),
  setPostcodeDetails: (postcodeDetails?: PostcodeCheckDto) => set(() => ({
    postcodeDetails: postcodeDetails
  })),
  setDeliveryAddress: (address?: AddressDto) => set(() => ({
    deliveryAddress: address
  })),
  setAddressDisplayName: (displayName?: string) => set(() => ({
    addressDisplayName: displayName
  })),
  setDeliveryInstructions: (deliveryInstructions?: string) => set(() => ({
    deliveryInstructions: deliveryInstructions
  })),
  setPhoneNumber: (phoneNumber?: string) => set(() => ({
    phoneNumber: phoneNumber
  })),
  setDeliveryInfoId: (deliveryInfoId?: string) => set(() => ({
    deliveryInfoId: deliveryInfoId
  })),
  setPaymentPlan: (paymentPlan?: PaymentPlan) => set(() => ({
    paymentPlan: paymentPlan
  })),
  setPaymentOption: (paymentOption?: PaymentOption) => set(() => ({
    paymentOption: paymentOption
  })),
  setPackageId: (packageId?: string) => set(() => ({
    packageId: packageId
  })),
  setPackageCount: (packageCount?: number) => set(() => ({
    packageCount: packageCount
  })),
  setDeliverySchedule: (deliverySchedule?: DeliveryScheduleDto) => set(() => ({
    deliverySchedule: deliverySchedule
  })),
  setFirstDeliveryDate: (firstDeliveryDate?: Date) => set(() => ({
    firstDeliveryDate: firstDeliveryDate
  })),

  setReferral: (referral: string) => set(() => ({
    referral: referral
  })),
  setReferralSecondOption: (referralSecondOption: string) => set(() => ({
    referralSecondOption: referralSecondOption
  })),
  setDiscountCode: (discountCode?: string) => set(() => ({
    discountCode: discountCode
  })),
  setTokens: (selectedTokens?: number) => set(() => ({
    selectedTokens: selectedTokens
  })),
  setMaximumApplicableTokens: (maximumApplicableTokens?: number) => set(() => ({
    maximumApplicableTokens: maximumApplicableTokens
  })),
  setTermsAndConditionsChecked: (isTermsAndConditionsChecked: boolean) => set(() => ({
    isTermsAndConditionsChecked: isTermsAndConditionsChecked
  })),

  setBillingAddress: (address?: AddressDto) => set(() => ({
    billingAddress: address
  })),
  setIsBillingAddressSameAsDelivery: (isBillingAddressSameAsDelivery: boolean) => set(() => ({
    billingAddressSameAsDelivery: isBillingAddressSameAsDelivery
  })),
  setFilteredMetadata: (filteredMetadata: any) => set(() => ({
    filteredMetadata: filteredMetadata
  })),

  // actions: update
  clearDeliveryViewData: () => set({
    postcodeDetails: undefined,
    deliveryAddress: undefined,
    isTimedDelivery: undefined,
    paymentPlan: undefined,
    paymentOption: undefined,
    packageId: undefined,
    packageCount: undefined,
    deliverySchedule: undefined,
    firstDeliveryDate: undefined,
    deliveryInfoId: undefined,
  }),
  clearAllMealExtras: () => set(() => ({
    mealExtras: {},
    mealExtraQuantities: {}
  })),
  clearExclusionIds: () => set(() => ({
    exclusionIds: []
  })),
  setOrderEditDetailsByStagedOrder: (stagedOrder: OrderStaging) => set(() => ({
    firstName: stagedOrder.firstName,
    lastName: stagedOrder.lastName,
    email: stagedOrder.email,
    phoneNumber: stagedOrder.phoneNumber,
    heightInCm: stagedOrder.heightInCm,
    weightInKg: stagedOrder.weightInKg,
    heightInFt: stagedOrder.heightInFt,
    weightInLbs: stagedOrder.weightInLbs,
    genderId: stagedOrder.genderId,
    birthDay: stagedOrder.birthDay,
    goalId: stagedOrder.goalId,
    nutritionPreferenceId: stagedOrder.nutritionPreferenceId,
    activeLevelId: stagedOrder.activeLevelId,
    exclusionIds: stagedOrder.exclusionIds,
    workoutHoursPerWeekId: stagedOrder.workoutHoursPerWeekId,
    subscribeToNewsletter: stagedOrder.subscribeToNewsletter,
    emailMarketingConsentMessage: stagedOrder.emailMarketingConsentMessage,
    emailMarketingConsentDate: stagedOrder.emailMarketingConsentDate,
    subscribeToSmsMarketing: stagedOrder.subscribeToSmsMarketing,
    smsMarketingConsentMessage: stagedOrder.smsMarketingConsentMessage,
    smsMarketingConsentDate: stagedOrder.smsMarketingConsentDate,
    isWorkingOut: stagedOrder.isWorkingOut,
    mealCount: stagedOrder.mealsPerday || MIN_MEAL_COUNT,
    customMealOptionId: stagedOrder.customMealOptionId,
    dailyCalories: stagedOrder.dailyCalories,
    derivedCalories: stagedOrder.derivedCalories,
    dailyProteinInGrams: stagedOrder.dailyProteinInGrams,
    dailyCarbsInGrams: stagedOrder.dailyCarbsInGrams,
    dailyFatInGrams: stagedOrder.dailyFatInGrams,
    dailyAdditionalCalories: stagedOrder.dailyAdditionalCalories,
    includeBreakfast: stagedOrder.includeBreakfast == undefined ? true : stagedOrder.includeBreakfast,
    includeVeggieMeals: stagedOrder.includeVeggieMeals == undefined ? false : stagedOrder.includeVeggieMeals,
    mealExtras: stagedOrder.mealExtrasIdsByCategory,
    mealExtraQuantities: stagedOrder.mealExtrasCountById,
    knownMacros: stagedOrder.knownMacros,
    packageId: stagedOrder.packageId,
    firstDeliveryDate: stagedOrder.firstDeliveryDate,
    isTimedDelivery: stagedOrder.isTimedDelivery,
    billingAddressSameAsDelivery: stagedOrder.billingAddressSameAsDelivery,
    isTermsAndConditionsChecked: stagedOrder.isTermsAndConditionsChecked,
    deliverySchedule: stagedOrder.deliveryScheduleStaging,
    discountCode: stagedOrder.discountCode,
    paymentOption: stagedOrder.paymentOption,
    packageCount: stagedOrder.packageCount,
    regularCalorieAllowance: stagedOrder.regularCalories,
    paymentPlan: stagedOrder.paymentPlan,
    maternityStatusId: stagedOrder.maternityStatusId,
    pregnancyStatusId: stagedOrder.pregnancyStatusId,
    breastfeedingStatusId: stagedOrder.breastfeedingStatusId,
    deliveryInfoId: stagedOrder.deliveryInfoId,
    deliveryAddress: stagedOrder.deliveryAddress,
  })),
  resetAllData: () => set({
    mealExtras: undefined,
    mealExtraQuantities: undefined,
    exclusionIds: undefined,
    mealCount: MIN_MEAL_COUNT,
    includeBreakfast: true,
    includeVeggieMeals: false,
    dailyCalories: 0,
    dailyProteinInGrams: undefined,
    dailyCarbsInGrams: undefined,
    dailyFatInGrams: undefined,
    dailyAdditionalCalories: undefined,
    regularCalorieAllowance: undefined,
    isTimedDelivery: undefined,
    billingAddressSameAsDelivery: true,
    billingAddress: undefined,
    isTermsAndConditionsChecked: false,
    customMealOptionId: undefined,
    goalId: undefined,
    nutritionPreferenceId: undefined,
    knownMacros: undefined,
    activeLevelId: undefined,
    workoutHoursPerWeekId: undefined,
    isWorkingOut: undefined,
    maternityStatusId: undefined,
    maternityStatus: undefined,
    pregnancyStatusId: undefined,
    breastfeedingStatusId: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    subscribeToNewsletter: false,
    emailMarketingConsentMessage: undefined,
    emailMarketingConsentDate: undefined,
    subscribeToSmsMarketing: false,
    smsMarketingConsentMessage: undefined,
    smsMarketingConsentDate: undefined,
    genderId: undefined,
    birthDay: undefined,
    heightInCm: undefined,
    weightInKg: undefined,
    heightInFt: undefined,
    weightInLbs: undefined,
    postcodeDetails: undefined,
    deliveryAddress: undefined,
    addressDisplayName: undefined,
    deliveryInstructions: undefined,
    phoneNumber: undefined,
    deliveryInfoId: undefined,
    paymentPlan: undefined,
    paymentOption: undefined,
    packageId: undefined,
    packageCount: undefined,
    deliverySchedule: undefined,
    firstDeliveryDate: undefined,
    referral: undefined,
    referralSecondOption: undefined,
    discountCode: undefined,
    selectedTokens: undefined,
    maximumApplicableTokens: undefined,
    filteredMetadata: {} as MetadataDictionary
  }),

  // actions: getters
  getSelectedMealExtraIds: () => {
    const mealExtras = get().mealExtras;
    let mealExtrasIds: string[] = [];
    for (let key in mealExtras) {
      if (mealExtras.hasOwnProperty(key)) {
        mealExtrasIds = [...mealExtrasIds, ...mealExtras[key]];
      }
    }
    return mealExtrasIds;
  },
  getMealPlanInfo: () => {
    const mealPlanInfo: MealPlanDto = {
      mealsPerday: get().mealCount,
      includeBreakfast: get().includeBreakfast,
      includeVeggieMeals: get().includeVeggieMeals,
      dailyCalories: get().dailyCalories,
      derivedCalories: get().derivedCalories,
      regularCalories: get().regularCalorieAllowance,
      customMealOptionId: get().customMealOptionId,
      nutritionPreferenceId: get().nutritionPreferenceId
    };
    return mealPlanInfo;
  },
  getNutritionViewDetails: () => {
    return {
      heightInCm: get().heightInCm,
      weightInKg: get().weightInKg,
      heightInFt: get().heightInFt,
      weightInLbs: get().weightInLbs,
      genderId: get().genderId,
      birthDay: get().birthDay,
      goalId: get().goalId,
      nutritionPreferenceId: get().nutritionPreferenceId,
      activeLevelId: get().activeLevelId,
      workoutHoursPerWeekId: get().workoutHoursPerWeekId,
      isWorkingOut: get().isWorkingOut,
      dailyCalories: get().dailyCalories,
      derivedCalories: get().derivedCalories,
      dailyProteinInGrams: get().dailyProteinInGrams,
      dailyCarbsInGrams: get().dailyCarbsInGrams,
      dailyFatInGrams: get().dailyFatInGrams,
      dailyAdditionalCalories: get().dailyAdditionalCalories,
      knownMacros: get().knownMacros,
      maternityStatusId: get().maternityStatusId,
      maternityStatus: get().maternityStatus,
      pregnancyStatusId: get().pregnancyStatusId,
      breastfeedingStatusId: get().breastfeedingStatusId,
      firstName: get().firstName,
      lastName: get().lastName,
      email: get().email,
      subscribeToNewsletter: get().subscribeToNewsletter,
      emailMarketingConsentMessage: get().emailMarketingConsentMessage,
      emailMarketingConsentDate: get().emailMarketingConsentDate,
      subscribeToSmsMarketing: get().subscribeToSmsMarketing,
      smsMarketingConsentMessage: get().smsMarketingConsentMessage,
      smsMarketingConsentDate: get().smsMarketingConsentDate,
    } as NutritionCalculatorValuesDto;
  },
  getStagedOrderDetails: () => {
    return {
      firstName: get().firstName,
      lastName: get().lastName,
      email: get().email,
      phoneNumber: get().phoneNumber,
      postcode: (get().postcodeDetails || {}).formattedPostcode,
      heightInCm: get().heightInCm,
      weightInKg: get().weightInKg,
      heightInFt: get().heightInFt,
      weightInLbs: get().weightInLbs,
      genderId: get().genderId,
      birthDay: get().birthDay,
      goalId: get().goalId,
      nutritionPreferenceId: get().nutritionPreferenceId,
      activeLevelId: get().activeLevelId,
      exclusionIds: get().exclusionIds,
      workoutHoursPerWeekId: get().workoutHoursPerWeekId,
      subscribeToNewsletter: get().subscribeToNewsletter,
      emailMarketingConsentMessage: get().emailMarketingConsentMessage,
      emailMarketingConsentDate: get().emailMarketingConsentDate,
      subscribeToSmsMarketing: get().subscribeToSmsMarketing,
      smsMarketingConsentMessage: get().smsMarketingConsentMessage,
      smsMarketingConsentDate: get().smsMarketingConsentDate,
      isWorkingOut: get().isWorkingOut,
      mealsPerday: get().mealCount,
      customMealOptionId: get().customMealOptionId,
      dailyCalories: get().dailyCalories,
      derivedCalories: get().derivedCalories,
      dailyProteinInGrams: get().dailyProteinInGrams,
      dailyCarbsInGrams: get().dailyCarbsInGrams,
      dailyFatInGrams: get().dailyFatInGrams,
      dailyAdditionalCalories: get().dailyAdditionalCalories,
      includeBreakfast: get().includeBreakfast,
      includeVeggieMeals: get().includeVeggieMeals,
      mealExtrasIds: get().getSelectedMealExtraIds(),
      knownMacros: get().knownMacros,
      packageId: get().packageId,
      referral: get().referral,
      referralName: get().referralSecondOption,
      firstDeliveryDate: get().firstDeliveryDate,
      isTimedDelivery: get().isTimedDelivery,
      billingAddressSameAsDelivery: get().billingAddressSameAsDelivery,
      deliveryAddress: get().deliveryAddress,
      billingAddress: get().billingAddressSameAsDelivery ? get().deliveryAddress : get().billingAddress,
      isTermsAndConditionsChecked: get().isTermsAndConditionsChecked,
      postcodeGroupId: get().postcodeDetails && get().postcodeDetails.id,
      deliveryInfoStaging: get().deliveryAddress ? {
        displayName: get().addressDisplayName,
        instruction: get().deliveryInstructions,
        address: get().deliveryAddress
      } as DeliveryInfoStagingDto : undefined,
      deliveryScheduleStaging: get().deliverySchedule,
      selectedTokens: get().selectedTokens || 0,
      discountCode: get().discountCode,
      paymentOption: get().paymentOption,
      packageCount: get().packageCount,
      regularCalories: get().regularCalorieAllowance,
      paymentPlan: get().paymentPlan,
      maternityStatusId: get().maternityStatusId,
      pregnancyStatusId: get().pregnancyStatusId,
      breastfeedingStatusId: get().breastfeedingStatusId,
      deliveryInfoId: get().deliveryInfoId
    } as OrderStaging
  }
});

// @ts-ignore
export const useOrderEditStore = create<OrderEditState>()(persist(devtools(store, { name: 'OrderEditStore' }), { name: 'orderEditState', getStorage: () => sessionStorage }));