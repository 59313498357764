export const SHOW_MESSAGE_POPUP = 'SHOW_MESSAGE_POPUP'
export type SHOW_MESSAGE_POPUP = typeof SHOW_MESSAGE_POPUP

export const HIDE_MESSAGE_POPUP = 'HIDE_MESSAGE_POPUP'
export type HIDE_MESSAGE_POPUP = typeof HIDE_MESSAGE_POPUP

export const SHOW_GLOBAL_CONFIRMATION = 'SHOW_GLOBAL_CONFIRMATION'
export type SHOW_GLOBAL_CONFIRMATION = typeof SHOW_GLOBAL_CONFIRMATION

export const HIDE_GLOBAL_CONFIRMATION = 'HIDE_GLOBAL_CONFIRMATION'
export type HIDE_GLOBAL_CONFIRMATION = typeof HIDE_GLOBAL_CONFIRMATION

export const SHOW_GLOBAL_ALERT = 'SHOW_GLOBAL_ALERT'
export type SHOW_GLOBAL_ALERT = typeof SHOW_GLOBAL_ALERT

export const HIDE_GLOBAL_ALERT = 'HIDE_GLOBAL_ALERT'
export type HIDE_GLOBAL_ALERT = typeof HIDE_GLOBAL_ALERT

export const SHOW_GLOBAL_LIGHTBOX = 'SHOW_GLOBAL_LIGHTBOX'
export type SHOW_GLOBAL_LIGHTBOX = typeof SHOW_GLOBAL_LIGHTBOX

export const HIDE_GLOBAL_LIGHTBOX = 'HIDE_GLOBAL_LIGHTBOX'
export type HIDE_GLOBAL_LIGHTBOX = typeof HIDE_GLOBAL_LIGHTBOX

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
export type SET_PAGE_TITLE = typeof SET_PAGE_TITLE

export const SHOW_GLOBAL_SPINNER = 'SHOW_GLOBAL_SPINNER'
export type SHOW_GLOBAL_SPINNER = typeof SHOW_GLOBAL_SPINNER

export const HIDE_GLOBAL_SPINNER = 'HIDE_GLOBAL_SPINNER'
export type HIDE_GLOBAL_SPINNER = typeof HIDE_GLOBAL_SPINNER

export const SHOW_PARTIAL_SPINNER = 'SHOW_PARTIAL_SPINNER'
export type SHOW_PARTIAL_SPINNER = typeof SHOW_PARTIAL_SPINNER

export const HIDE_PARTIAL_SPINNER = 'HIDE_PARTIAL_SPINNER'
export type HIDE_PARTIAL_SPINNER = typeof HIDE_PARTIAL_SPINNER

export const START_API_REQUEST = 'START_API_REQUEST'
export type START_API_REQUEST = typeof START_API_REQUEST

export const END_API_REQUEST = 'END_API_REQUEST'
export type END_API_REQUEST = typeof END_API_REQUEST