const keyPrefix = "FFF_DATA_";

export const storageService = {
  setItem,
  getItem,
  removeItem
}

const localStorage = window.localStorage;

function setItem(key: string, data: string) {
  key = keyPrefix + key;
  removeItem(key);
  localStorage.setItem(key, data);
}

function getItem(key: string) {
  key = keyPrefix + key;
  let data = localStorage.getItem(key);
  return data;
}

function removeItem(key: string) {
    key = keyPrefix + key;
    localStorage.removeItem(key);
}