import { utilsService } from "./services/shared/utils.service";
import { DiscountApplicablePaymentOption, DiscountCategory, DiscountCodePlatform, DiscountCodeType, WaiverExtraType } from "./types/discount-code.types";
import { CustomerOnBoardedType, MealClassification, OrderType, RecipeType } from "./types/fff.enums";
import { AddressDto, PaymentOption } from "./types/order-dto.types";

export const USER_PROFILE_BASE_PATH = "/user/profile";

export const FOOD_BOX_BASE_PATH = {
  URL: "/select",
  NAME: "Select"
};

export const LATEST_ORDER_FORM_VERSION = "2.0.0";

export const IMPERSONATE_BUSINESS_USER_URL = "/togo/order";
export const EMAIL_FFF_SUPPORT = "support@freshfitnessfood.com";
export const EMAIL_FFF_SALES = "sales@freshfitnessfood.com";

// Time
export const CALENDER_VIEW_DATE_FORMAT_GB = "dd/mm/yy";
export const CALENDER_VIEW_DATE_FORMAT_SPACED = "dd M yy";
export const CALENDER_VIEW_DATE_FORMAT_DASHED = "dd-M-yy";

export const BASIC_DATE_FORMAT = "DD/MM/YYYY"; // 19/12/2001
export const DATE_TIME_FORMAT_ISO = "YYYY-MM-DDTHH:mm:ssZ"; // 2001-12-19T20:30:20Z
export const DATE_FORMAT_ISO = "YYYY-MM-DD"; // 2001-12-19

export const BOOK_A_CALL_LINK = "https://calendly.com/d/3c4-v8b-gtv/15-minute-info-nutrition-call";
export const BOOK_A_CALL_LINK_SALES = "https://calendly.com/d/3c4-v8b-gtv/15-minute-info-nutrition-call";
export const BOOK_A_CALL_PHONE_NUMBER = "02030023544";
export const SOCIAL_MEDIA_LINKS = {
  FACEBOOK: "https://www.facebook.com/Fresh-Fitness-Food-223064564433483/",
  TWITTER: "https://twitter.com/fresh_fit_food",
  INSTAGRAM: "https://www.instagram.com/freshfitnessfood/",
  YOU_TUBE: "https://www.youtube.com/channel/UCnE0Z2A_ceoqHZVwULXE02A",
  LINKED_IN: "https://www.linkedin.com/company/fresh-fitness-food-limited",
  GOOGLE_PLUS: "https://plus.google.com/108742941114370166053/videos"
};
export const BOOK_A_DEMO_LINK = "https://calendly.com/d/3c4-v8b-gtv/15-minute-info-nutrition-call";

export const MOBILE_APP_LINKS = {
  IOS: "https://apps.apple.com/us/app/fresh-fitness-food/id1542086046"
}

export const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday"
];

export const DAYS_SHORT_NAMES = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat"
];

export const HOME_PAGE_MEDIA_URLS = {
  HOW_WE_WORK_SECTION_VIDEO_URL_DESKTOP:
    "https://test-fff-blob-storage.s3.eu-west-2.amazonaws.com/Videos/WebsiteVideoCompressedVersionDesktop.mp4",
  HOW_WE_WORK_SECTION_VIDEO_URL_MOBILE:
    "https://test-fff-blob-storage.s3.eu-west-2.amazonaws.com/Videos/WebsiteVideoCompressedVersionMobile.mp4"
};

export const MEAL_PLAN_PAGE_MEDIA_VIDEO_CODE = {
  LEAN_GAINS: 'LEAN_GAINS_VIDEO',
  FAT_LOSS: 'FAT_LOSS_VIDEO',
  GENERAL_HEALTH: 'GENERAL_HEALTH_VIDEO',
  BUILD_MUSCLE: 'BUILD_MUSCLE_VIDEO',
  LEAN_GAINS_MOBILE: 'LEAN_GAINS_VIDEO_MOBILE',
  FAT_LOSS_MOBILE: 'FAT_LOSS_VIDEO_MOBILE',
  GENERAL_HEALTH_MOBILE: 'GENERAL_HEALTH_VIDEO_MOBILE',
  BUILD_MUSCLE_MOBILE: 'BUILD_MUSCLE_VIDEO_MOBILE',
};

export const MIN_MEAL_COUNT = 3;
export const MAX_MEAL_COUNT = 6;

export const CUSTOM_OPTIONS_MEAL_COUNT = 2;

export const MANDATORY_EXTRAS_MEAL_COUNT = 2;

export const MANDATORY_EXTRAS_COUNT = 2;

export const PROMO_AVAILABLE_MEAL_COUNTS = [2, 3];

export const OFFICE_EXTRA_COUNT_MIN = 2;

export const MAX_CALORIES_OF_A_MEAL = 700;
export const MIN_CALORIES_OF_A_MEAL = 350;
export const SHOW_HIDE_BREAKFAST_MEAL_COUNT = 3;

export const CUSTOM_MEAL_OPTIONS = {
  BF_LUNCH: 'BF_LUNCH',
  LUNCH_DINNER: 'LUNCH_DINNER'
}

export const AFFILIATE_REFERRAL_DATA = [
  {
    value: "My Gym",
    label: "My Gym"
  },
  {
    value: "An FFF staff member",
    label: "An FFF staff member"
  },
  {
    value: "A friend or colleague",
    label: "A friend or colleague"
  }
];

export const DISCOUNT_CODE_TYPE_DATA_FOR_CREATE = [
  {
    value: DiscountCodeType.Seasonal,
    label: "Seasonal"
  },
  {
    value: DiscountCodeType.FrozenFood,
    label: "Shop Range"
  },
  {
    value: DiscountCodeType.ToGo,
    label: "To Go"
  },
  {
    value: DiscountCodeType.F45,
    label: "F45"
  },
  {
    value: DiscountCodeType.Flexi,
    label: "Flexi"
  }
];

export const PUBLIC_USER_DISCOUNT_CODE_TYPE_DATA = [
  {
    value: DiscountCodeType.Affiliate,
    label: "Affiliate"
  },
  {
    value: DiscountCodeType.Referral,
    label: "Referral"
  }
];

export const DISCOUNT_CODE_TYPE_DATA = [
  {
    label: "All"
  },
  {
    value: DiscountCodeType.Seasonal,
    label: "Seasonal"
  },
  {
    value: DiscountCodeType.Sales,
    label: "Sales"
  },
  {
    value: DiscountCodeType.Affiliate,
    label: "Affiliate"
  },
  {
    value: DiscountCodeType.Referral,
    label: "Referral"
  },
  {
    value: DiscountCodeType.FrozenFood,
    label: "Shop Range"
  },
  {
    value: DiscountCodeType.ToGo,
    label: "To Go"
  },
  {
    value: DiscountCodeType.F45,
    label: "F45"
  },
  {
    value: DiscountCodeType.Flexi,
    label: "Flexi"
  },
  {
    value: DiscountCodeType.Ambassador,
    label: "Ambassador"
  }
];

export const DISCOUNT_CODE_PLATFORM_DATA = [
  {
    value: DiscountCodePlatform.Any,
    label: "Any"
  },
  {
    value: DiscountCodePlatform.Web,
    label: "Web"
  },
  {
    value: DiscountCodePlatform.Mobile,
    label: "App"
  }
];

export const DISCOUNT_CODE_SALES_CHANNEL_DATA = [
  {
    value: undefined,
    label: "None"
  },
  {
    value: CustomerOnBoardedType.Manual,
    label: "Manual"
  },
  {
    value: CustomerOnBoardedType.Affiliate,
    label: "Affiliate"
  },
  {
    value: CustomerOnBoardedType.Referral,
    label: "Referral"
  },
  {
    value: CustomerOnBoardedType.Online,
    label: "Online"
  },
  {
    value: CustomerOnBoardedType.F45,
    label: "F45"
  },
  {
    value: CustomerOnBoardedType.Free,
    label: "Free"
  },
  {
    value: CustomerOnBoardedType.Mobile,
    label: "App"
  }
];

export const PAYMENT_OPTIONS_DATA = [
  {
    value: PaymentOption.Upfront,
    label: "Upfront"
  },
  {
    value: PaymentOption.Weekly,
    label: "Weekly"
  },
  {
    value: PaymentOption.Flexi,
    label: "Flexi"
  }
];

export const ORDER_TYPE_OPTIONS_DATA = [
  {
    value: OrderType.FFF,
    label: "FFF"
  },
  {
    value: OrderType.F45,
    label: "F45"
  },
  {
    value: OrderType.Promotional,
    label: "Promotional"
  }
];

export const MONTHS_LIST: String[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const DEFAULT_COUNTRY = "United Kingdom";

export const DEFAULT_COUNTRY_CODE = "GB";

export const DEFAULT_CURRENCY_SIGN = "£";

export const DEFAULT_CURRENCY_CODE = "GBP";

export const DEFAULT_LANGUAGE_ID = "en-GB";

export const DEFAULT_ADDRESS: AddressDto = {
  line1: "",
  line2: "",
  city: "",
  county: "",
  country: DEFAULT_COUNTRY,
  postCode: ""
};

export const EMPTY_DELIVERY_PATTERN = JSON.stringify(
  Object.assign({}, [1, 1, 1, 1, 1])
);

export const ContentManagementCodes = {
  ORDER_SUMMARY_FIELDS: "ORDER_SUMMARY_FIELDS",
  HOME_PAGE_CAROUSEL_ITEM_1: "HOME_PAGE_CAROUSEL_ITEM_1",
  HOME_PAGE_CAROUSEL_ITEM_2: "HOME_PAGE_CAROUSEL_ITEM_2",
  HOME_PAGE_CAROUSEL_ITEM_3: "HOME_PAGE_CAROUSEL_ITEM_3",
  HOME_PAGE_CAROUSEL_ITEM_4: "HOME_PAGE_CAROUSEL_ITEM_4",
  HOME_PAGE_CAROUSEL_ITEM_5: "HOME_PAGE_CAROUSEL_ITEM_5",
  HOME_PAGE_CAROUSEL_ITEM_6: "HOME_PAGE_CAROUSEL_ITEM_6",
  HOME_PAGE_MAIN_SECTION: "HOME_PAGE_MAIN_SECTION",
  ANNOUNCEMENT_BANNER: "ANNOUNCEMENT_BANNER",
  ANNOUNCEMENT_BANNER_LOGGED_IN: "ANNOUNCEMENT_BANNER_LOGGED_IN",
  MY_REWARDS_BANNER: "MY_REWARDS_BANNER",
  NAV_MENU_SEASONAL_CAMPAIGN: "NAV_MENU_SEASONAL_CAMPAIGN",
  REFERRAL_PROMO_IMAGE: "REFERRAL_PROMO_IMAGE",
  ORDER_FORM_SUMMARY_PACKAGE: "ORDER_FORM_SUMMARY_PACKAGE",
  ORDER_FORM_SUMMARY_GOAL: "ORDER_FORM_SUMMARY_GOAL",
}

export const StaticContentManagementCodes = {
  DYNAMIC_SHARED: "DYNAMIC_SHARED",
  HOME: "HOME",
  HOME_YOUR_OWN_POCKET_NUTRITIONIST: "HOME_YOUR_OWN_POCKET_NUTRITIONIST",
  HOME_HOW_IT_WORKS: "HOME_HOW_IT_WORKS",
  HOME_PERSONALISED_MEAL_PLANS: "HOME_PERSONALISED_MEAL_PLANS",
  HOME_FOOTER_ROW: "HOME_FOOTER_ROW",
  HOME_DELIVERY_DETAILS: "HOME_DELIVERY_DETAILS",
  HOW_HEADER: "HOW_HEADER",
  HOW_PARAGRAPH_CONTENT: "HOW_PARAGRAPH_CONTENT",
  HOW_ORDER_PROCESS_ITEMS: "HOW_ORDER_PROCESS_ITEMS",
  HOW_THREE_COLUMN_FAQ: "HOW_THREE_COLUMN_FAQ",
  HOW_STILL_HAVE_QUESTIONS: "HOW_STILL_HAVE_QUESTIONS",
  HOW_TESTIMONIALS: "HOW_TESTIMONIALS",
  HOW_READ_MORE: "HOW_READ_MORE",
  BESPOKE_HEADER: "BESPOKE_HEADER",
  BESPOKE_DESCRIPTION: "BESPOKE_DESCRIPTION",
  BESPOKE_DEMO: "BESPOKE_DEMO",
  BESPOKE_MENU_SINGLE_FAT_LOSS: "BESPOKE_MENU_SINGLE_FAT_LOSS",
  BESPOKE_MENU_SINGLE_GENERAL_HEALTH: "BESPOKE_MENU_SINGLE_GENERAL_HEALTH",
  BESPOKE_MENU_SINGLE_LEAN_GAIN: "BESPOKE_MENU_SINGLE_LEAN_GAIN",
  BESPOKE_MENU_SINGLE_MUSCEL_GAIN: "BESPOKE_MENU_SINGLE_MUSCEL_GAIN",
  BESPOKE_WHO_TRIED_IT: "BESPOKE_WHO_TRIED_IT",
  BESPOKE_DESCRIPTION_ON_THE_MENU_IMAGE: "BESPOKE_DESCRIPTION_ON_THE_MENU_IMAGE",
  MOBILE_FIXED_BUTTONS: "MOBILE_FIXED_BUTTONS",
  IMAGE_CARD: "IMAGE_CARD",
  PRICING_HEADER: "PRICING_HEADER",
  PRICING_INTRO: "PRICING_INTRO",
  PRICING_DESCRIPTION: "PRICING_DESCRIPTION",
  PRICING_DEMO: "PRICING_DEMO",
  PRICING_COMMIT_TITLE: "PRICING_COMMIT_TITLE",
  PRICING_FLEXI_TITLE: "PRICING_FLEXI_TITLE",
  PRICING_COMMIT_DESC: "PRICING_COMMIT_DESC",
  PRICING_FLEXI_DESC: "PRICING_FLEXI_DESC",
  PRICING_GOALS: "PRICING_GOALS",
  PRICING_CAROUSEL: "PRICING_CAROUSEL",
  ACTION_BANNER: "ACTION_BANNER",
  FOOTER: "FOOTER",
  HOME_BRAND_LOGO: "HOME_BRAND_LOGO",
  HOME_LOGO: "HOME_LOGO",
  HOME_BENEFITS_LOGOS: "HOME_BENEFITS_LOGOS",
  HOW_GET_STARTED_BTN: "HOW_GET_STARTED_BTN",
  ABOUT_US_PAGE_LAYOUT: "ABOUT_US_PAGE_LAYOUT",
  AFFILIATES_PAGE_LAYOUT: "AFFILIATES_PAGE_LAYOUT",
  AFFILIATES_BOTTOM: "AFFILIATES_BOTTOM",
  AFFILIATES_TOP: "AFFILIATES_TOP",
  AFFILIATES_BENEFITS_BRAND_LOGO: "AFFILIATES_BENEFITS_BRAND_LOGO",
  CONTACT_PAGE_LAYOUT: "CONTACT_PAGE_LAYOUT",
  FAQ_PAGE_LAYOUT: "FAQ_PAGE_LAYOUT",
  HOW_PAGE_LAYOUT: "HOW_PAGE_LAYOUT",
  IMPACT_PAGE_LAYOUT: "IMPACT_PAGE_LAYOUT",
  OUR_TEAM_PAGE_LAYOUT: "OUR_TEAM_PAGE_LAYOUT",
  PRIVACY_POLICY_PAGE_LAYOUT: "PRIVACY_POLICY_PAGE_LAYOUT",
  TERMS_AND_CONDITIONS_PAGE_LAYOUT: "TERMS_AND_CONDITIONS_PAGE_LAYOUT",
  REVIEWS_PAGE_LAYOUT: "REVIEWS_PAGE_LAYOUT",
  ABOUT_US: "ABOUT_US",
  CONTACT_US: "CONTACT_US",
  FAQ: "FAQ",
  HOW: "HOW",
  IMPACT_STATIC: "IMPACT_STATIC",
  IMPACT: "IMPACT",
  OUR_TEAM: "OUR_TEAM",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  TESTIMONIALS: "TESTIMONIALS",
  CORPORATE_HEALTH: "CORPORATE_HEALTH",
  STATIC_HEADER: "STATIC_HEADER",
  BESPOKE_ON_THE_MENU_ITEM_DESCRIPTION: "BESPOKE_ON_THE_MENU_ITEM_DESCRIPTION",
  BESPOKE_DETAIL_BOX_ITEMS: "BESPOKE_DETAIL_BOX_ITEMS",
  BESPOKE_MENU_ITEM_PAGE_HEADER: "BESPOKE_MENU_ITEM_PAGE_HEADER"
}

export const UPLOAD_FILE_TYPE_IMAGE = "image/*";
export const UPLOAD_FILE_TYPE_VIDEO = "video/*";
export const UPLOAD_FILE_TYPE_VIDEO_MP4 = "video/mp4";
export const UPLOAD_FILE_TYPE_AUDIO_MP3 = "audio/mpeg";
export const UPLOAD_TYPE_IMAGE = "Image";
export const UPLOAD_TYPE_VIDEO = "Video";
export const UPLOAD_TYPE_AUDIO = "Audio";
export const MAXIMUM_UPLOAD_SIZE_1 = 1048000;
export const MAXIMUM_UPLOAD_SIZE_2 = 2096000;
export const DEFAULT_LOAD_COUNT = 10;
export const DEFAULT_TOKENS_AMOUNT = 0;

export const ORDER_FORM_REFERRAL_DATA_SECOND_OPTION = {
  DROP_DOWN_LIST: "DROP_DOWN_LIST",
  TEXT_BOX: "TEXT_BOX",
  NULL: null
};

export const ORDER_FORM_REFERRAL_DATA = [
  {
    value: "FROM_A_FRIEND",
    label: "Another Client",
    secondOptionTitle: "Name of client",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.TEXT_BOX
  },
  {
    value: "SOCIAL_MEDIA",
    label: "Instagram/Facebook",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.NULL
  },
  {
    value: "ONLINE_SEARCH",
    label: "Google",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.NULL
  },
  {
    value: "OTHER_SOCIAL_MEDIA",
    label: "Influencer/ Other Social Media",
    secondOptionTitle: "Please specify",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.TEXT_BOX
  },
  {
    value: "GYM_OR_PERSONAL_TRAINER",
    label: "Personal Trainer/ Gym/ Health Professional",
    secondOptionTitle: "Name of trainer/ gym/ health professional",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.TEXT_BOX
  },
  {
    value: "PRESS_ARTICLE",
    label: "Press Article",
    secondOptionTitle: "Name of publication",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.TEXT_BOX
  },
  {
    value: "FLYER",
    label: "Flyer",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.NULL
  },
  {
    value: "EVENT",
    label: "Event",
    secondOptionTitle: "Name of event",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.TEXT_BOX
  },
  {
    value: "APP_ADVERTISEMENT",
    label: "Saw the app advertised",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.NULL
  },
  {
    value: "APP_STORE",
    label: "Found on the app store",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.NULL
  },
  {
    value: "FFF_STAFF_MEMBER",
    label: "Fresh Fitness Food staff member",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.NULL
  },
  {
    value: "OTHER",
    label: "Other",
    secondOptionTitle: "Please specify",
    secondOption: ORDER_FORM_REFERRAL_DATA_SECOND_OPTION.TEXT_BOX
  }
];

export const ORDER_FORM_REFERRAL_DATA_ADVERTISEMENT = [
  {
    value: "Tube Advert",
    label: "Tube Advert"
  },
  {
    value: "Flier",
    label: "Flier"
  },
  {
    value: "Instagram Ad",
    label: "Instagram Ad"
  },
  {
    value: "Facebook Ad",
    label: "Facebook Ad"
  }
];

export const ORDER_FORM_MACROS_CALCULATIONS = {
  PERCENTAGE_CALCULATION: 100,
  PROTEIN_MAX_LIMIT: 45 / 100,
  CARB_MIN_LIMIT: 15 / 100,
  FAT_MIN_LIMIT: 20 / 100,
  FAT_MAX_LIMIT: 45 / 100,
  MIN_PROTEIN_VALUE: 50,
  MIN_FAT_VALUE: 30,
  CARBS_MULTIPLICATION: 4,
  PROTEIN_MULTIPLICATION: 4,
  FAT_MULTIPLICATION: 9
};

export const CONVERTER_CALCULATIONS = {
  LBS_TO_KG: 0.453592,
  FT_TO_CM: 30.48,
  FT_TO_INCHES: 12,
  INCHES_TO_CM: 2.54,
  KG_TO_LBS: 2.20462
};

export const MEALS_METADATA_MISCELLANEOUS_DISPLAY_TYPE = {
  CHECKBOX: "CHECKBOX",
  TOGGLE: "TOGGLE"
};

export const MEAL_PLANS_TAB_MENU_DEFAULT_TAB = "fatLoss";

export const ORDER_FORM_MEASUREMENT_UNITS_HEIGHT = [
  {
    name: "heightUnits",
    value: "Cm",
    label: "cm"
  },
  {
    name: "heightUnits",
    value: "Ft",
    label: "ft"
  }
];

export const ORDER_FORM_MEASUREMENT_UNITS_WEIGHT = [
  {
    name: "weightUnits",
    value: "Kg",
    label: "kg"
  },
  {
    name: "weightUnits",
    value: "Lbs",
    label: "lbs"
  }
];

export const MIN_WEIGHT_KG = 40;
export const MAX_WEIGHT_KG = 160;
export const MIN_WEIGHT_LBS =
  utilsService.truncateUptoTwoDecimal(MIN_WEIGHT_KG / CONVERTER_CALCULATIONS.LBS_TO_KG);
export const MAX_WEIGHT_LBS =
  utilsService.truncateUptoTwoDecimal(MAX_WEIGHT_KG / CONVERTER_CALCULATIONS.LBS_TO_KG);
export const MIN_HEIGHT_CM = 120;
export const MAX_HEIGHT_CM = 230;
export const MIN_HEIGHT_FT =
  utilsService.truncateUptoTwoDecimal(MIN_HEIGHT_CM / CONVERTER_CALCULATIONS.FT_TO_CM);
export const MAX_HEIGHT_FT =
  utilsService.truncateUptoTwoDecimal(MAX_HEIGHT_CM / CONVERTER_CALCULATIONS.FT_TO_CM);

export const MIN_AGE: number = 18;

const paymentSchemeData: { [k: string]: any } = {
  VISA: {
    NAME: "Visa",
    IMAGE_URL: "/assets/img/payment-card-logos/visa.png"
  },
  MASTERCARD: {
    NAME: "Mastercard",
    IMAGE_URL: "/assets/img/payment-card-logos/mastercard.png"
  },
  AMERICAN_EXPRESS: {
    NAME: "American Express",
    IMAGE_URL: "/assets/img/payment-card-logos/american-express.png"
  },
  AMEX: {
    NAME: "American Express",
    IMAGE_URL: "/assets/img/payment-card-logos/american-express.png"
  },
  DISCOVER: {
    NAME: "Discover",
    IMAGE_URL: "/assets/img/payment-card-logos/discover.jpg"
  },
  DINERS_CLUB: {
    NAME: "Diners Club",
    IMAGE_URL: "/assets/img/payment-card-logos/diners-club.jpg"
  },
  DINERSCLUB: {
    NAME: "Diners Club",
    IMAGE_URL: "/assets/img/payment-card-logos/diners-club.jpg"
  },
  DINERS_CLUB_INTERNATIONAL: {
    NAME: "Diners Club",
    IMAGE_URL: "/assets/img/payment-card-logos/diners-club.jpg"
  },
  JCB: {
    NAME: "JCB",
    IMAGE_URL: "/assets/img/payment-card-logos/jcb.png"
  },
  UNION_PAY: {
    NAME: "UnionPay",
    IMAGE_URL: "/assets/img/payment-card-logos/unionpay.png"
  }
};

export const PAYMENT_CARD_SCHEMES = new Proxy(paymentSchemeData, {
  get(target, key) {
    if (key in target) {
      let prop = key as string;
      return target[prop];
    } else {
      return {
        NAME: "",
        IMAGE_URL: ""
      };
    }
  }
});

const paymentWalletData: { [k: string]: any } = {
  APPLEPAY: {
    Name: "Apple Pay",
    IMAGE_URL: "/assets/img/payment-card-logos/apple-pay.svg"
  }
};

export const PAYMENT_CARD_WALLETS = new Proxy(paymentWalletData, {
  get(target, key) {
    if (key in target) {
      let prop = key as string;
      return target[prop];
    } else {
      return {
        NAME: "",
        IMAGE_URL: ""
      };
    }
  }
});

export const REGEX = {
  EMAIL: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
  NAME: /^[a-zA-Z]+$/,
  THOUSAND_SEPARATOR: /\B(?=(\d{3})+(?!\d))/g,
  DISCOUNT_CODE_INPUT: /^[a-zA-Z0-9]+$/,
  DISCOUNT_CODE_ADMIN_INPUT: /^[A-Z0-9]+$/,
  PHONE_NUMBER: /^\+?[0-9]+$/,
  PHONE_NUMBER_INPUT: /^\+(?:[0-9]●?){6,14}[0-9]$/,
  CHARACTERS: /[a-zA-Z0-9]/,
  BANK_SORT_CODE: /^(?!(00-00-00))(\d\d-\d\d-\d\d)$/,
  CODE_INPUT: /^[A-Z0-9_]+$/
};

export const EXTRA_CODES = {
  JUICES: "JUICES",
  SHAKES: "SHAKES",
  PSK2: "PSK2",
  SWEET_SNACK: "SWEET_SNACK",
  SAVOURY_SNACK: "SAVOURY_SNACK",
  BOOSTERS: "BOOSTERS",
  SNACKS: "SNACKS"
};

export const ORDER_GOALS = {
  FAT_LOSS: "FAT_LOSS",
  LEAN_GAINS: "LEAN_GAINS",
  ENHANCE_ENERGY: "ENHANCE_ENERGY",
  MUSCLE_GAIN: "MUSCLE_GAIN"
};

export const PRICING_PAGE_CALCULATION_VALUES = {
  SLIDER_VALUE_DEFAULT: 40,
  DISPLAY_VALUE_DEFAULT: 40,
  SAVE_HOURS_VALUE_DEFAULT: 56,
  SLIDER_VALUE_MIN: 5,
  SAVE_HOURS_VALUE_MIN: 7,
  DISPLAY_VALUE_DIVIDER: 10,
  DISPLAY_VALUE_MULTIPLICATION: 10,
  SAVE_HOURS_VALUE_DIVIDER: 10,
  SAVE_HOURS_VALUE_MULTIPLICATION: 2,
  SLIDER_VALUE_RANGE_1_LOWER_BOUND: 30,
  SLIDER_VALUE_RANGE_1_UPPER_BOUND: 40,
  SLIDER_VALUE_RANGE_2_LOWER_BOUND: 60,
  SLIDER_VALUE_RANGE_2_UPPER_BOUND: 90,
  SLIDER_VALUE_RANGE_3_LOWER_BOUND: 100
};

export const LEDGER_CODES = {
  Customer: "CUS_LGR",
  Sales: "SAL_LGR",
  Cash: "CSH_LGR",
  Delivery: "DLY_LGR",
  Token: "TKN_LGR",
  Discount: "DCT_LGR",
  CustomerCredit: "CUS_CRD_LGR",
  Penalty: "PLT_LGR",
  BagDeposit: "BAG_DEP_LGR",
  NonRefundable: "NON_REF_LGR",
  ActualTokenUtilization: "ACT_TKN_LGR",
  WithdrawnCredit: "WD_CRD_LGR",
  CustomerAdjustments: "CUS_ADJ_LGR",
  ExpiredCredit: "EXP_CRD_LGR",
  ExpiredSales: "EXP_SAL_LGR",
  RoundingAdjustments: "RND_ADJ_LGR",
};

export const TRUSTPILOT_DATA_VALUES = {
  MEAL_PLANS_PAGE_DATA_TEMPLATE_ID: "5418052cfbfb950d88702476",
  FAQ_PAGE_DATA_TEMPLATE_ID: "5418052cfbfb950d88702476",
  ORDER_FORM_PACKAGE_PROMPT_DATA_TEMPLATE_ID: "53aa8912dec7e10d38f59f36",
  MEAL_PACKAGE_PAGES_DATA_TEMPLATE_ID: "53aa8912dec7e10d38f59f36",

  TEMPLATE_ID: {
    WIDGET: "5406e65db0d04a09e042d5fc",
    SMALL_WIDGET: "5418052cfbfb950d88702476",
    SLIDER: "53aa8912dec7e10d38f59f36"
  },

  FONT_FAMILY: {
    POPPING: "Poppins"
  }
};

export const CUSTOMER_ON_BOARD_TYPE = [
  "Manual",
  "Affiliate",
  "Referral",
  "Online",
  "F45",
  "Free",
  "App"
];

export const BusinessUserTabKeys = {
  PersonalInfo: "PERSONAL_INFO",
  DeliveryInfo: "DELIVERY_INFO",
  PaymentMethodInfo: "PAYMENT_METHOD_INFO"
};

export const CustomerTabKeys = {
  PersonalInfo: "PERSONAL_INFO",
  DeliveryInfo: "DELIVERY_INFO",
  PaymentMethodInfo: "PAYMENT_METHOD_INFO",
  Notes: "NOTES",
  AdvanceMealSettings: "ADVANCED_MEAL_SETTINGS"
};

export const MONTHS_SHORT_LIST = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const DISCOUNT_CATEGORY_DATA = [
  {
    value: undefined,
    label: "None"
  },
  {
    value: DiscountCategory.Platform,
    label: "Platform"
  },
  {
    value: DiscountCategory.PerCustomer,
    label: "Per Customer"
  }
];

export const APPLICABLE_DISCOUNT_PAYMENT_OPTION_DATA = [
  {
    value: DiscountApplicablePaymentOption.Upfront,
    label: "Upfront"
  },
  {
    value: DiscountApplicablePaymentOption.Weekly,
    label: "Weekly"
  },
  {
    value: DiscountApplicablePaymentOption.Both,
    label: "Both"
  }
]

export const DEFAULT_ORDER_METADATA_PACKAGE_CODE = 'TXT_100_DAY_PACKAGE';
export const DEFAULT_ORDER_PACKAGE_COUNT = 100;
export const DEFAULT_ORDER_PACKAGE_PAYMENT_OPTION = PaymentOption.Flexi;

export const WAIVER_EXTRA_TYPE_DATA = [
  {
    value: WaiverExtraType.Juice,
    label: "Juice"
  },
  {
    value: WaiverExtraType.Booster,
    label: "Booster"
  },
  {
    value: WaiverExtraType.Snack,
    label: "Snack"
  },
  {
    value: WaiverExtraType.Shake,
    label: "Smoothie"
  }
];

export const FROZEN_FOOD_CHECKOUT_MIN_ITEM_COUNT = 6;
export const FROZEN_FOOD_MISCELLANEOUS_PREFIX = 'MISC_';
export const REQUEST_STATUS_PENDING = 'PENDING';

export const TRAINING_GUIDE = {
  PREVIOUS_PRICE: 100,
  PRICE: 0,
}

export const ANNOUNCEMENT_BANNER_SHOWN = 'ANNOUNCEMENT_BANNER_SHOWN';

export const TOGO_ORDER_CREATION_LEAD_DATES = 4
export const TOGO_MIN_ITEM_COUNT_PER_DAY = 6;

export const TOGO_EXTRA_RECIPES = [
  RecipeType.Snack,
  RecipeType.Juice,
  RecipeType.Shake,
  RecipeType.Booster,
];

export const TOGO_EXTRA_CLASSIFICATIONS = [
  MealClassification.Sweet,
  MealClassification.Savory,
];

export const DELIVERY_SERVICES = {
  CITY_SPRINT: 'CITY_SPRINT',
  DPD: 'DPD'
}

export const DELIVERY_SERVICE_CONFIG = {
  DEFAULT_DELIVERY_SERVICE: DELIVERY_SERVICES.CITY_SPRINT,
  FROZEN_DELIVERY_SERVICE: DELIVERY_SERVICES.DPD,
}

export const F45_BASE_URL = '/f45-challenge-meal-plans';
export const F45_DEFAULT_MEALS_PER_DAY = 3;
export const F45_PRESELECTED_MEALS_PER_DAY = 4;
export const F45_MINIMUM_CALORIES_FOR_PRESELECT_MEAL_PLAN = 2300;

export const CALENDAR_LOCALE = {
  firstDayOfWeek: 1,
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  today: 'Today',
  clear: 'Clear'
}

export const TWENTY_MINUTES_IN_MS = 20 * 60 * 1000;
export const ONE_HOUR_IN_MS = 60 * 60 * 1000;
export const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;

export const MyFffoodsTabKeys = {
  ManageDelivery: "MANAGE_DELIVERY",
  ManageNutritions: "MANAGE_NUTRITION",
  DailyMenu: "DAILY_MENU"
}

export const ClientDashboardTabKeys = {
  ManageTokens: 'MANAGE_TOKENS',
  MyFffoods: 'MY_FFFOODS',
  ManageAccountInfo: 'MANAGE_ACCOUNT_INFO',
  ViewClientOffers: 'VIEW_CLIENT_OFFERS'
};

export const ClientDashboardTabNames: any = {
  'MY_REWARDS': ClientDashboardTabKeys.ManageTokens,
  'MY_ORDER': ClientDashboardTabKeys.MyFffoods,
  'ACCOUNT_INFO': ClientDashboardTabKeys.ManageAccountInfo,
  'CLIENT_OFFERS': ClientDashboardTabKeys.ViewClientOffers
}

export const ImageFolderPaths = {
  FoodBox: {
    MealVariations: "food-box/meal-variations"
  }
}

export const DELIVERY_TIME_OPTIONS = {
  STANDARD: 'STANDARD',
  TIMED: 'TIMED'
}

export const PAGED_LIST_DEFAULTS = {
  PAGE_SIZE: 10,
  PAGE_NUMBER: 1,
  ASCENDING: true
}
