import { DEFAULT_LANGUAGE_ID, DEFAULT_CURRENCY_CODE } from './../../constants';
import _ from 'lodash';
import moment from 'moment';
import { DEFAULT_CURRENCY_SIGN } from "../../constants";
import { sessionService } from './session.service';

export const utilsService = {
  evenRound,
  formatDate,
  truncateUptoTwoDecimal,
  formatCurrency,
  formatNumber,
  thousandsSeparators,
  formatDateTime,
  truncateText,
  isArrayEqual,
  isArraySame,
  getDatesBetweenRange,
  getSignFromTenantCurrencyCode,
  getDisplayableTextFromCode,
  getDisplayableTextFromEnumValue,
  getWithOrdinalSuffix,
  formatValueWithDecimals,
  enumToOptions,
  getPreviousAndNextOfArray
};

function evenRound(num: number, decimalPlaces: number = 0) {
  var d = decimalPlaces || 0;
  var m = Math.pow(10, d);
  var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  var i = Math.floor(n),
    f = n - i;
  var e = 1e-8; // Allow for rounding errors in f
  var r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
}

function formatDate(date: Date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function zeroPad(num: number, places: number) {
  return String(num).padStart(places, "0");
}

function formatDateTime(date: Date) {
  const dateStr = formatDate(date);
  const hour = zeroPad(date.getHours(), 2);
  const minute = zeroPad(date.getMinutes(), 2);
  const seconds = zeroPad(date.getSeconds(), 2);

  const time = [hour, minute, seconds].join(":");

  return `${dateStr}T${time}`;
}

function truncateUptoTwoDecimal(value: number) {
  return Math.floor(value * 100) / 100;
}

/**
 * Returns number separated by thousands
 * @param {number} num - Amount to be formatted.
 */
function thousandsSeparators(num: string) {
  const numParts = num.split(".");
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numParts.join(".");
}

/**
 * Returns formatted number by two decimal places.
 * @param {number} amount - Amount to be formatted.
 * @param {boolean} hideDecimalIfZero - Hide decimal if zero.
 * @returns {string} Formatted number.
 * @example
 * formatNumber(1) // returns 1.00
 * formatNumber(1.1) // returns 1.10
 * formatNumber(1, true) // returns 1
 * formatNumber(1.123, false) // returns 1.123
 */
function formatNumber(amount: number, hideDecimalIfZero: boolean = false) {
  const hasDecimal = amount % 1 > 0;
  return thousandsSeparators(amount.toFixed(hasDecimal || !hideDecimalIfZero ? 2 : 0));
}

function formatCurrency(amount: number, hideDecimalIfZero: boolean = true, showZero: boolean = true, showPlus: boolean = false) {
  const currencySign = getSignFromTenantCurrencyCode();

  if (amount) {
    return `${showPlus ? "+" : ""}${currencySign}${formatNumber(amount, hideDecimalIfZero)}`;
  }

  return showZero ? `${currencySign}0` : "";
}

/**
 * Get currency sign from the tenant currency code
 * @returns currency symbol. eg; $
 */
function getSignFromTenantCurrencyCode() {
  const tenantConfigs = sessionService.getTenantConfiguration();

  const currency = tenantConfigs && tenantConfigs['Currency'] || DEFAULT_CURRENCY_CODE;
  const culture = tenantConfigs && tenantConfigs['Culture'] || DEFAULT_LANGUAGE_ID;

  const currencySign = new Intl.NumberFormat(culture, {
    style: "currency",
    currency: currency
  })
    .formatToParts(1)
    .find(p => p.type == "currency");

  return currencySign && currencySign.value || DEFAULT_CURRENCY_SIGN;
}

function truncateText(text: string, length: number) {
  if (text.length > length) return text.substring(0, length) + "...";

  return text;
}

/**
 * Deep compares two object arrays
 * @param {any[]} x - array to be compared against
 * @param {any[]} y - array to be comapared
 * @returns {boolean} 
 */
function isArrayEqual(x: any[], y: any[]): boolean {
  return _(x).xorWith(y, _.isEqual).isEmpty();
}

/**
 *  Check 2 arrays same regardless of the order
 * @param {any[]} x - array to be compared against
 * @param {any[]} y - array to be compared
 * @returns {boolean} 
 */
function isArraySame(x: any[], y: any[]): boolean {
  let arr1 = _.uniq(x);
  let arr2 = _.uniq(y);
  return arr1.length === arr2.length && _.isEmpty(_.difference(arr2.sort(), arr1.sort()));
}

/**
 * Retrieves list of dates between two dates including
 * @param {Date} fromDate - start date
 * @param {Date} toDate - end date
 * @param {Date[]} [excludedDates = []] - dates to be excluded
 * @returns {Date[]} 
 */
function getDatesBetweenRange(fromDate: Date, toDate: Date, excludedDates: Date[] = []): Date[] {
  let currentDate = moment(fromDate).clone();
  let dates: Date[] = [];

  while (currentDate.isSameOrBefore(toDate)) {
    let date = currentDate.toDate();
    if (!excludedDates.some(d => moment(d).isSame(currentDate, 'date'))) {
      dates.push(date);
    }
    currentDate.add(1, 'days');
  }
  return dates;
}

/**
 * Get displayable text from a code of type MEAL_EXTRAS etc.
 * @param code - any metadata or product code
 * @returns string
 * @example 
 * getDisplayableTextFromCode('MEAL_EXTRAS') // returns Meal Extras
 */

function getDisplayableTextFromCode(code: string) {
  return code
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
}

/**
 * Get displayable text from enum value
 * @param enumObj - any enum object
 * @param value - enum value
 * @returns string
 * @example
 * getDisplayableTextFromEnumValue(ORDER_STATUS, 1) // returns Pending
 * getDisplayableTextFromEnumValue(ORDER_STATUS, 2) // returns Accepted
 **/
function getDisplayableTextFromEnumValue<T>(enumObj: T, value: number): keyof T | null {
  for (const key in enumObj) {
    //@ts-ignore
    if (Object.prototype.hasOwnProperty.call(enumObj, key) && enumObj[key] === value) {
      const formattedKey = key.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
      return formattedKey as keyof T;
    }
  }
  return null; // Value not found in the enum
}

/**
 * Converts enum to options
 * @param enumObject - any enum object
 * @returns {label: string, value: string}[]
 * @example
 * enumToOptions(ORDER_STATUS) // returns [{label: 'Pending', value: '1'}, {label: 'Accepted', value: '2'}]
 */
function enumToOptions<T>(enumObject: T): { label: string; value: string }[] {
  const options: { label: string; value: string }[] = [];

  for (const key in enumObject) {
    if (isNaN(Number(key))) {
      options.push({
        label: key.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
        value: `${enumObject[key]}`
      });
    }
  }

  return options;
}

/**
 * Get number with ordinal suffix
 * @param {number} number - number to be formatted
 * @returns {string}
 * @example
 * getWithOrdinalSuffix(1) // returns 1st
 * getWithOrdinalSuffix(2) // returns 2nd
 */
function getWithOrdinalSuffix(num: number, culture?: string): string {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);

  const pluralRules = new Intl.PluralRules(culture, { type: 'ordinal' });

  const rule = pluralRules.select(num);
  const suffix = suffixes.get(rule);
  return `${num}${suffix}`;
}

/**
 * Format a number with decimals if it has decimals
 * @param {number} value - number to be formatted
 * @returns {string}
 * @example
 * formatValueWithDecimals(1) // returns 1
 * formatValueWithDecimals(1.1) // returns 1.10
 */
function formatValueWithDecimals(value: number, fixedDecimals: number = 2) {
  if (Number.isInteger(value)) {
    return value.toString();
  } else {
    return value.toFixed(fixedDecimals);
  }
}


/**
 * Returns the previous and next elements of an array based on the given index.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The array from which to retrieve the previous and next elements.
 * @param {number} index - The index of the element for which to find the previous and next elements.
 * @returns {{ previous: T, next: T }} - An object containing the previous and next elements of the array.
 * @throws {Error} - If the index is out of bounds.
 */
function getPreviousAndNextOfArray<T>(array: T[], index: number): { previous: T, next: T } {
  if (index < 0 || index >= array.length) {
    throw new Error('Index out of bounds');
  }

  const previous = index === 0 ? array[array.length - 1] : array[index - 1];
  const next = index === array.length - 1 ? array[0] : array[index + 1];

  return { previous, next };
}