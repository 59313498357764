import * as intl from 'react-intl-universal';
import { WaiverType } from "../types/discount-code.types";
import { F45ConfigDetailsDto, F45DiscountSummaryDto, F45EmailPostcodeValidationResultDto, F45OrderDto, F45PlanDetailDto, F45PlanDto, F45PricingMatrixDto, F45ReOrderDto } from "../types/f45.types";
import { PaymentOption, PaymentResultDto } from "../types/order-dto.types";
import { DateRangeSearchFilter, EntitySaveResult, SearchResultsDto } from '../types/shared-dto.types';
import { OrderCreateResultDto } from './../types/order-dto.types';
import { discountCodeService } from './discount-code.service';
import { apiService } from "./shared/api.service";
import { utilsService } from './shared/utils.service';

export const f45Service = {
  getPlans,
  getPlan,
  createF45Order,
  getF45OrderForCurrentUser,
  getPlanForReorder,
  placeReorder,
  getF45DiscountInfo,
  getF45EnabledConfigSetting,
  getF45StartDateConfigSetting,
  getF45OrderCutOffDateConfigSetting,
  syncF45Orders,
  getF45ConfigDetails,
  validateEmailAndPostcode,
  getPricingMatrix,
  getPricingMatrixById,
  updatePricingMatrix
}

function getPlans() {
  return apiService.get<F45PlanDto[]>('F45', 'packages', [], null, true);
}

function getPlan(packageId: string, postcode: string, email: string, mealCount: number, includeSnack: boolean, includeShake: boolean, includeJuice: boolean, discountCode?: string) {
  return apiService.get<F45PlanDetailDto>('F45', 'package', [], { packageId, postcode, email, mealCount, includeSnack, includeShake, includeJuice, discountCode }, true);
}

function getPlanForReorder(packageId: string, mealCount: number, includeSnack: boolean, includeShake: boolean, includeJuice: boolean, discountCode?: string) {
  return apiService.get<F45PlanDetailDto>('F45', 'package/reorder', [], { packageId, mealCount, includeSnack, includeShake, includeJuice, discountCode }, true);
}

function createF45Order(order: F45OrderDto) {
  return apiService.post<OrderCreateResultDto>('F45', 'Order', order, [], null, true);
}

function getF45OrderForCurrentUser() {
  return apiService.get<F45OrderDto>('F45', 'Order', []);
}

function placeReorder(reorderDto: F45ReOrderDto) {
  return apiService.post<PaymentResultDto>('F45', 'reorder', reorderDto, [], null, true);
}

function calcuateExtraDiscountAmount(code: string, packageCode: string) {
  return apiService.get('F45', "extra", [code, packageCode]);
}

async function getF45DiscountInfo(discountCode: string, packageId: string, isFirstOrder: boolean) {
  let f45DiscountSummary: F45DiscountSummaryDto = {
    isValid: false
  };
  let isValid = await discountCodeService.validateDiscountCodeApplicability(discountCode, packageId);
  if (isValid) {
    let discountCodeInfo = await discountCodeService.getDiscountCodeInfo(discountCode, isFirstOrder, PaymentOption.Upfront);
    if (discountCodeInfo.valid) {
      let discountStr: (string | undefined);
      if (discountCodeInfo.waiverType == WaiverType.Extras) {
        discountStr = "Extras off";
      }
      else {
        discountStr = discountCodeInfo.discountAmount > 0 ? `${utilsService.formatCurrency(discountCodeInfo.discountAmount)} off` : `${discountCodeInfo.discountPercentage}% off`;
      }
      f45DiscountSummary.isValid = true;
      f45DiscountSummary.discountStr = discountStr;
    }
    else {
      f45DiscountSummary.invalidDiscountCodeMessage = intl.get('ERR_DISCOUNT_CODE_INVALID')
    }
  } else {
    f45DiscountSummary.invalidDiscountCodeMessage = intl.get('ERR_DISCOUNT_CODE_INVALID')
  }
  return f45DiscountSummary;
}

function getF45EnabledConfigSetting() {
  return apiService.get<boolean>('F45', 'isEnabled')
    .then((value: boolean) => {
      return value;
    })
}

function getF45StartDateConfigSetting() {
  return apiService.get<Date>('F45', 'challengeStartDate')
    .then((value: Date) => {
      return value;
    })
}

function getF45OrderCutOffDateConfigSetting() {
  return apiService.get<Date>('F45', 'challengeCutOffDate')
    .then((value: Date) => {
      return value;
    })
}

function syncF45Orders(fromDate: Date, toDate: Date) {
  return apiService.post('F45', 'Order/Sync', undefined, undefined, { fromDate: fromDate.toUTCString(), toDate: toDate.toUTCString() });
}

function getF45ConfigDetails() {
  return apiService.get<F45ConfigDetailsDto>('F45', 'ConfigDetails');
}

function validateEmailAndPostcode(email: string, postcode: string) {
  return apiService.get<F45EmailPostcodeValidationResultDto>('F45', 'ValidateEmailAndPostcode', undefined, { email, postcode }, true);
}

function getPricingMatrix(filter: DateRangeSearchFilter) {
  return apiService.get<SearchResultsDto<F45PricingMatrixDto>>('ordermetadata', 'F45PricingMatrix', [], filter)
}

function getPricingMatrixById(id?: string) {
  return apiService.get<F45PricingMatrixDto>('ordermetadata', `F45PricingMatrix/${id}`)
}

function updatePricingMatrix(dto: F45PricingMatrixDto, id?: string) {
  return apiService.put<EntitySaveResult>('ordermetadata', `F45PricingMatrix/${id}`, dto);
}