
export enum MealType {
  Main = 0,
  Breakfast = 1
}

export enum MealCategory {
  Normal = 0,
  Veggie = 1,
  Vegan = 2
}

export enum MealClassification {
  None = 0,
  Sweet = 1,
  Savory = 2,
}

export enum BreakfastPreference {
  Alternate = 0,
  Sweet = 1,
  Savory = 2
}

export enum EmailSubscriptions {
  DailyMenu = 0,
  NewsLetter = 1,
}

export enum MealIncrementUnit {
  Gram = 1,
  Unit = 2
}

export enum RecipeType {
  Protein = 1,
  Carbs = 2,
  Fat = 3,
  Veg = 4,
  BaseVeg = 5,
  Sauce = 6,
  Garnish = 7,
  Juice = 8,
  Shake = 9,
  Snack = 10,
  Booster = 11,
  Supplementary = 12,
  Supplement = 13
}

export enum UnitType {
  G100 = 1,
  Unit = 2
}

export enum BagNumber {
  Bag_1 = 1,
  Bag_2 = 2,
  Bag_3 = 3,
  Bag_4 = 4,
  Bag_5 = 5,
}

export enum MealBalancingStatus {
  Unknown = 0,
  Pending = 1,
  ManualBalancingRequired = 2,
  ApprovalRequired = 3,
  AutoApproved = 4,
  Approved = 5,
  FailedDueToErrors = 6
}

export enum TransactionPlatform {
  NotAvailable,
  Stripe,
  PayPal,
  Manual,
  Checkout
}

export enum CoupledRecipeCondition {
  LessThanOrEqualTo = 1,
  GreaterThan = 2
}

export enum CreditCashOutRequestStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2
}

export enum RefundStatus {
  Pending = 0,
  PartiallySettled = 1,
  Settled = 2,
  Failed = 3,
}

export enum CreditRefundOption {
  Manual = 0,
  Online = 1
}

export enum CreditLogsRetrievalType {
  Additions = 0,
  Removals = 1
}

export enum TokenLogsRetrievalType {
  Additions = 0,
  Removals = 1
}

export enum MealBalancingLogAction {
  TemplateApproved = 1,
  BagSaved = 2,
  EnabledForceMealBalancing = 3,
  DisabledForceMealBalancing = 4,
  FixedTemplate = 5,
  UnfixedTemplate = 6
}

export enum OrderType {
  FFF = 0,
  F45 = 1,
  Promotional = 2,
  FoodBox = 3
}

export enum KeyActionType {
  KeyCollected = 0,
  KeyReturned = 1,
  MadeKeyReturnRequest = 2,
  CancelledKeyReturnRequest = 3,
  UpdatedKeyReturnRequest = 4,
}


export enum BagCollectionLogType {
  Pending = 0,
  Collected = 1,
  Charged = 2,
  Refunded = 3,
  Processed = 4
}

export enum AffiliateAbcStatus {
  A = 1,
  B = 2,
  C = 3,
  Staff = 4,
  ToBeAllocated = 5
}

export enum CustomerOnBoardedType {
  Manual = 0,
  Affiliate = 1,
  Referral = 2,
  Online = 3,
  F45 = 4,
  Free = 5,
  Mobile = 6
}

export enum InvoiceType {
  CreditCashOut = 1,
  TokenCashOut = 2
}

export enum InvoiceStatus {
  Pending = 1,
  Paid = 2,
  Overdue = 3,
  Cancelled = 4
}

export enum DeliveryStatus {
  Active = 0,
  Completed = 1,
  Cancelled = 2,
  Pending = 3,
  Drafted = 4,
}

export enum PaymentChannel {
  Bespoke = 1,
  FrozenRange = 2,
  ToGo = 3,
  FoodBox = 4, 
}

export enum PaymentMethod {
  CreditCard = 0,
  PayPal = 1,
  GooglePay = 2,
  ApplePay = 3
}

export enum PaymentType {
  Regular,
  Recurring,
  Moto
}

export enum OrderPlacementStatus {
  InProgress = 1,
  Error = 2,
  Success = 3,
  Failure = 4,
  Incomplete = 5,
  Completing = 6
}

export enum TransactionStatus {
  Successful = 1,
  Failed = 2,
  Authorized = 3,
  Pending = 4
}

export enum FeatureAccessThreshold {
  Full = 0,
  ReadOnly = 1,
  Disabled = 2
}

export enum PaymentPlan {
  Commit = "COMMIT",
  Flexi = "FLEXI"
}

export enum DaysOfWeek {
  Su = 0,
  Mo = 1,
  Tu = 2,
  We = 3,
  Th = 4,
  Fr = 5,
  Sa = 6
}

export enum SchemaApplicablePlatform {
  All = 0,
  Commit = 1,
  Flexi = 2
}

export enum StaticContentLayoutType {
  Partial = 0,
  PageLayout = 1
}

export enum DeliveryContainerType {
  Bag = 0,
  Box = 1
}

export enum BackgroundJobExecutionStatus {
  Pending = 0,
  InProgress = 1,
  Queued = 2,
  Completed = 3
}

export enum OrderPlacementType {
  OrderForm = 1,
  QuickReorder = 2,
  AutoRenew = 3
}

export enum HolidayStatus {
  Added = 0,
  InProcess = 1,
  Completed = 2
}

export enum AmbassadorCategory {
  None = 0,
  Level1 = 1,
  Level2 = 2,
  Level3 = 3
}

export enum DailyMenuItemType {
  Meal = 1,
  Extra = 2
};

export enum DeliveryDayType {
  Delivering = 1,
  Blocked = 2,
  Holiday = 3
}

export enum FoodBoxMenuItemType {
  Meal = 0,
  Extra = 1,
}

export enum MainOrderType {
  Bespoke = 0,
  FoodBox = 1,
  ToGo = 2,
  FrozenFood = 3
}

export enum MarketingSubscriptionType {
  Email,
  Sms
}

export enum FoodBoxSubscriptionStatus {
  Active = 1,
  Cancelled = 2
}

export enum MenuBarType {
  All,
  Side,
  Bottom
}
