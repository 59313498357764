import * as constants from './customer.constants'

import { CustomerInfoActions } from './customer.actions';
import { CustomerReducerState } from '../../types/customer-store.types';

const customerManager = (state: CustomerReducerState = {}, action: CustomerInfoActions) => {
  switch (action.type) {
    case constants.LOAD_CUSTOMER_BASIC_INFO: {
      return { ...state, basicInfo: action.customerInfo }
    }
    case constants.UPDATE_CUSTOMER_ADVANCE_MEAL_SETTINGS: {
      return { ...state, basicInfo: { ...state.basicInfo, ...action.settings } }
    }
    case constants.LOAD_CUSTOMER_DELIVERY_INFO: {
      return { ...state, deliveryInfos: action.deliveryInfos }
    }
    case constants.LOAD_CUSTOMER_NOTES_LIST: {
      return { ...state, customerNotes: action.customerNotes }
    }
    case constants.LOAD_FFF_MEAL_LIST_ITEMS: {
      return { ...state, fffMeals: action.fffMeals }
    }
    case constants.LOAD_CUSTOMER_MEAL_BLACKLIST: {
      return { ...state, mealBlacklist: action.mealBlacklist }
    }
    case constants.LOAD_EXTRA_RECIPES_LIST_ITEMS: {
      return { ...state, extraRecipes: action.extraRecipes }
    }
    case constants.LOAD_CUSTOMER_EXTRAS_BLACKLIST: {
      return { ...state, extrasBlacklist: action.extrasBlacklist }
    }
    case constants.LOAD_CUSTOMER_MEAL_PLAN_INFO: {
      return { ...state, mealPlanInfo: action.mealPlanInfo }
    }
  }

  return state;
}

export const customerManagerReducer = {
  customerDetails: customerManager
}