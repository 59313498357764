import { GoogleOAuthProvider } from "@react-oauth/google";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createBrowserHistory } from "history";
import * as React from "react";
import { useEffect, useState } from "react";
import * as intl from "react-intl-universal";
import { QueryClientProvider } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { config } from "./config";
import { TWENTY_MINUTES_IN_MS } from "./constants";
import FeaturGroupCodes from "./constants/feature-codes.constants";
import QueryClientKeys from "./constants/query-client-key.constants";
import { fffQueryClient } from "./modules/shared/components/QueryClient/FFFQueryClient";
import MaintenancePage from "./modules/shared/components/Response/MaintenancePage";
import GlobalSpinner from "./modules/shared/containers/Spinner/GlobalSpinner";
import { FFFAuthProps, withAuth } from "./modules/shared/hocs/withAuth";
import getBaseRoutes from "./routes";
import { accountService } from "./services/account.service";
import { configurationSettingsService } from "./services/config-settings.service";
import { f45Service } from "./services/f45.service";
import { featureRegistryService } from "./services/feature-registry.service";
import { orderService } from "./services/order.service";
import { lookupService } from "./services/shared/lookup.service";
import { messagingService } from "./services/shared/messaging.service";
import { sessionService } from "./services/shared/session.service";
import { tenantService } from "./services/tenant.service";
import { coreActions } from "./store/core/core.actions";
import { LOAD_FEATURE_REGISTRY, LOAD_GENERAL_CONFIG_SETTINGS } from "./store/system/system.constants";
import { FeatureRegistry } from "./types/feature-store.types";
import { FeatureAccessThreshold } from "./types/fff.enums";
import { AppState, AuthUser } from "./types/shared-store.types";
import { ConfigSettingsDto } from "./types/system.types";
import { TenantConfigurationMap } from "./types/tenant.types";

const Toast = React.lazy(() => import("primereact/toast").then((module) => ({ default: module.Toast })));

export const history = createBrowserHistory();

/** 
 * NOTE: ReactSnap tries to crawl Stripe and throws and error. So this will only load Stripe when it's not ReactSnap.
 * Another fix is to set "skipThirdPartyRequests": true in package.json under reactSnap but it was giving more errors.
*/
const isReactSnap = navigator.userAgent === 'ReactSnap';
const stripePromise = isReactSnap ? null : loadStripe(config.payment.STRIPE_API_KEY);

export interface Props extends FFFAuthProps {
  locale?: string;
  history: any;
}

export interface State {
  initialized: boolean;
  isF45Enabled: boolean;
  horizontallyOriented: boolean;
  featureRegistryLoaded: boolean;
}

const App = ({ locale = "en", auth }: Props) => {
  const [horizontallyOriented, setHorizontallyOriented] = useState(
    navigator.userAgent.indexOf("Mobile") !== -1 &&
    window.innerWidth > window.innerHeight
  );
  const maintenanceModeOn = useSelector(
    (state: AppState) => state.core.maintenanceMode && state.core.maintenanceMode.on
  );
  const currentTenant = useSelector(
    (state: AppState) => state.core.currentTenant
  );
  const localeInitialized = useSelector(
    (state: AppState) => state.core.localeInitialized
  );
  const appInitialized = useSelector(
    (state: AppState) => state.core.appInitialized
  );
  const authInitialized = useSelector(
    (state: AppState) => state.core.authInitialized
  );
  const tenantsLoaded = useSelector(
    (state: AppState) => state.core.tenantsLoaded
  );
  const featureRegistryInitialized = useSelector(
    (state: AppState) => state.core.featureRegistryInitialized
  );

  const dispatch = useDispatch();

  useEffect(() => {
    loadAuthStateFromLocalStore();
    loadLocales();
    handleScreenSizeChange();
    loadOrderMetadata();
  }, []);

  useEffect(() => {
    if (authInitialized) {
      loadTenantsAndDefaultTenant();
    }
  }, [auth, authInitialized])

  useEffect(() => {
    loadLocales(locale);
  }, [locale]);

  useEffect(() => {
    if (authInitialized && currentTenant && currentTenant !== "NO_TENANT") {
      loadFeatureRegistry();
      loadConfigSettings();
      lookupService.fetchGeneralLookups();
    }
  }, [authInitialized, currentTenant]);

  useEffect(() => {
    const initialized =
      (localeInitialized &&
        authInitialized &&
        tenantsLoaded &&
        currentTenant &&
        (featureRegistryInitialized || currentTenant === "NO_TENANT")) ||
      false;

    if (initialized) {
      dispatch(coreActions.setAppInitialized(initialized));
    }
  }, [
    localeInitialized,
    featureRegistryInitialized,
    authInitialized,
    currentTenant,
    tenantsLoaded,
  ]);

  const loadTenantsAndDefaultTenant = () => {
    if (config.web.SINGLE_TENANT_MODE) {
      sessionService.setTenantCode(config.web.SINGLE_TENANT_MODE);
      dispatch(coreActions.setCurrentTenant(config.web.SINGLE_TENANT_MODE));
      dispatch(coreActions.setTenantsLoaded(true));
    } else {
      accountService
        .getRelatedTenants((auth && auth.user.id) || "")
        .then((tenants) => {
          dispatch(coreActions.setAllTenants(tenants));

          var tenantIdInPath = getTenantIdFromPath();

          const currentTenant =
            tenantIdInPath &&
              tenants.some((t) => t.code.toLowerCase() === tenantIdInPath)
              ? tenantIdInPath.toUpperCase()
              : tenantService.getSelectedTenant(tenants);

          sessionService.setTenantCode(currentTenant);
          dispatch(coreActions.setCurrentTenant(currentTenant));

          if (currentTenant.toUpperCase() !== "NO_TENANT") {
            tenantService.getTenantConfigurations()
              .then((configs: TenantConfigurationMap) => {
                sessionService.setTenantConfiguration(configs);
                dispatch(coreActions.setTenantsLoaded(true));
              });
          } else {
            dispatch(coreActions.setTenantsLoaded(true));
          }
        });
    }
  };

  const loadAuthStateFromLocalStore = () => {
    const authState = sessionService.getAuthState();
    if (authState) {
      const authUser: AuthUser = {
        user: authState.user,
        defaultTenant: authState.defaultTenant,
        isImpersonating: authState.isImpersonating,
        permissions: authState.permissions,
        subscribedTenants: authState.subscribedTenants,
      };

      dispatch(coreActions.setAuthUser(authUser));
    } else {
      dispatch(coreActions.clearAuthUser());
    }
    dispatch(coreActions.setAuthInitialized(true));
  };

  const loadFeatureRegistry = () => {
    featureRegistryService
      .getFeatureRegistry()
      .then((featureRegistry: FeatureRegistry) => {
        dispatch({ type: LOAD_FEATURE_REGISTRY, featureRegistry });
        dispatch(coreActions.setFeatureRegistryInitialized(true));
      });
  };

  const loadLocales = (newLocale?: string) => {
    let currentLocale: string;
    if (localStorage.getItem("locale") === null) {
      currentLocale = intl.determineLocale({
        cookieLocaleKey: "lang",
        urlLocaleKey: "lang",
      });
    } else {
      if (newLocale) {
        currentLocale = newLocale;
      } else {
        currentLocale = locale;
      }
    }

    if (newLocale !== undefined) {
      currentLocale = newLocale;
    }

    if (currentLocale.includes("-")) {
      currentLocale = currentLocale.substring(0, 2);
    }

    import(`./locales/${currentLocale}/${currentLocale}`)
      .then(({ locale }) =>
        intl
          .init({ currentLocale, locales: { [currentLocale]: locale } })
          .then(() => {
            localStorage.setItem("locale", currentLocale);
            dispatch(coreActions.setLocaleInitialized(true));
          })
      )
      .catch(() =>
        import(`./locales/en/en`).then(({ locale }) =>
          intl
            .init({ currentLocale, locales: { [currentLocale]: locale } })
            .then(() => {
              localStorage.setItem("locale", currentLocale);
              dispatch(coreActions.setLocaleInitialized(true));
            })
        )
      );

  };

  const loadOrderMetadata = () => {
    fffQueryClient.prefetchQuery(QueryClientKeys.OrderFormOrderMetadata,
      () => orderService.getAllOrderFormMetadata(false, false),
      { staleTime: TWENTY_MINUTES_IN_MS }
    );
  }

  const handleScreenSizeChange = () => {
    window.addEventListener("resize", function () {
      setHorizontallyOriented(
        navigator.userAgent.indexOf("Mobile") != -1 &&
        window.innerWidth > window.innerHeight
      );

    }, false);
  };

  const loadConfigSettings = () => {
    f45Service.getF45EnabledConfigSetting().then((isF45Enabled: boolean) => {
      dispatch(coreActions.setF45Initialized(isF45Enabled));
    });

    configurationSettingsService
      .getGeneralConfigSettings()
      .then((generalConfigSettings: ConfigSettingsDto[]) => {
        dispatch({ type: LOAD_GENERAL_CONFIG_SETTINGS, generalConfigSettings });
      });
  };

  if (maintenanceModeOn) {
    return <MaintenancePage />
  }

  const googleClientId = config.socialSignIn.googleClientId;

  return appInitialized && googleClientId ? (
    <QueryClientProvider client={fffQueryClient}>
      <Elements stripe={stripePromise}>
        {horizontallyOriented && (
          <div id="app-overlay">
            <i className="material-icons">screen_rotation</i>
            <div>{intl.get("TXT_LANDSCAPE_VIEW")}</div>
          </div>
        )}
        <GoogleOAuthProvider clientId={googleClientId}>
          <React.Suspense fallback={null}>
            <AppRoutes />
            <Toast
              baseZIndex={99999}
              ref={(el) => messagingService.setGrowlRef(el)}
            />
            <GlobalSpinner />
          </React.Suspense>
        </GoogleOAuthProvider>
      </Elements>
    </QueryClientProvider>
  ) : null;
};

export default withAuth(App);

const AppRoutes = () => {
  const allTenants = useSelector((state: AppState) => state.core.allTenants);

  const currentTenant = useSelector(
    (state: AppState) => state.core.currentTenant || ""
  );

  const singleTenantMode = !!config.web.SINGLE_TENANT_MODE;

  useEffect(() => {
    if (!singleTenantMode) {
      const tenantId = getTenantIdFromPath();

      if (tenantId) {
        const isValidTenant =
          (!allTenants.length && tenantId === "no_tenant") ||
          allTenants.some(
            (c) => c.code.toLowerCase() === tenantId.toLowerCase()
          );

        if (isValidTenant) {
          if (currentTenant.toLowerCase() !== tenantId.toLowerCase()) {
            const { pathname, search } = window.location;
            sessionService.setTenantCode(tenantId.toUpperCase());

            window.location.replace(`${pathname}${search}`);
          }
        } else {
          const defaultTenantId = currentTenant.toLowerCase();
          const { pathname, search } = window.location;
          sessionService.setTenantCode(defaultTenantId);

          window.location.replace(`/${defaultTenantId}${pathname}${search}`);
        }
      } else {
        const defaultTenantId = currentTenant.toLowerCase();
        sessionService.setTenantCode(defaultTenantId);

        window.location.replace(`/${defaultTenantId}`);
      }
    }
  }, []);

  const f45Enabled = useSelector((state: AppState) => state.core.f45Enabled);

  const featureReg = useSelector(
    (state: AppState) => state.systemData.featureRegistry
  );

  const f45Subscribed =
    (featureReg &&
      featureReg[FeaturGroupCodes.F45] === FeatureAccessThreshold.Full) ||
    false;

  const basePath = sessionService.getTenantCode() || "";

  return (
    <>
      <BrowserRouter basename={singleTenantMode ? undefined : `/${basePath.toLowerCase()}`}>
        <Switch>
          {getBaseRoutes(f45Enabled, f45Subscribed).map((route, key) => {
            return (
              <Route
                path={route.path}
                key={key}
                component={route.component}
                exact={route.exact}
              />
            );
          })}
        </Switch>
      </BrowserRouter>
    </>
  );
};

function getTenantIdFromPath() {
  const parsedData = window.location.pathname.split("/");
  const tenantId =
    parsedData.length >= 1 ? parsedData[1].toLowerCase() : undefined;
  return tenantId;
}
