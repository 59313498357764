import { MealBalancingState } from "../../types/meal-balancing.types";
import { MealBalancingActions } from "./meal-balancing.actions";
import * as constants from "./meal-balancing.constants";

export const mealBalancingReducer = {
  mealBalancing
}

function mealBalancing(state: MealBalancingState = {}, action: MealBalancingActions) {
  switch (action.type) {
    case constants.LOAD_ORDER_INFO: {
      return { ...state, orderInfo: action.orderInfo }
    }
    case constants.LOAD_FFF_MEALS: {
      return { ...state, meals: action.meals }
    }
    case constants.LOAD_SUPPLEMENTARY_MEALS: {
      return { ...state, supplementaryMeals: action.supplementaryMeals }
    }
    case constants.LOAD_ELIGIBLE_FFF_MEALS_FOR_ALL_TEMPLATES: {
      return { ...state, templateEligibleMeals: action.templateEligibleMeals }
    }
    case constants.LOAD_ELIGIBLE_FFF_MEALS_FOR_RECENT_TEMPLATES: {
      return { ...state, recentTemplateEligibleMeals: action.recentTemplateEligibleMeals }
    }
    case constants.LOAD_FFF_EXTRAS: {
      return { ...state, extras: action.extras }
    }
    case constants.LOAD_FFF_DAYS: {
      return { ...state, days: action.days }
    }
    case constants.LOAD_ORDER_MEAL_TEMPLATES: {
      return { ...state, orderMealTemplates: action.orderMealTemplates }
    }
    case constants.UPDATE_MEAL_TEMPLATE: {
      if (state.orderMealTemplates) {
        let orderMealTemplates = [...state.orderMealTemplates];
        let index = orderMealTemplates.findIndex(t => t.templateId == action.templateId);
        let template = orderMealTemplates[index];
        orderMealTemplates.splice(index, 1, { ...template, ...action.obj });
        return { ...state, orderMealTemplates };
      }
      break;
    }
    case constants.LOAD_TEMPLATE_LOGS: {
      var eligibleMeals = state.templateEligibleMeals && [...state.templateEligibleMeals];

      var template = eligibleMeals && eligibleMeals.find(m => m.templateId == action.templateId);

      template && (template.logs = action.logs);

      return { ...state, templateEligibleMeals: eligibleMeals }
    }
    case constants.LOAD_BALANCER_VERSION: {
      return { ...state, balancerVersion: action.balancerVersion }
    }
    case constants.LOAD_MEAL_ALLERGENS: {
      return { ...state, mealAllergens: action.mealAllergens }
    }
    case constants.CLEAR_MEAL_BALANCING_STATE: {
      return {}
    }
    default:
  }
  return state;
}