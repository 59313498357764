import * as constants from './driver.constants';
import { DriverListActions } from './driver.actions';
import { DriverState } from '../../types/driver-store.types';

const driverReducer = (state: DriverState = {}, action: DriverListActions) => {
  switch (action.type) {
    case constants.LOAD_DRIVER_LIST: {
      return {
        ...state, list: {
          items: action.items,
          total: action.total
        }
      }
    }
  }

  return state;
}

export const DriverReducer = {
  driver: driverReducer
};