import { EntitySaveResult } from "../types/shared-dto.types";
import { ConfigSettingsDto, ConfigSettingsSearchFilter, ConfigSettingsSearchResults } from '../types/system.types';
import { apiService } from "./shared/api.service";

const getValueByCode = (code: string) => {
  return apiService.get<string>('ConfigurationSettings', 'value', [code]);
}

const getValuesByCodes = (codes: string[]) => {
  return apiService.get<any>('ConfigurationSettings', 'values', [], { codes: codes });
}

const getAll = (filter: ConfigSettingsSearchFilter) => {
  return apiService.get<ConfigSettingsSearchResults>('ConfigurationSettings', undefined, undefined, filter);
}

const get = (id: string) => {
  return apiService.get<ConfigSettingsDto>('ConfigurationSettings', undefined, [id]);
}

const getByCode = (code: string) => {
  return apiService.get<ConfigSettingsDto>('ConfigurationSettings', 'code', [code]);
}

const update = (setting: ConfigSettingsDto) => {
  return apiService.put<EntitySaveResult>('ConfigurationSettings', '', setting)
}

const getWeeklySurplus = () => {
  return apiService.get<ConfigSettingsDto>('ConfigurationSettings', 'WeeklySurplus');
}

const getGeneralConfigSettings = () => {
  return apiService.get<ConfigSettingsDto[]>('ConfigurationSettings', 'General');
}

const getSeasonalCampaignStatus = () => {
  return apiService.get<ConfigSettingsDto>('ConfigurationSettings', 'SeasonalCampaignStatus');
}

export const configurationSettingsService = {
  getValueByCode,
  getValuesByCodes,
  getAll,
  update,
  get,
  getByCode,
  getWeeklySurplus,
  getGeneralConfigSettings,
  getSeasonalCampaignStatus
}