import * as constants from './permission.constants';
import { PermissionManagement } from '../../types/permission-store';
import { PermissionAction } from './permission.actions';

export const permissionReducer = {
    permissionManagement
}

function permissionManagement(state: PermissionManagement = {}, action: PermissionAction) {
    switch (action.type) {
        case constants.LOAD_PERMISSIONS: {
            return { ...state, permissions: action.permissions }
        }
        case constants.LOAD_ROLES: {
            return { ...state, roles: action.roles }
        }
        case constants.SET_SELECTED_ROLE: {
            return { ...state, selectedRoleName: action.roleName }
        }
        case constants.ADD_PERMISSION_TO_ROLE: {
            if (state.selectedRoleName && state.roles) {
                let selectedRoleIndex = state.roles.findIndex(r => r.name == state.selectedRoleName)
                let selectedRole = state.roles[selectedRoleIndex];

                if (!selectedRole.claims.includes(action.permission)) {

                    let claims = [...selectedRole.claims, action.permission];

                    selectedRole = { ...selectedRole, claims };

                    let roles = [...state.roles];
                    roles.splice(selectedRoleIndex, 1, selectedRole)

                    return { ...state, roles }
                }
                else{
                    return {...state}
                }
            }
        }
        case constants.REMOVE_PERMISSION_FROM_ROLE: {
            if (state.selectedRoleName && state.roles) {
                let selectedRoleIndex = state.roles.findIndex(r => r.name == state.selectedRoleName)
                let selectedRole = state.roles[selectedRoleIndex];

                let claims = [...selectedRole.claims];
                let claimIndex = claims.indexOf(action.permission);
                claims.splice(claimIndex, 1);

                selectedRole = { ...selectedRole, claims };
                let roles = [...state.roles];
                roles.splice(selectedRoleIndex, 1, selectedRole)

                return { ...state, selectedRole, roles }
            }
        }
    }
    return state;
}