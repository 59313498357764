export const LOAD_PERMISSIONS = 'LOAD_PERMISSIONS'
export type LOAD_PERMISSIONS = typeof LOAD_PERMISSIONS

export const LOAD_ROLES = 'LOAD_ROLES'
export type LOAD_ROLES = typeof LOAD_ROLES

export const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE'
export type SET_SELECTED_ROLE = typeof SET_SELECTED_ROLE

export const ADD_PERMISSION_TO_ROLE = 'ADD_PERMISSION_TO_ROLE'
export type ADD_PERMISSION_TO_ROLE = typeof ADD_PERMISSION_TO_ROLE

export const REMOVE_PERMISSION_FROM_ROLE = 'REMOVE_PERMISSION_FROM_ROLE'
export type REMOVE_PERMISSION_FROM_ROLE = typeof REMOVE_PERMISSION_FROM_ROLE