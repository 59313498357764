export const User = {
  search: "USERS_SEARCH",
  View: "USERS_VIEW",
  Create: "USERS_CREATE",
  Update: "USERS_UPDATE",
  Delete: "USERS_DELETE",
  EditRoles: "USERS_EDIT_ROLES",
  Impersonate: "USERS_IMPERSONATE",
  ImpersonateWithPriorityLeadTime: "USERS_IMPERSONATE_PRIORITY_LEAD_TIME",
  CleanData: "USERS_CLEAN_DATA",
  Reset2FA: "USERS_RESET_2FA"
};

export const UserActions = {
  AddCard: "USER_ACTIONS_ADD_CARD",
  UpdateCard: "USER_ACTIONS_UPDATE_CARD",
};

export const RoleType = {
  cmsUser: "ROLE_TYPE_CMS_USER",
  publicUser: "ROLE_TYPE_PUBLIC_USER",
  franchiseAdminUser: "ROLE_TYPE_FRANCHISE_ADMIN_USER"
};

export const UserRoles = {
  Admin: "Admin",
  Staff: "Staff",
  Analyst: "Analyst",
  Affiliate: "Affiliate",
  Customer: "Customer",
  SalesPerson: "SalesPerson",
  SuperAdmin: "SuperAdmin",
  BusinessUser: "BusinessUser",
  PartialCustomer: "PartialCustomer",
};

export const Affiliate = {
  Search: "AFFILIATE_SEARCH",
  View: "AFFILIATE_VIEW",
  Update: "AFFILIATE_UPDATE",
  Delete: "AFFILIATE_DELETE",
  Create: "AFFILIATE_CREATE",
  ViewUsageStatus: "AFFILIATE_VIEW_USAGE_STATUS",
  ViewHistory: "AFFILIATE_VIEW_HISTORY",
  ViewDiscountCodes: "AFFILIATE_VIEW_DISCOUNT_CODES",
  ViewMySignups: "AFFILIATE_VIEW_MYSIGNUPS",
};

export const AffiliateRequest = {
  Search: "AFFILIATE_REQUEST_SEARCH",
  View: "AFFILIATE_REQUEST_VIEW",
  Approve: "AFFILIATE_REQUEST_APPROVE",
  Reject: "AFFILIATE_REQUEST_REJECT",
};

export const SalesTarget = {
  Search: "SALES_TARGET_SEARCH",
  View: "SALES_TARGET_VIEW",
  Update: "SALES_TARGET_UPDATE",
  Delete: "SALES_TARGET_DELETE",
  Create: "SALES_TARGET_CREATE",
};

export const AffiliateSales = {
  Search: "AFFILIATE_SALES_SEARCH",
};

export const BusinessUser = {
  Search: "BUSINESS_USER_SEARCH",
  View: "BUSINESS_USER_VIEW",
  Update: "BUSINESS_USER_UPDATE",
  Delete: "BUSINESS_USER_DELETE",
};

export const BusinessUserRequest = {
  Search: "BUSINESS_USER_REQUEST_SEARCH",
  View: "BUSINESS_USER_REQUEST_VIEW",
  Approve: "BUSINESS_USER_REQUEST_APPROVE",
  Reject: "BUSINESS_USER_REQUEST_REJECT",
};

export const Driver = {
  Search: "DRIVER_SEARCH",
  View: "DRIVER_VIEW",
  Create: "DRIVER_CREATE",
  Update: "DRIVER_UPDATE",
  Delete: "DRIVER_DELETE",
};

export const Holiday = {
  Search: "HOLIDAY_SEARCH",
  View: "HOLIDAY_VIEW",
  Create: "HOLIDAY_CREATE",
  Update: "HOLIDAY_UPDATE",
  Delete: "HOLIDAY_DELETE",
};

export const PostCode = {
  Search: "POSTCODE_SEARCH",
  View: "POSTCODE_VIEW",
  Create: "POSTCODE_CREATE",
  Update: "POSTCODE_UPDATE",
  Delete: "POSTCODE_DELETE",
};

export const SalesPerson = {
  Search: "SALES_PERSON_SEARCH",
  View: "SALES_PERSON_VIEW",
  Create: "SALES_PERSON_CREATE",
  Update: "SALES_PERSON_UPDATE",
  Delete: "SALES_PERSON_DELETE",
};

export const Permission = {
  Search: "PERMISSION_SEARCH",
  Modify: "PERMISSION_MODIFY",
};

export const GuestOrder = {
  Search: "GUEST_ORDER_SEARCH",
  View: "GUEST_ORDER_VIEW",
  Delete: "GUEST_ORDER_DELETE",
};

export const Customer = {
  Search: "CUSTOMER_SEARCH",
  View: "CUSTOMER_VIEW",
  Create: "CUSTOMER_CREATE",
  Update: "CUSTOMER_UPDATE",
  Delete: "CUSTOMER_DELETE",
  AddCredit: "CUSTOMER_ADD_CREDIT",
  RemoveCredit: "CUSTOMER_REMOVE_CREDIT",
  AddToken: "CUSTOMER_ADD_TOKEN",
  RemoveToken: "CUSTOMER_REMOVE_TOKEN",
  Block: "CUSTOMER_BLOCK",
  ManageAdvanceMealSettings: "CUSTOMER_MANAGE_ADVANCE_MEAL_SETTINGS",
};

export const PartialCustomer = {
  Search: "PARTIAL_CUSTOMER_SEARCH",
  View: "PARTIAL_CUSTOMER_VIEW",
  Update: "PARTIAL_CUSTOMER_UPDATE",
  GDPRCleanUp: "PARTIAL_CUSTOMER_GDPRCLEANUP"
};

export const CustomerNotes = {
  Search: "CUSTOMER_NOTES_SEARCH",
  View: "CUSTOMER_NOTES_VIEW",
  Create: "CUSTOMER_NOTES_CREATE",
  Update: "CUSTOMER_NOTES_UPDATE",
  Delete: "CUSTOMER_NOTES_DELETE",
};

export const ClientDashboard = {
  View: "CLIENT_DASHBOARD_VIEW",
  ManageDelivery: "CLIENT_DASHBOARD_MANAGE_DELIVERY",
  ManageOrder: "CLIENT_DASHBOARD_MANAGE_ORDER",
  ViewDailyMenu: "CLIENT_DASHBOARD_VIEW_DAILY_MENU",
  ManageNutrition: "CLIENT_DASHBOARD_MANAGE_NUTRITION",
  ViewReferrals: "CLIENT_DASHBOARD_VIEW_REFERRALS",
  ManageAccountInfo: "CLIENT_DASHBOARD_MANAGE_ACCOUNT_INFO",
  ManagePaymentMethods: "CLIENT_DASHBOARD_MANAGE_PAYMENT_METHODS",
  ManageNotificationSettings: "CLIENT_DASHBOARD_MANAGE_NOTIFICATION_SETTINGS",
  ViewAccountLogs: "CLIENT_DASHBOARD_VIEW_ACCOUNT_LOGS",
  ViewClientOffers: "CLIENT_DASHBOARD_VIEW_CLIENT_OFFERS",
  ManageProfile: "CLIENT_DASHBOARD_MANAGE_PROFILE_INFO", // Obsolete
  ManageTokens: "CLIENT_DASHBOARD_MANAGE_TOKENS", // Obsolete
  ManageAffiliate: "CLIENT_DASHBOARD_MANAGE_AFFILIATE", // Obsolete
  ManageAmbassador: "CLIENT_DASHBOARD_MANAGE_AMBASSADOR" // Obsolete
};

export const Order = {
  Pause: "ORDER_PAUSE",
  Resume: "ORDER_RESUME",
  Cancel: "ORDER_CANCEL",
  ReOrder: "ORDER_REORDER",
  Search: "ORDER_SEARCH",
  View: "ORDER_VIEW",
  AddFreeBag: "ORDER_ADD_FREE_BAG",
  ChangeStartDate: "ORDER_CHANGE_START_DATE",
  EditOrder: "ORDER_EDIT",
  SetCustomOrderStatus: "ORDER_SET_CUSTOM_ORDER_STATUS"
};

export const Payment = {
  MakeMotoPayments: "PAYMENT_MAKE_MOTO_PAYMENTS",
};

export const WeeklyPayment = {
  Search: "WEEKLY_PAYMENTS_SEARCH",
  MakePayments: "WEEKLY_PAYMENTS_MAKE_PAYMENTS",
};

export const MealBalancing = {
  view: "MEAL_BALANCING_VIEW",
  approve: "MEAL_BALANCING_APPROVE",
  modifyBags: "MEAL_BALANCING_MODIFIY_BAGS",
  forceMealSelection: "MEAL_BALANCING_FORCE_MEAL_SELECTION",
};

export const DiscountCode = {
  Search: "DISCOUNT_CODE_SEARCH",
  View: "DISCOUNT_CODE_VIEW",
  Create: "DISCOUNT_CODE_CREATE",
  Update: "DISCOUNT_CODE_UPDATE",
  Delete: "DISCOUNT_CODE_DELETE",
};

export const SystemAudit = {
  Search: "SYSTEM_AUDIT_SEARCH",
};

export const SystemErrorLog = {
  View: "SYSTEM_ERROR_LOGS_VIEW",
};

export const CreditCashOutRequest = {
  Search: "CREDIT_CASH_OUT_REQUEST_SEARCH",
  View: "CREDIT_CASH_OUT_REQUEST_VIEW",
  Request: "CREDIT_CASH_OUT_REQUEST_REQUEST",
  Edit: "CREDIT_CASH_OUT_REQUEST_EDIT",
  Delete: "CREDIT_CASH_OUT_REQUEST_DELETE",
  Approve: "CREDIT_CASH_OUT_REQUEST_APPROVE",
  Reject: "CREDIT_CASH_OUT_REQUEST_REJECT",
};

export const DailyDeliveries = {
  Search: "DAILY_DELIVERY_SEARCH",
  View: "DAILY_DELIVERY_VIEW",
};

export const MealManagement = {
  create: "MEAL_MANAGEMENT_CREATE",
  delete: "MEAL_MANAGEMENT_DELETE",
  update: "MEAL_MANAGEMENT_UPDATE",
  view: "MEAL_MANAGEMENT_VIEW",
  search: "MEAL_MANAGEMENT_SEARCH",
  bulkUpload: "MEAL_MANAGEMENT_MEAL_SHEET_BULK_UPLOAD",
  flushAndRebalanceAllOrders: "MEAL_BALANCING_FLUSH_AND_REBALANCE_ALL_ORDERS",
  analytics: "MEAL_MANAGEMENT_ANALYTICS",
};

export const ConfigurationSetting = {
  update: "CONFIGURATION_SETTING_UPDATE",
  search: "CONFIGURATION_SETTING_SEARCH",
  view: "CONFIGURATION_SETTING_VIEW",
};

export const FinancialAnalytics = {
  Orders: "FINANCIAL_ANALYTICS_ORDERS",
  Delivery: "FINANCIAL_ANALYTICS_DELIVERY",
  DeferredRevenue: "FINANCIAL_ANALYTICS_DEFERRED_REVENUE",
  FreeBagsAndCreditLog: "FINANCIAL_ANALYTICS_FREE_BAGS_AND_CREDIT_LOG",
  LedgerBalance: "FINANCIAL_ANALYTICS_LEDGER_BALANCE",
  Reconciliation: "FINANCIAL_ANALYTICS_RECONCILIATION",
  ExpiredBagsAndCredits: "FINANCIAL_ANALYTICS_EXPIRED_ORDERS_AND_CREDIT",
  FOOD_COST: "FINANCIAL_ANALYTICS_FOOD_COST"
};

export const DeliveryInfo = {
  UpdateHasKeyStatus: "DELIVERY_INFO_UPDATE_HAS_KEY_STATUS",
  Create: "DELIVERY_INFO_CREATE",
  Update: "DELIVERY_INFO_UPDATE",
  Delete: "DELIVERY_INFO_DELETE",
};

export const KeyReturnRequest = {
  Create: "KEY_RETURN_REQUEST_CREATE",
  Cancel: "KEY_RETURN_REQUEST_CANCEL",
  Update: "KEY_RETURN_REQUEST_UPDATE",
};

export const SpecialPermissionsClaims = {
  ImpersonateWithPriority: "USERS_IMPERSONATE_WITH_PRIORITY_LEAD_TIME",
};

export const KeyLogs = {
  Search: "KEYS_SEARCH",
  View: "KEYS_VIEW",
};

export const ContentManagement = {
  Edit: "CONTENT_MANAGEMENT_EDIT",
  Search: "CONTENT_MANAGEMENT_SEARCH",
  View: "CONTENT_MANAGEMENT_VIEW",
};

export const StaticContentManagement = {
  Create: "STATIC_CONTENT_MANAGEMENT_CREATE",
  Edit: "STATIC_CONTENT_MANAGEMENT_EDIT",
  Search: "STATIC_CONTENT_MANAGEMENT_SEARCH",
  View: "STATIC_CONTENT_MANAGEMENT_VIEW",
};

export const TransactionHistory = {
  View: "TRANSACTION_HISTORY_VIEW",
};

export const AddAddress = {
  AddAddress: "ADDRESS_ADD",
};

export const PromotionalCodes = {
  Search: "PROMOTIONAL_CODE_SEARCH",
  View: "PROMOTIONAL_CODE_VIEW",
  Create: "PROMOTIONAL_CODE_CREATE",
  Update: "PROMOTIONAL_CODE_UPDATE",
  Delete: "PROMOTIONAL_CODE_DELETE",
};

export const EmailSettings = {
  Search: "EMAIL_SETTINGS_SEARCH",
  CreateOrUpdate: "EMAIL_SETTINGS_CREATE_OR_UPDATE",
  Delete: "EMAIL_SETTINGS_DELETE",
};

export const CancelledOrders = {
  Search: "CANCELLED_ORDERS_SEARCH",
};

export const Invoices = {
  Search: "INVOICE_SEARCH",
  View: "INVOICE_VIEW",
  Create: "INVOICE_CREATE",
  Update: "INVOICE_UPDATE",
  Delete: "INVOICE_DELETE",
  ConfirmPayment: "INVOICE_CONFIRM_PAYMENT",
};

export const LoyaltyToken = {
  ConvertToCredit: "LOYALTY_TOKEN_CONVERT_TO_CREDIT",
  CashOut: "LOYALTY_TOKEN_CASH_OUT",
};

export const FrozenFoodInventory = {
  Search: "FROZEN_FOOD_INVENTORY_SEARCH",
  Update: "FROZEN_FOOD_INVENTORY_UPDATE",
  View: "FROZEN_FOOD_INVENTORY_VIEW",
  Delete: "FROZEN_FOOD_INVENTORY_DELETE",
  Create: "FROZEN_FOOD_INVENTORY_CREATE"
};

export const FrozenFoodDelivery = {
  Search: "FROZEN_FOOD_DELIVERY_SEARCH",
  View: "FROZEN_FOOD_DELIVERY_VIEW",
};

export const TrialOrders = {
  Search: "TRIAL_ORDERS_SEARCH",
};

export const FlexiOrders = {
  Search: "FLEXI_ORDERS_SEARCH",
};

// ALL RELATED TO FRONT END
export const Supplement = {
  PlaceOrder: "SUPPLEMENT_PLACE_ORDER",
  ViewHistory: "SUPPLEMENT_VIEW_HISTORY",
  UpdateOrder: "SUPPLEMENT_UPDATE_ORDER",
  CancelOrder: "SUPPLEMENT_CANCEL_ORDER",
  ViewOrder: "SUPPLEMENT_VIEW_ORDER",
  ViewQuiz: "SUPPLEMENT_VIEW_QUIZ",
  ViewList: "SUPPLEMENT_VIEW_LIST"
};

// ALL RELATED TO CRM SUPPLEMENTS
export const SupplementInventory = {
  Search: "SUPPLEMENT_INVENTORY_SEARCH",
  Create: "SUPPLEMENT_INVENTORY_CREATE",
  Update: "SUPPLEMENT_INVENTORY_UPDATE",
  View: "SUPPLEMENT_INVENTORY_VIEW",
  Delete: "SUPPLEMENT_INVENTORY_DELETE"
};
// ALL RELATED TO CRM ORDERS
export const SupplementOrders = {
  Search: "SUPPLEMENT_ORDERS_SEARCH",
  View: "SUPPLEMENT_ORDERS_VIEW"
};

export const ToGoMealManagement = {
  Search: "TOGO_MEAL_MANAGEMENT_SEARCH",
  Create: "TOGO_MEAL_MANAGEMENT_CREATE",
  Update: "TOGO_MEAL_MANAGEMENT_UPDATE",
  View: "TOGO_MEAL_MANAGEMENT_VIEW",
  Delete: "TOGO_MEAL_MANAGEMENT_DELETE",
};

export const FoodBox = {
  ViewAllOrders: "FOOD_BOX_VIEW_ALL_ORDERS",
  ViewDailyDeliveries: "FOOD_BOX_VIEW_DAILY_DELIVERIES",
}

export const FoodBoxMealManagement = {
  Search: "FOOD_BOX_MEAL_MANAGEMENT_SEARCH",
  Create: "FOOD_BOX_MEAL_MANAGEMENT_CREATE",
  Update: "FOOD_BOX_MEAL_MANAGEMENT_UPDATE",
  Delete: "FOOD_BOX_MEAL_MANAGEMENT_DELETE",
};

export const ToGoDailyDelivery = {
  Search: "TOGO_DAILY_DELIVERY_SEARCH",
  View: "TOGO_DAILY_DELIVERY_VIEW",
  Download: "TOGO_DAILY_DELIVERY_DOWNLOAD",
};

export const ToGo = {
  PlaceOrders: "TOGO_PLACE_ORDERS",
  ViewOrderHistory: "TOGO_VIEW_ORDER_HISTORY",
  ManageAccount: "TOGO_MANAGE_ACCOUNT",
};

export const ToGoOrders = {
  Search: "TOGO_ORDERS_SEARCH",
  View: "TOGO_ORDERS_VIEW",
  Update: "TOGO_ORDERS_UPDATE",
  Create: "TOGO_ORDERS_CREATE",
  Delete: "TOGO_ORDERS_DELETE",
};

export const ToGoEvents = {
  View: "TOGO_EVENTS_VIEW",
  Update: "TOGO_EVENTS_UPDATE",
  Create: "TOGO_EVENTS_CREATE",
  Delete: "TOGO_EVENTS_DELETE",
  Review: "TOGO_EVENTS_REVIEW",
};

export const Miscellaneous = {
  view: "MISCELLANEOUS_VIEW",
  F45OrderSync: "MISCELLANEOUS_F45_ORDER_SYNC",
  BulkUploadCheckoutUsers: "MISCELLANEOUS_BULK_UPLOAD_CHECKOUT_USERS",
  updateMealCosts: "MISCELLANEOUS_UPDATE_MEAL_COSTS",
  HubspotSync: "MISCELLANEOUS_HUBSPOT_SYNC",
  SkipAllOrders: "MISCELLANEOUS_SKIP_ALL_ORDERS",
  OrderDataMigration: "MISCELLANEOUS_ORDER_DATA_MIGRATION",
  BulkUpdateIngredients: "MISCELLANEOUS_BULK_UPDATE_INGREDIENTS",
  BulkUpdateRecipeKitchenSections: "MISCELLANEOUS_BULK_UPDATE_RECIPE_KITCHEN_SECTIONS",
  Stripe: "MISCELLANEOUS_STRIPE",
  Klaviyo: "MISCELLANEOUS_KLAVIYO",
  FoodBox: "MISCELLANEOUS_FOODBOX",
  AffiliateNewDiscountCodeMigration: "MISCELLANEOUS_AFFILIATE_NEW_DISCOUNT_CODE_MIGRATION",
};

export const Notification = {
  Send: "NOTIFICATION_SEND",
};

export const FoodDb = {
  bulkUploadRestaurants: "FOOD_DB_BULK_UPLOAD_RESTAURANTS",
};

export const FitnessCenterWorkoutCategory = {
  Search: "FITNESS_CENTER_WORKOUT_CATEGORY_SEARCH",
  Create: "FITNESS_CENTER_WORKOUT_CATEGORY_CREATE",
  Update: "FITNESS_CENTER_WORKOUT_CATEGORY_UPDATE",
  Delete: "FITNESS_CENTER_WORKOUT_CATEGORY_DELETE",
};

export const FitnessCenterWorkoutTrainer = {
  Search: "FITNESS_CENTER_WORKOUT_TRAINER_SEARCH",
  Create: "FITNESS_CENTER_WORKOUT_TRAINER_CREATE",
  Update: "FITNESS_CENTER_WORKOUT_TRAINER_UPDATE",
  Delete: "FITNESS_CENTER_WORKOUT_TRAINER_DELETE",
};

export const FitnessCenterWorkoutVideo = {
  Search: "FITNESS_CENTER_WORKOUT_VIDEO_SEARCH",
  Create: "FITNESS_CENTER_WORKOUT_VIDEO_CREATE",
  Update: "FITNESS_CENTER_WORKOUT_VIDEO_UPDATE",
  Delete: "FITNESS_CENTER_WORKOUT_VIDEO_DELETE",
  ViewCustomerVideos: "FITNESS_CENTER_WORKOUT_VIDEO_VIEW_CUSTOMER_VIDEOS",
};

export const VideoLibrary = {
  Search: "VIDEO_LIBRARY_SEARCH",
  View: "VIDEO_LIBRARY_VIEW",
  Create: "VIDEO_LIBRARY_CREATE",
  Update: "VIDEO_LIBRARY_UPDATE",
  Delete: "VIDEO_LIBRARY_DELETE",
};

export const FrozenRange = {
  View: "FROZEN_RANGE_VIEW",
  PlaceOrders: "FROZEN_RANGE_PLACE_ORDERS"
}

export const F45 = {
  View: "F45_VIEW",
  PlaceOrders: "F45_PLACE_ORDERS"
}

export const Tenants = {
  Manage: "TENANTS_MANAGE"
}

export const Lookups = {
  Search: "LOOKUPS_SEARCH",
  View: "LOOKUPS_VIEW",
  Create: "LOOKUPS_CREATE",
  Update: "LOOKUPS_UPDATE",
  Delete: "LOOKUPS_DELETE",
};

export const DeliveryActions = {
  SkipDay: "DELIVERY_ACTIONS_SKIP_DAY", // Skip a whole day
  SkipDays: "DELIVERY_ACTIONS_SKIP_DAYS", // Skip multiple days
  SkipBags: "DELIVERY_ACTIONS_SKIP_BAGS", // Skip bags from a day
  MoveBags: "DELIVERY_ACTIONS_MOVE_BAGS",
  AddBags: "DELIVERY_ACTIONS_ADD_BAGS",
  ReturnBags: "DELIVERY_ACTIONS_RETURN_BAGS",
  ChangeLocation: "DELIVERY_ACTIONS_CHANGE_LOCATION", // Change address of a delivery day
};

export const DeliverySchedule = {
  View: "DELIVERY_SCHEDULE_VIEW",
  Upsert: "DELIVERY_SCHEDULE_UPSERT",
  Delete: "DELIVERY_SCHEDULE_DELETE",
}

export const OrderMetadata = {
  Search: "ORDER_METADATA_SEARCH",
  View: "ORDER_METADATA_VIEW",
  Create: "ORDER_METADATA_CREATE",
  Update: "ORDER_METADATA_UPDATE",
  ViewInfo: "ORDER_METADATA_VIEW_INFO",
  UpdateVisibility: "ORDER_METADATA_UPDATE_VISIBILITY",
  UpdateAvailability: "ORDER_METADATA_UPDATE_AVAILABILITY",
  Propagate: "ORDER_METADATA_PROPAGATE",
};

export const PricingMatrix = {
  Search: "PRICING_MATRIX_SEARCH",
  View: "PRICING_MATRIX_VIEW",
  Update: "PRICING_MATRIX_UPDATE"
};

export const BackgroundJobExecution = {
  Search: "BACKGROUND_JOB_EXECUTION_SEARCH",
  View: "BACKGROUND_JOB_EXECUTION_VIEW"
};

export const F45PricingMatrix = {
  Search: "F45_PRICING_MATRIX_SEARCH",
  View: "F45_PRICING_MATRIX_VIEW",
  Update: "F45_PRICING_MATRIX_UPDATE"
};

export const Ambassador = {
  Search: "AMBASSADOR_SEARCH",
  View: "AMBASSADOR_VIEW",
  Update: "AMBASSADOR_UPDATE",
  Create: "AMBASSADOR_CREATE"
};

export const ClientOffer = {
  Search: "CLIENT_OFFER_SEARCH",
  View: "CLIENT_OFFER_VIEW",
  Update: "CLIENT_OFFER_UPDATE",
  Create: "CLIENT_OFFER_CREATE",
  Delete: "CLIENT_OFFER_DELETE"
};

const PermissionConstants = {
  User,
  UserActions,
  RoleType,
  UserRoles,
  Affiliate,
  Customer,
  SalesPerson,
  ClientDashboard,
  Permission,
  Order,
  PostCode,
  Holiday,
  Driver,
  AffiliateRequest,
  GuestOrder,
  DiscountCode,
  SystemAudit,
  CreditCashOutRequest,
  DailyDeliveries,
  MealManagement,
  FinancialAnalytics,
  DeliveryInfo,
  KeyLogs,
  ContentManagement,
  EmailSettings,
  CancelledOrders,
  Invoices,
  LoyaltyToken,
  FrozenFoodInventory,
  FrozenFoodDelivery,
  TrialOrders,
  SupplementInventory,
  SupplementOrders,
  Supplement,
  ToGoMealManagement,
  ToGoOrders,
  ToGoDailyDelivery,
  AffiliateSales,
  SalesTarget,
  FitnessCenterWorkoutCategory,
  FitnessCenterWorkoutTrainer,
  FitnessCenterWorkoutVideo,
  VideoLibrary,
  ToGoEvents,
  Payment,
  FrozenRange,
  F45,
  Tenants,
  WeeklyPayment,
  DeliveryActions,
  DeliverySchedule,
  Lookups,
  OrderMetadata,
  PricingMatrix,
  PartialCustomer,
  BackgroundJobExecution,
  F45PricingMatrix,
  Ambassador,
  ClientOffer,
  MealBalancing
}

export default PermissionConstants;
