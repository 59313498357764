import { Dispatch } from "react";
import { configurationSettingsService } from '../../services/config-settings.service';
import { orderService } from "../../services/order.service";
import { validationService } from "../../services/shared/validation.service";
import { OrderType } from "../../types/fff.enums";
import {
  PackageMetadataFilter,
  PaymentOption
} from "../../types/order-dto.types";
import {
  CalorieMatrixDto,
  GuestOrderListDto,
  MealExtrasUnitPriceDto,
  MetadataDictionary,
  MetadataWithMappings,
  Order, OrderFormStep, OrderListRequest,
  OrderStaging,
  PackageMetadata,
  PackagePriceDictionary,
  PackagePricingMatrixDto,
  WeeklyOrderListItemDto,
  WeeklyOrdersListRequest
} from "../../types/order-store.types";
import * as constants from "./order.constants";

export const orderAction = {
  loadAllMetaData,
  loadPackageMetadata,
  filterMetaData,
  filterPackageMetaData,
  loadPackagePricing: loadPackagePrices,
  loadGuestOrders,
  loadCalorieMatrixData,
  toggleOrderForm,
  updateOrderStaging,
  updateAndStageOrder,
  resetOrder,
  setPaymentErrorCode,
  setOrderValidity,
  updateOrderFormSteps,
  toggleOrderEditWizard,
  loadOrderEditWizard,
  loadAllMetadataForOrderEdit,
  loadOrderToEdit,
  filterMetadataForOrderEdit,
  orderEditUpdateOrder,
  loadWeeklyOrders,
  setAdminOrderRequestStatus,
  loadPackagePricingMatrix,
  loadMealExtrasUnitPrice,
  loadWeeklyPaymentSurplus,
};

export interface LoadAllMetadata {
  type: constants.LOAD_ALL_META_DATA;
  data: MetadataWithMappings;
}

export interface LoadPackageMetadata {
  type: constants.LOAD_PACKAGE_META_DATA;
  data: PackageMetadata[];
}

export interface SetPaymentErrorCode {
  type: constants.SET_PAYMENT_ERROR_CODE;
  errorCode?: string;
}

export interface SetOrderValidity {
  type: constants.SET_ORDER_VALIDITY;
  isValid: boolean;
}

export interface UpdateOrderFormSteps {
  type: constants.UPDATE_ORDER_FORM_STEPS;
  steps: OrderFormStep[];
}

export interface SetAdminOrderRequestStatus {
  type: constants.SET_ADMIN_ORDER_REQUEST_STATUS;
  isAdminRequest: boolean;
}

export interface SetFilteredMetadata {
  type: constants.SET_FILTERED_META_DATA;
  data: MetadataDictionary;
}

export interface LoadGuestOrders {
  type: constants.LOAD_GUEST_ORDERS;
  data: GuestOrderListDto[];
}

export interface LoadOrderStaging {
  type: constants.LOAD_ORDER_STAGING;
  orderStaging: OrderStaging;
}

export interface UpdateOrderStaging {
  type: constants.UPDATE_ORDER_STAGING;
  updatedOrder: OrderStaging;
  isValid: boolean;
}

export interface UpdateAndStageOrder {
  type: constants.UPDATE_AND_STAGE_ORDER;
  updatedOrder: OrderStaging;
}

export interface FilterMetadata {
  type: constants.FILTER_META_DATA;
  filterByHeader: string;
  filterById: string;
}

export interface FilterPackageMetadata {
  type: constants.FILTER_PACKAGE_META_DATA;
  packageMetadataFilter: PackageMetadataFilter;
}

export interface LoadCalorieMatrixData {
  type: constants.LOAD_CALORIE_MATRIX_DATA;
  data: CalorieMatrixDto[];
}

export interface LoadPackagePrices {
  type: constants.LOAD_PACKAGE_PRICES;
  packagePrices: PackagePriceDictionary;
}

export interface ToggleOrderForm {
  type: constants.TOGGLE_ORDER_FORM;
}

export interface UpdateStepNumber {
  type: constants.UPDATE_STEP_NUMBER;
  stepNumber: number;
}

export interface ResetOrderStaging {
  type: constants.RESET_ORDER_STAGING;
}

export interface ToggleOrderEditWizard {
  type: constants.TOGGLE_ORDER_EDIT_WIZARD;
  orderId: string;
  successCallback?: () => void;
}

export interface LoadMetadataForOrderEdit {
  type: constants.LOAD_ALL_META_DATA_FOR_ORDER_EDIT;
  data: MetadataWithMappings;
}

export interface FilterMetadataForOrderEdit {
  type: constants.FILTER_META_DATA_FOR_ORDER_EDIT;
  header?: string;
  headerId?: string;
}

export interface LoadOrderToEdit {
  type: constants.LOAD_ORDER_TO_EDIT;
  order: Order;
}

export interface LoadCalorieMatrixDataForOrderEdit {
  type: constants.LOAD_CALORIE_MATRIX_DATA_FOR_ORDER_EDIT;
  data: CalorieMatrixDto[];
}

export interface OrderEditUpdateOrder {
  type: constants.ORDER_EDIT_UPDATE_ORDER;
  data: any;
}

export interface LoadWeeklyOrders {
  type: constants.LOAD_WEEKLY_ORDERS;
  data: WeeklyOrderListItemDto[];
}

export interface ClearOrderFormOnLogout {
  type: constants.CLEAR_ORDER_FORM_ON_LOGOUT;
}

export interface LoadPackagePricingMatrix {
  type: constants.LOAD_PRICING_MATRIX_DATA;
  data: PackagePricingMatrixDto[]
}

export interface LoadMealExtrasUnitPrice {
  type: constants.LOAD_MEAL_EXTRAS_UNIT_PRICE_DATA;
  data: MealExtrasUnitPriceDto[]
}

export interface LoadWeeklyPaymentSurplus {
  type: constants.LOAD_WEEKLY_PAYMENT_SURPLUS_AMOUNT;
  data: number
}

export type OrderActions =
  | LoadAllMetadata
  | SetFilteredMetadata
  | FilterMetadata
  | LoadPackagePrices
  | LoadGuestOrders
  | LoadCalorieMatrixData
  | ToggleOrderForm
  | UpdateStepNumber
  | LoadOrderStaging
  | UpdateOrderStaging
  | ResetOrderStaging
  | SetPaymentErrorCode
  | SetOrderValidity
  | UpdateOrderFormSteps
  | UpdateAndStageOrder
  | LoadPackageMetadata
  | FilterPackageMetadata
  | LoadWeeklyOrders
  | ClearOrderFormOnLogout
  | SetAdminOrderRequestStatus
  | LoadPackagePricingMatrix
  | LoadMealExtrasUnitPrice
  | LoadWeeklyPaymentSurplus;

export type OrderEditWizardActions =
  | ToggleOrderEditWizard
  | LoadMetadataForOrderEdit
  | OrderEditUpdateOrder
  | LoadOrderToEdit
  | FilterMetadataForOrderEdit
  | LoadCalorieMatrixDataForOrderEdit;

function loadAllMetaData(excludeNonRenewables?: boolean) {

  return (dispatch: Dispatch<OrderActions>) => {
    orderService
      .getAllMetadata(excludeNonRenewables)
      .then((data: MetadataWithMappings) => {
        let defaultMetadata = orderService.getFilteredMetadataForOrderForm(
          data.metadata,
          data.mappings
        );
        dispatch({ type: constants.LOAD_ALL_META_DATA, data });
        dispatch({
          type: constants.SET_FILTERED_META_DATA,
          data: defaultMetadata,
        });
      });
  };
}

function loadPackageMetadata() {
  return (dispatch: Dispatch<OrderActions>) => {
    orderService.getPackageMetadata().then((items: PackageMetadata[]) => {
      dispatch({
        type: constants.LOAD_PACKAGE_META_DATA,
        data: items,
      });
    });
  };
}

function setPaymentErrorCode(errorCode?: string) {
  return (dispatch: Dispatch<OrderActions>) => {
    dispatch({ type: constants.SET_PAYMENT_ERROR_CODE, errorCode });
  };
}

function updateOrderFormSteps(steps: OrderFormStep[]) {
  return (dispatch: Dispatch<OrderActions>) => {
    dispatch({ type: constants.UPDATE_ORDER_FORM_STEPS, steps });
  };
}

function setOrderValidity(isValid: boolean) {
  return (dispatch: Dispatch<OrderActions>) => {
    dispatch({ type: constants.SET_ORDER_VALIDITY, isValid });
  };
}

function setAdminOrderRequestStatus(isAdminRequest: boolean) {
  return (dispatch: Dispatch<OrderActions>) => {
    dispatch({ type: constants.SET_ADMIN_ORDER_REQUEST_STATUS, isAdminRequest });
  };
}

function loadPackagePrices(
  mealsPerDay: number,
  mealExtrasIds: string[],
  packageDays: string[],
  paymentOption: PaymentOption,
  orderType: OrderType
) {
  return (dispatch: Dispatch<OrderActions>) => {
    orderService
      .loadPackagePrices(
        mealsPerDay,
        mealExtrasIds,
        packageDays,
        paymentOption,
        orderType
      )
      .then((packagePrices: PackagePriceDictionary) => {
        dispatch({ type: constants.LOAD_PACKAGE_PRICES, packagePrices });
      });
  };
}

function filterMetaData(filterByHeader: string, filterById: string) {
  return { type: constants.FILTER_META_DATA, filterByHeader, filterById };
}

function filterPackageMetaData(packageMetadataFilter: PackageMetadataFilter) {
  return { type: constants.FILTER_PACKAGE_META_DATA, packageMetadataFilter };
}

function loadGuestOrders(request: OrderListRequest) {
  return (dispatch: Dispatch<OrderActions>) => {
    orderService.getGuestOrders(request).then((data: GuestOrderListDto[]) => {
      dispatch({ type: constants.LOAD_GUEST_ORDERS, data: data });
    });
  };
}

function updateOrderStaging(order: OrderStaging, schema?: any) {
  return async (dispatch: Dispatch<OrderActions>) => {
    let isValid = true;
    if (schema) {
      isValid = await validationService.validateOrder(schema, order);
    }

    dispatch({
      type: constants.UPDATE_ORDER_STAGING,
      updatedOrder: order,
      isValid,
    });
  };
}

function updateAndStageOrder(order: OrderStaging, callback?: () => void) {
  return (dispatch: Dispatch<OrderActions>) => {
    dispatch({ type: constants.UPDATE_AND_STAGE_ORDER, updatedOrder: order });
    callback && callback();
  };
}

function resetOrder() {
  return (dispatch: Dispatch<OrderActions>) => {
    dispatch({ type: constants.RESET_ORDER_STAGING });
  };
}

function loadCalorieMatrixData() {
  return (dispatch: Dispatch<OrderActions>) => {
    orderService.getCalorieMatrixData().then((data: CalorieMatrixDto[]) => {
      dispatch({ type: constants.LOAD_CALORIE_MATRIX_DATA, data: data });
    });
  };
}

function loadOrderEditWizard(orderId: string, successCallback: () => void) {
  return (dispatch: Dispatch<any>) => {
    dispatch(toggleOrderEditWizard(orderId, successCallback));
  };
}

function loadAllMetadataForOrderEdit(includeAdminOnly: boolean) {
  return (dispatch: Dispatch<any>) => {
    orderService.getAllMetadata(false, includeAdminOnly).then((data: MetadataWithMappings) => {
      dispatch({ type: constants.LOAD_ALL_META_DATA_FOR_ORDER_EDIT, data });
      dispatch(filterMetadataForOrderEdit());
      dispatch(loadCalorieMatrixDataForOrderEdit());
    });
  };
}

function filterMetadataForOrderEdit(
  header?: string,
  headerId?: string
): FilterMetadataForOrderEdit {
  return { type: constants.FILTER_META_DATA_FOR_ORDER_EDIT, header, headerId };
}

function loadOrderToEdit(orderId: string) {
  return (dispatch: Dispatch<any>) => {
    orderService.getOrder(orderId).then((order: Order) => {
      dispatch({ type: constants.LOAD_ORDER_TO_EDIT, order });
    });
  };
}

function toggleOrderForm() {
  return { type: constants.TOGGLE_ORDER_FORM };
}

function toggleOrderEditWizard(orderId?: string, successCallback?: () => void) {
  return { type: constants.TOGGLE_ORDER_EDIT_WIZARD, orderId, successCallback };
}

function loadCalorieMatrixDataForOrderEdit() {
  return (dispatch: Dispatch<OrderEditWizardActions>) => {
    orderService.getCalorieMatrixData().then((data: CalorieMatrixDto[]) => {
      dispatch({
        type: constants.LOAD_CALORIE_MATRIX_DATA_FOR_ORDER_EDIT,
        data: data,
      });
    });
  };
}

function orderEditUpdateOrder(data: any) {
  return { type: constants.ORDER_EDIT_UPDATE_ORDER, data };
}

function loadWeeklyOrders(request: WeeklyOrdersListRequest) {
  return (dispatch: Dispatch<OrderActions>) => {
    orderService
      .getWeeklyOrders(request)
      .then((data: WeeklyOrderListItemDto[]) => {
        dispatch({ type: constants.LOAD_WEEKLY_ORDERS, data: data });
      });
  };
}

function loadPackagePricingMatrix() {
  return (dispatch: Dispatch<OrderActions>) => {
    orderService.getPackagePricingData()
      .then((data: PackagePricingMatrixDto[]) => {
        dispatch({
          type: constants.LOAD_PRICING_MATRIX_DATA,
          data: data
        })
      })
  }
}

function loadMealExtrasUnitPrice() {
  return (dispatch: Dispatch<OrderActions>) => {
    orderService.getExtrasUnitPricingData()
      .then((data: MealExtrasUnitPriceDto[]) => {
        dispatch({
          type: constants.LOAD_MEAL_EXTRAS_UNIT_PRICE_DATA,
          data: data
        })
      })
  }
}

function loadWeeklyPaymentSurplus() {
  return (dispatch: Dispatch<OrderActions>) => {
    configurationSettingsService.getWeeklySurplus()
      .then(data => {
        dispatch({
          type: constants.LOAD_WEEKLY_PAYMENT_SURPLUS_AMOUNT,
          data: Number(data)
        })
      });
  }
}