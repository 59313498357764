export const TOGO_LOAD_ALL_MEALS = "TOGO_LOAD_ALL_MEALS";
export type TOGO_LOAD_ALL_MEALS = typeof TOGO_LOAD_ALL_MEALS;

export const TOGO_LOAD_ALL_EXTRAS = "TOGO_LOAD_ALL_EXTRAS";
export type TOGO_LOAD_ALL_EXTRAS = typeof TOGO_LOAD_ALL_EXTRAS;

export const LOAD_TOGO_PRICING_MATRIX = "LOAD_TOGO_PRICING_MATRIX";
export type LOAD_TOGO_PRICING_MATRIX = typeof LOAD_TOGO_PRICING_MATRIX;

export const TOGO_SELECT_MENU_ITEM = "TOGO_SELECT_MENU_ITEM";
export type TOGO_SELECT_MENU_ITEM = typeof TOGO_SELECT_MENU_ITEM;

export const TOGO_SET_EDITING_TEMPLATE = "TOGO_SET_EDITING_TEMPLATE";
export type TOGO_SET_EDITING_TEMPLATE = typeof TOGO_SET_EDITING_TEMPLATE;

export const TOGO_SET_SELECTED_ORDER = "TOGO_SET_SELECTED_ORDER";
export type TOGO_SET_SELECTED_ORDER = typeof TOGO_SET_SELECTED_ORDER;

export const TOGO_SET_CURRENT_TEMPLATES = "TOGO_SET_CURRENT_TEMPLATES";
export type TOGO_SET_CURRENT_TEMPLATES = typeof TOGO_SET_CURRENT_TEMPLATES;

export const TOGO_SET_CURRENT_ORDERS = "TOGO_SET_CURRENT_ORDERS";
export type TOGO_SET_CURRENT_ORDERS = typeof TOGO_SET_CURRENT_ORDERS;

export const TOGO_UPDATE_CURRENT_TEMPLATE = "TOGO_UPDATE_CURRENT_TEMPLATE";
export type TOGO_UPDATE_CURRENT_TEMPLATE = typeof TOGO_UPDATE_CURRENT_TEMPLATE;

export const TOGO_ORDER_CLEAR_STATE = "TOGO_ORDER_CLEAR_STATE";
export type TOGO_ORDER_CLEAR_STATE = typeof TOGO_ORDER_CLEAR_STATE;

export const TOGO_CLEAR_STATE = "TOGO_CLEAR_STATE";
export type TOGO_CLEAR_STATE = typeof TOGO_CLEAR_STATE;

export const TOGO_LOAD_HOLIDAYS = "TOGO_LOAD_HOLIDAYS";
export type TOGO_LOAD_HOLIDAYS = typeof TOGO_LOAD_HOLIDAYS;

export const TOGO_SKIPPED_WEEKS = "TOGO_SKIPPED_WEEKS";
export type TOGO_SKIPPED_WEEKS = typeof TOGO_SKIPPED_WEEKS;

export const TOGO_EDIT_TEMPLATE = "TOGO_EDIT_TEMPLATE";
export type TOGO_EDIT_TEMPLATE = typeof TOGO_EDIT_TEMPLATE;

export const TOGO_LOAD_USER_PROFILE = "TOGO_LOAD_USER_PROFILE";
export type TOGO_LOAD_USER_PROFILE = typeof TOGO_LOAD_USER_PROFILE;

export const TOGO_EDIT_USER_INFO = "TOGO_EDIT_USER_INFO";
export type TOGO_EDIT_USER_INFO = typeof TOGO_EDIT_USER_INFO;

export const TOGO_SET_STARTING_DATE = "TOGO_SET_STARTING_DATE";
export type TOGO_SET_STARTING_DATE = typeof TOGO_SET_STARTING_DATE;

export const TOGO_CLEAR_ALL_ITEMS = "TOGO_CLEAR_ALL_ITEMS";
export type TOGO_CLEAR_ALL_ITEMS = typeof TOGO_CLEAR_ALL_ITEMS;

