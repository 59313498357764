
import { BusinessUserProfileDto } from "./business-user.types";
import { DeliveryStatus, MealClassification, RecipeType } from "./fff.enums";
import { RecipeDto } from "./meal-management.types";
import { AddressDto, DeliveryInfoDto, OrderStatus, PaymentContextDto } from "./order-dto.types";
import { PaymentResultContextDto } from './payment-dto.types';
import { SearchFilterBase } from "./shared-dto.types";
import { CardDetailsDto } from "./user-card.types";

export interface ToGoState {
  allMeals?: ToGoMeal[];
  allExtras?: ToGoExtra[];
  pricingMatrix?: ToGoPricingMatrix;
  currentTemplates?: ToGoOrderTemplate[];
  currentOrders?: ToGoOrderDto[];
  templateEditing?: ToGoOrderTemplate;
  selectedOrder?: ToGoOrderDto;
  holidays?: Date[];
  skippedWeeks?: Date[];
  userProfile?: BusinessUserProfileDto;
  startingDate?: Date;
}

export interface ToGoMenuItem {
  id: string;
  code: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  proteinInGrams: number;
  carbsInGrams: number;
  fatInGrams: number;
  kCals: number;
  inactive: boolean;
  adminOnly?: boolean;
  sortOrder: number;
  ingredients?: string;
  allergens?: string;
}

export interface ToGoMeal extends ToGoMenuItem {
  recipes: ToGoMealRecipe[];
}

export interface ToGoExtra extends ToGoMenuItem {
  type?: RecipeType;
  classification?: MealClassification;
  recipeId: string;
  recipe?: RecipeDto;
}

export interface ToGoPricingMatrix {
  mealPrices: ToGoMealPricing[];
  extraPrices: ToGoExtrasPricing[];
}

export interface ToGoMealPricing {
  id: string;
  thresholdQuantity: number;
  unitPrice: number;
}

export interface ToGoExtrasPricing {
  id: string;
  type: RecipeType;
  unitPrice: number;
}

export interface ToGoOrderListDto {
  id: string;
  orderNo: string;
  formattedOrderNo: string;
  startDate: Date;
  createdDate: Date;
  totalPrice: number;
  status: OrderStatus;
  email: string;
  customerName: string;
  businessName: string;
  isEvent: boolean;
  userId: string;
  isFreeOrder: boolean;
  isCustomDelivery: boolean;
  eventName: string;
}

export interface ToGoOrderDetail {
  deliveryItems: ToGoDailyDeliveryItemDto[];
  deliveryInfo: DeliveryInfoDto;
  orderInfo: ToGoOrderListDto;
}

export interface DailyDeliveryCount {
  [date: string]: number;
}

export interface ToGoDailyMenuItem {
  name: string;
  description: string;
  thumbnailUrl: string;
}

export interface ToGoDailyDeliveryItemDto {
  dailyCounts: DailyDeliveryCount;
  item: ToGoDailyMenuItem;
  itemType: ToGoItemType;
  itemId: string;
}

export interface ToGoOrderList {
  [key: string]: ToGoOrderTemplate;
}

export interface ToGoOrderTemplate {
  id?: string;
  startDate: Date;
  isCancelled: boolean;
  meals: ToGoOrderTemplateItems;
  extras: ToGoOrderTemplateItems;
  isEvent: boolean;
  activeOrderId?: string;
  eventId?: string;
  discountCodeId?: any;
}

export interface ToGoOrderTemplateItems {
  [itemId: string]: ToGoDailyItemCounts;
}

export interface ToGoDailyItemCounts {
  [day: number]: number;
}

export interface DailyTotal {
  day: number;
  mealCount: number;
  extraCount: number;
  price: number;
}

export enum ToGoItemType {
  Meal = 0,
  Extra = 1,
}

export interface ToGoFailedOrderDto {
  id: string;
  templateId: string;
  orderStartDate: Date;
  error: string;
  businessName: string;
  customerName: string;
  telephoneNo: string;
  email: string;
}

export interface ToGoOrderTemplateDto {
  id?: string;
  startDate: string;
  isEvent: boolean;
  isCancelled: boolean;
  activeOrdersStartDates?: string[];
  userId: string;
  meals: ToGoOrderTemplateItemDto[];
  extras: ToGoOrderTemplateItemDto[];
  specialRequirements?: string;
  eventId?: string;
  discountCodeId?: string;
}

export interface ToGoOrderTemplateItemDto {
  id?: string;
  itemId: string;
  countMon: number;
  countTue: number;
  countWed: number;
  countThu: number;
  countFri: number;
}

export interface ToGoMealRecipe {
  recipeId: string;
  recipe?: RecipeDto;
  defaultQuantity?: number;
}

export interface ToGoMealListSearchFilter extends SearchFilterBase {
  includeInactive: boolean;
}

export interface ToGoOrderSearchFilter extends SearchFilterBase {
  createdDate?: string;
}

export interface ToGoFailedOrdersSearchFilter extends SearchFilterBase {
  from?: string;
  to?: string;
}

export interface ToGoOrderResult {
  succeeded: boolean;
  paymentError: string;
}

export interface ToGoMealListItemDto {
  id: string;
  name: string;
  code: string;
  inactive: boolean;
  adminOnly: boolean;
  recipes: string;
}

export interface ToGoExtraListSearchFilter extends SearchFilterBase {
  includeInactive: boolean;
}

export interface HomeSection {
  header: string;
  subHeader: string;
  items: any[];
  itemType: ToGoItemType;
}

export interface ToGoOrderSaveResult extends PaymentResultContextDto {
  error: string;
  id: string;
  rowVersion: string;
  paymentRequired: boolean;
  payment: number;
  creditToBeConsumed: number;
  creditAdded: number;
  paymentCard: CardDetailsDto;
}

export interface ToGoDeliveryListSearchFilter extends SearchFilterBase {
  deliveryDate: string;
}

export interface ToGoDeliverySummaryDto {
  revenue: number;
  totalOrders: number;
  totalMeals: number;
  totalExtras: number;
}

export interface ToGoDailyDeliveryListItemDto {
  id: string;
  formattedOrderNo: string;
  customerEmail: string;
  businessName: string;
  postCode: string;
  event: boolean;
  totalAmount: number;
  status: DeliveryStatus;
}

export interface ToGoDailyDeliveryDetails {
  id: string;
  formattedOrderNo: string;
  totalAmount: number;
  mealItems: ToGoDailyDeliveryItemDto[];
  extraItems: ToGoDailyDeliveryItemDto[];
  address: AddressDto;
  deliveryInstructions: string;
  savings: number;
  mealsPrice: number;
  extrasPrice: number;
  mealsSavings: number;
  extrasSavings: number;
  deliveryCharge: number;
  discount: number;
}

export interface ToGoDailyDeliveryItemDto {
  name: string;
  quantity: number;
  originalUnitPrice: number;
  subtotal: number;
}

export interface ToGoOrderHistorySearchFilter extends SearchFilterBase { }

export interface ToGoOrderHistoryDto {
  orderId: string;
  orderNo: number;
  formattedOrderNo: string;
  mealsCount: number;
  extrasCount: number;
  orderTotal: number;
  startDate: Date;
}

export interface ToGoOrderSummaryDto {
  orderId: string;
  orderNo: number;
  mealsCount: number;
  extrasCount: number;
  createdDate: Date;
  startDate: Date;
  orderTotal: number;
  isFreeOrder: boolean;
  isEvent: boolean;
}

export interface ToGoOrderDto {
  orderId: string;
  templateId: string;
  startDate: Date;
  isEvent: boolean;
  userId: string;
  subTotal: number;
  deliveryCharge: number;
  totalPrice: number;
  deliveryInfo: DeliveryInfoDto;
  deliveries: ToGoDeliveryDto[];
  discountCodeId?: string;
  eventId?: string;
}

export interface ToGoDeliveryDto {
  date: Date;
  status: number;
  subTotal: number;
  mealsPrice: number;
  extrasPrice: number;
  deliveryCharge: number;
  savings: number;
  items: ToGoDeliveryItemDto[];
}

export interface ToGoDeliveryItemDto {
  mealId: string;
  extraId: string;
  count: number;
  unitPrice: number;
  unitPriceBeforeDiscount: number;
}

export enum ToGoEventStatus {
  Pending = 1,
  Linked = 2,
  Approved = 3,
  Expired = 4,
  Rejected = 5
}

export interface ToGoEventDto {
  id: string;
  name: string;
  eventDate: Date;
  isFree: boolean;
  status: ToGoEventStatus;
  eventRequirements: string;
  rejectedReason: string;
  orderId?: string;
}

export interface ToGoEventSearchFilter extends SearchFilterBase {
  fromDate?: Date;
  toDate?: Date;
  status?: ToGoEventStatus;
}

export interface ToGoEventCalendarItemDto {
  id: string;
  name: string;
  status: ToGoEventStatus;
}

export interface ToGoEventCalendarDayDto {
  date: Date;
  eventList: ToGoEventCalendarItemDto[];
}

export interface ToGoOrderTemplateRequestDto {
  orderTemplate?: ToGoOrderTemplateDto;
  paymentContext?: PaymentContextDto;
}

export interface ToGoDeliveryInfoChangeResponseDto extends PaymentResultContextDto {
  succeeded: boolean;
  error: string;
  creditAdded?: number;
  paymentRequired: boolean;
  creditToBeConsumed?: number;
  payment?: number;
  paymentCard?: CardDetailsDto;
}

export interface ToGoDiscountCodeInfo {
  id?: string,
  waiverType?: number,
  discountPercentage?: number,
  discountAmount?: number,
  valid?: boolean,
  code?: string
}