import React from 'react';
import { useSelector } from 'react-redux';
import { AppState, AuthUser } from '../../../types/shared-store.types';
import { authUtils } from '../../../utils/auth.utils';

export interface FFFAuthProps {
  auth?: AuthUser;
  isLoggedIn: boolean;
  authUtils: AuthUtilFunctions;
  authUserId?: string;
}

interface AuthUtilFunctions {
  hasPermission: (permission: string) => boolean;
  hasAnyPermission: (permissions: string[]) => boolean;
  isInRole: (role: string) => boolean;
  isBackendUser: () => boolean;
  isAffiliate: () => boolean;
  isCustomer: () => boolean;
  isBusinessUser: () => boolean;
  getPermissions: () => string[];
  isPartialCustomer: () => boolean;
  isImpersonating: () => boolean;
  hasAnySubscribedTenant: () => boolean;
}

export function withAuth<T extends FFFAuthProps = FFFAuthProps>(
  Component: React.ComponentType<T>
) {
  const ComponentWithAuth = (props: Omit<T, keyof FFFAuthProps>) => {
    const auth = useSelector((state: AppState) => state.core.auth);

    const authProps: FFFAuthProps = {
      auth,
      authUserId: auth && auth.user.id,
      isLoggedIn: auth != null,
      authUtils: {
        isInRole: (role: string) => authUtils.isInRole(auth, role),
        hasPermission: (permission: string) => authUtils.hasPermission(auth, permission),
        hasAnyPermission: (permissions: string[]) => authUtils.hasAnyPermission(auth, permissions),
        isBackendUser: () => authUtils.isBackendUser(auth),
        isAffiliate: () => authUtils.isAffiliate(auth),
        isCustomer: () => authUtils.isCustomer(auth),
        isBusinessUser: () => authUtils.isBusinessUser(auth),
        getPermissions: () => authUtils.getPermissions(auth),
        isPartialCustomer: () => authUtils.isPartialCustomer(auth),
        isImpersonating: () => authUtils.isImpersonating(auth),
        hasAnySubscribedTenant: () => authUtils.hasAnySubscribedTenant(auth)
      }
    }

    return <Component {...authProps} {...(props as T)} />;
  };

  return ComponentWithAuth;
}
