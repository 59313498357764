import * as constants from './user.constants'

import { UserListActions } from './user.actions';
import { UserReducerState } from '../../types/user-store.types';

const userReducer = (state: UserReducerState = {}, action: UserListActions) => {
  switch (action.type) {
    case constants.LOAD_USER_LIST: {
      return {
        ...state, list: {
          items: action.items,
          total: action.total
        }
      }
    }
    case constants.LOAD_MEALS_METADATA: {
      return {
        ...state, profile: {
          ...state.profile, nutrition: {
            "mealsMetadata": action.items
          }
        }
      }
    }
    default: {
      return state;
    }
  }
}

export const UserReducer = {
  user: userReducer
};