export const LOAD_SUPPLEMENT_INVENTORY = 'LOAD_SUPPLEMENT_INVENTORY';
export type LOAD_SUPPLEMENT_INVENTORY = typeof LOAD_SUPPLEMENT_INVENTORY;

export const UPDATE_SUPPLEMENT_INVENTORY_SELECTION = 'UPDATE_SUPPLEMENT_INVENTORY_SELECTION';
export type UPDATE_SUPPLEMENT_INVENTORY_SELECTION = typeof UPDATE_SUPPLEMENT_INVENTORY_SELECTION;

export const LOAD_UNDELIVERABLE_SUPPLEMENT_ORDERS = 'LOAD_UNDELIVERABLE_SUPPLEMENT_ORDERS';
export type LOAD_UNDELIVERABLE_SUPPLEMENT_ORDERS = typeof LOAD_UNDELIVERABLE_SUPPLEMENT_ORDERS;

export const CLEAR_SUPPLEMENT_INVENTORY_SELECTION = 'CLEAR_SUPPLEMENT_INVENTORY_SELECTION';
export type CLEAR_SUPPLEMENT_INVENTORY_SELECTION = typeof CLEAR_SUPPLEMENT_INVENTORY_SELECTION;

export const REPLACE_SUPPLEMENT_INVENTORY_SELECTION = 'REPLACE_SUPPLEMENT_INVENTORY_SELECTION';
export type REPLACE_SUPPLEMENT_INVENTORY_SELECTION = typeof REPLACE_SUPPLEMENT_INVENTORY_SELECTION;

export const LOAD_SUPPLEMENT_DELIVERY_DAYS = 'LOAD_SUPPLEMENT_DELIVERY_DAYS';
export type LOAD_SUPPLEMENT_DELIVERY_DAYS = typeof LOAD_SUPPLEMENT_DELIVERY_DAYS;

export const LOAD_SUPPLEMENT_ORDER_HISTORY = 'LOAD_SUPPLEMENT_ORDER_HISTORY';
export type LOAD_SUPPLEMENT_ORDER_HISTORY = typeof LOAD_SUPPLEMENT_ORDER_HISTORY;
