import * as constants from './admin-dashboard.constants';

import { AdminDashboardState } from '../../types/dashboard.types';
import { DashboardActions } from "./admin-dashboard.actions";

export const adminDashboardReducer = {
  adminDashboard
}

function adminDashboard(state: AdminDashboardState = {}, action: DashboardActions) {

  switch (action.type) {
    case constants.LOAD_ADMIN_DASHBOARD_PACKAGE_OPTIONS: {
      return { ...state, packageOptions: action.packageOptions }
    }
    case constants.LOAD_ADMIN_DASHBOARD_PROMOTIONAL_PACKAGE_OPTIONS: {
      return { ...state, promotionalPackageOptions: action.packageOptions }
    }
  }

  return state;
}
