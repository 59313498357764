import * as constants from "./core.constants";
import { CoreState } from "../../types/shared-store.types";
import { CoreActions } from "./core.actions";

const coreReducer = (
  state: CoreState = {
    allTenants: [],
    appInitialized: false,
    f45Enabled: false,
    featureRegistryInitialized: false,
    localeInitialized: false,
    authInitialized: false,
    tenantsLoaded: false
  },
  action: CoreActions
) => {
  switch (action.type) {
    case constants.CORE_SET_APP_INITIALIZED: {
      return { ...state, appInitialized: action.initialized };
    }
    case constants.CORE_SET_AUTH_INITIALIZED: {
      return { ...state, authInitialized: action.initialized };
    }
    case constants.CORE_SET_LOCALE_INITIALIZED: {
      return { ...state, localeInitialized: action.initialized };
    }
    case constants.CORE_SET_F45_INITIALIZED: {
      return { ...state, f45Enabled: action.initialized };
    }
    case constants.CORE_SET_TENANTS_LOADED: {
      return { ...state, tenantsLoaded: action.loaded };
    }
    case constants.CORE_SET_FEATURE_REG_INITIALIZED: {
      return { ...state, featureRegistryInitialized: action.initialized };
    }
    case constants.CORE_SET_CURRENT_TENANT: {
      return { ...state, currentTenant: action.tenant };
    }
    case constants.CORE_SET_ALL_TENANTS: {
      return { ...state, allTenants: action.tenants };
    }
    case constants.CORE_SET_AUTH: {
      return {
        ...state,
        auth: action.auth,
        currentTenant: action.auth.defaultTenant,
      };
    }
    case constants.CORE_CLEAR_AUTH: {
      return {
        ...state,
        auth: undefined,
        currentTenant: undefined,
      };
    }
    case constants.CORE_UPDATE_AUTH_USER: {
      return {
        ...state,
        auth: {...state.auth, user: action.user},        
      };
    }
    case constants.CORE_SET_MAINTENANCE_MODE: {
      return {
        ...state,
        maintenanceMode: action.maintenanceMode
      }
    }
    default: {
      return state;
    }
  }
};

export default {
  core: coreReducer,
};
