import * as constants from '../../store/shared/shared.constants'

import { store } from "../../store";

export const messagingService = {
  setGrowlRef,
  notifySuccess,
  notifyInfo,
  notifyWarning,
  notifyError,
  showMessagePopup
}

export const globalConfirmationService = {
  showConfirmDialog,
  hideConfirmDialog,
  dialogConfirm,
  dialogCancel,
}

export const globalAlertService = {
  showAlertDialog,
  hideAlertDialog,
  dialogAlert
}

export const pageHeaderService = {
  setPageTitle,
  setCustomPageTitle
}

let growlRef: any;
function setGrowlRef(ref: any) {
  growlRef = ref;
}

function notifySuccess(text: string) {
  showGrowl('success', 'Success', text);
}

function notifyInfo(text: string) {
  showGrowl('info', 'Information', text);
}

function notifyWarning(text: string, duration?: number) {
  showGrowl('warn', 'Warnning', text, duration);
}

function notifyError(text: string) {
  showGrowl('error', 'Error', text);
}

function showGrowl(severity: string, summary: string, detail: string, life: number = 4000) {
  growlRef && growlRef.show({ severity, summary, detail, life });
}

function showMessagePopup(header: string, message: any, redirectUrl?: string) {
  store.dispatch({ type: constants.SHOW_MESSAGE_POPUP, header, message, redirectUrl });
}

let confirmCallback: () => void;
let cancelCallback: () => void;
let okCallback: () => void;

function showConfirmDialog(title: string, message: any, yesCallbackFn: () => void, noCallbackFn?: () => void, confirmButtonText?: string, denyButtonText?: string) {
  confirmCallback = yesCallbackFn;

  cancelCallback = noCallbackFn ? noCallbackFn : () => { }

  store.dispatch({ type: constants.SHOW_GLOBAL_CONFIRMATION, message, title, confirmButtonText, denyButtonText });
}

function hideConfirmDialog() {
  store.dispatch({ type: constants.HIDE_GLOBAL_CONFIRMATION });
}

function dialogConfirm() {
  hideConfirmDialog();
  confirmCallback && confirmCallback();
}

function dialogCancel() {
  hideConfirmDialog();
  cancelCallback && cancelCallback();
}

function showAlertDialog(title: string, message: string, okCallbackFn: () => void) {
  okCallback = okCallbackFn;
  store.dispatch({ type: constants.SHOW_GLOBAL_ALERT, message, title });
}

function hideAlertDialog() {
  store.dispatch({ type: constants.HIDE_GLOBAL_ALERT })
}

function dialogAlert() {
  hideAlertDialog();
  okCallback && okCallback();
}

function setPageTitle(title: string) {
  store.dispatch({ type: constants.SET_PAGE_TITLE, title });
}

function setCustomPageTitle(customTitle: any) {
  store.dispatch({ type: constants.SET_PAGE_TITLE, customTitle });
}