export const LOAD_FROZEN_FOOD_INVENTORY = 'LOAD_FROZEN_FOOD_INVENTORY'
export type LOAD_FROZEN_FOOD_INVENTORY = typeof LOAD_FROZEN_FOOD_INVENTORY

export const UPDATE_INVENTORY_SELECTION = 'UPDATE_INVENTORY_SELECTION'
export type UPDATE_INVENTORY_SELECTION = typeof UPDATE_INVENTORY_SELECTION

export const UPDATE_INVENTORY_SELECTION_ALL = 'UPDATE_INVENTORY_SELECTION_ALL'
export type UPDATE_INVENTORY_SELECTION_ALL = typeof UPDATE_INVENTORY_SELECTION_ALL

export const TOGGLE_FROZEN_FOOD_ORDER_FORM = 'TOGGLE_FROZEN_FOOD_ORDER_FORM'
export type TOGGLE_FROZEN_FOOD_ORDER_FORM = typeof TOGGLE_FROZEN_FOOD_ORDER_FORM;

export const UPDATE_FROZEN_FOOD_CUSTOMER_INFO = 'UPDATE_FROZEN_FOOD_CUSTOMER_INFO'
export type UPDATE_FROZEN_FOOD_CUSTOMER_INFO = typeof UPDATE_FROZEN_FOOD_CUSTOMER_INFO;

export const RESET_FROZEN_FOOD_ORDER_FORM = 'RESET_FROZEN_FOOD_ORDER_FORM'
export type RESET_FROZEN_FOOD_ORDER_FORM = typeof RESET_FROZEN_FOOD_ORDER_FORM;

export const UPDATE_FROZEN_FOOD_DELIVERY_CHARGE = 'UPDATE_FROZEN_FOOD_DELIVERY_CHARGE'
export type UPDATE_FROZEN_FOOD_DELIVERY_CHARGE = typeof UPDATE_FROZEN_FOOD_DELIVERY_CHARGE;

export const ADD_DISCOUNT = 'ADD_DISCOUNT'
export type ADD_DISCOUNT = typeof ADD_DISCOUNT;

export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT'
export type REMOVE_DISCOUNT = typeof REMOVE_DISCOUNT;

export const LOAD_FROZEN_ORDER_ITEM_DETAILS = 'LOAD_FROZEN_ORDER_ITEM_DETAILS'
export type LOAD_FROZEN_ORDER_ITEM_DETAILS = typeof LOAD_FROZEN_ORDER_ITEM_DETAILS;