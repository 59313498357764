import { GuestOrderReducer, OrderEditWizard, OrderReducer, WeeklyOrdersReducer } from "./order/order.reducer";

import { AffiliateReducer } from "./affiliate/affiliate.reducer";
import { DriverReducer } from "./driver/driver.reducer";
import { HolidayReducer } from "./holiday/holiday.reducer";
import { LookupReducer } from "./lookup/lookup.reducer";
import { PostcodeReducer } from "./postcode/postcode.reducer";
import { SalesPersonReducer } from './salesperson/salesperson.reducer';
import { SharedReducer } from "./shared/shared.reducer";
import { UserReducer } from './user/user.reducer';
import { adminDashboardReducer } from "./admin-dashboard/admin-dashboard.reducer";
import { clientDashboardReducer } from "./client-dashboard/client-dashboard.reducer";
import { combineReducers } from "redux";
import { customerManagerReducer } from "./customer/customer.reducer";
import { discountCodesReducer } from "./discount-code/discount-code-reducer";
import { mealBalancingReducer } from "./meal-balancing/meal-balancing.reducer";
import { permissionReducer } from "./permission/permission.reducer";
import { promotionalCodesReducer } from "./promotional-code/promotional-code-reducer";
import { systemReducer } from "./system/system.reducer";
import { frozenFoodReducer } from "./frozen-food/frozen-food.reducer";
import { ToGoReducer } from "./to-go/to-go.reducer";
import { BusinessUserReducer } from "./business-user/business-user.reducer";
import { SupplementReducer } from "./supplement/supplement.reducer";
import coreReducer from "./core/core.reducer";
import { tenantsReducer } from "./tenant/tenant.reducer";

export const rootReducer = combineReducers({
	...UserReducer,
	...AffiliateReducer,
	...SalesPersonReducer,
	...SharedReducer,
	...OrderReducer,
	...DriverReducer,
	...PostcodeReducer,
	...LookupReducer,
	...HolidayReducer,
	...GuestOrderReducer,
	...permissionReducer,
	...promotionalCodesReducer,
	...discountCodesReducer,
	...clientDashboardReducer,
	...adminDashboardReducer,
	...systemReducer,
	...customerManagerReducer,
	...mealBalancingReducer,
	...OrderEditWizard,
	...WeeklyOrdersReducer,
	...frozenFoodReducer,
	...ToGoReducer,
	...BusinessUserReducer,
	...SupplementReducer,
	...coreReducer,
	...tenantsReducer
})