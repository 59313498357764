import { ToGoOrderTemplateItems } from './../../types/to-go.types';
import { ToGoItemType, ToGoState } from "../../types/to-go.types";
import { ToGoActions } from "./to-go.actions";
import * as actionTypes from "./to-go.constants";

export const ToGoReducer = {
  togo,
};

function togo(state: ToGoState = {}, action: ToGoActions) {
  switch (action.type) {
    case actionTypes.TOGO_LOAD_ALL_MEALS: {
      return { ...state, allMeals: action.meals };
    }
    case actionTypes.TOGO_LOAD_ALL_EXTRAS: {
      return { ...state, allExtras: action.extras };
    }
    case actionTypes.LOAD_TOGO_PRICING_MATRIX: {
      return { ...state, pricingMatrix: action.pricingMatrix };
    }
    case actionTypes.TOGO_SELECT_MENU_ITEM: {
      if (state.templateEditing) {
        const itemType =
          action.itemType === ToGoItemType.Extra ? "extras" : "meals";

        const itemCounts = state.templateEditing[itemType][action.itemId] || {};
        const itemCountsNew = {
          ...itemCounts,
          [action.dayOfWeek]: action.count,
        };
        const items = {
          ...state.templateEditing[itemType],
          [action.itemId]: itemCountsNew,
        };

        return {
          ...state,
          templateEditing: {
            ...state.templateEditing,
            [itemType]: items,
          },
        };
      }
      return state;
    }
    case actionTypes.TOGO_SET_EDITING_TEMPLATE: {
      return { ...state, templateEditing: action.templateEditing };
    }
    case actionTypes.TOGO_SET_SELECTED_ORDER: {
      return { ...state, selectedOrder: action.selectedOrder };
    }
    case actionTypes.TOGO_SET_CURRENT_TEMPLATES: {
      return { ...state, currentTemplates: action.currentTemplates };
    }
    case actionTypes.TOGO_SET_CURRENT_ORDERS: {
      return { ...state, currentOrders: action.currentOrders };
    }
    case actionTypes.TOGO_UPDATE_CURRENT_TEMPLATE: {
      const templatesList = state.currentTemplates || [];

      const index = templatesList.findIndex((t) => t.id === action.template.id);

      if (index >= 0) templatesList.splice(index, 1, action.template);
      else templatesList.push(action.template);

      return { ...state, currentTemplates: [...templatesList] };
    }
    case actionTypes.TOGO_ORDER_CLEAR_STATE: {
      return {
        ...state,
        currentTemplates: undefined,
        templateEditing: undefined,
      };
    }
    case actionTypes.TOGO_CLEAR_STATE: {
      return {};
    }
    case actionTypes.TOGO_LOAD_HOLIDAYS: {
      return { ...state, holidays: action.holidays };
    }
    case actionTypes.TOGO_SKIPPED_WEEKS: {
      return { ...state, skippedWeeks: action.skippedWeeks };
    }
    case actionTypes.TOGO_EDIT_TEMPLATE: {
      return {
        ...state,
        templateEditing: {
          ...state.templateEditing,
          ...action.templateEditing,
        },
      };
    }
    case actionTypes.TOGO_LOAD_USER_PROFILE: {
      return { ...state, userProfile: action.userProfile };
    }
    case actionTypes.TOGO_EDIT_USER_INFO: {
      let userInfo = state.userProfile && { ...state.userProfile.userInfo, ...action.userInfo } || action.userInfo;
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          userInfo
        },
      };
    }
    case actionTypes.TOGO_SET_STARTING_DATE: {
      return {
        ...state,
        startingDate: action.date
      };
    }
    case actionTypes.TOGO_CLEAR_ALL_ITEMS: {
      if (state.templateEditing) {
        const dayOfWeek = action.effectiveDayOfWeek.toString();
        
        return {
          ...state,
          templateEditing: {
            ...state.templateEditing,
            extras: getUpdatedToGoItemTypeCounts(state.templateEditing.extras, dayOfWeek),
            meals: getUpdatedToGoItemTypeCounts(state.templateEditing.meals, dayOfWeek)
          },
        };
      }
    }
  }
  return state;
}

// Support functions
function getUpdatedToGoItemTypeCounts(templateItems: ToGoOrderTemplateItems, effectiveDayOfWeek: string) {
  let newItemCounts = { ...templateItems };

  for (const item in templateItems) {
    if (Object.prototype.hasOwnProperty.call(templateItems, item)) {
      const itemsCounts = { ...templateItems[item] };

      for (const count in itemsCounts) {
        if (Object.prototype.hasOwnProperty.call(itemsCounts, count)) {
          if (count >= effectiveDayOfWeek) {
            delete itemsCounts[count];
          }
        }
      }

      newItemCounts = {
        ...newItemCounts,
        [item]: itemsCounts
      }
    }
  }

  return newItemCounts;
}

