import { applyMiddleware, createStore } from "redux";

import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './root.reducer';
import thunkMiddleware from "redux-thunk";

const composeEnhancers = composeWithDevTools({});
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
)