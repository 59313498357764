import { GlobalAlertMessage, GlobalConfirmationMessage } from '../../types/shared-dto.types';
import { GlobalImageLightbox, MessagePopupState, PageTitleState, SpinnerState, ApiRequestsState } from '../../types/shared-store.types';
import { GlobalAlertDialogActions, GlobalConfirmationActions, GlobalLightboxActions, MessagePopupActions, SetPageTitle, SpinnerActions, StartApiRequest, EndApiRequest } from './shared.actions';
import * as constants from './shared.constants';

export const SharedReducer = {
    messagePopup,
    globalConfirmationMessage,
    globalImageLightbox,
    globalAlertMessage,
    pageTitle,
    spinner,
    apiRequestState,
}

function messagePopup(state: MessagePopupState = {}, action: MessagePopupActions) {
    switch (action.type) {
        case constants.SHOW_MESSAGE_POPUP: {
            return { ...state, message: action.message, header: action.header, redirectUrl: action.redirectUrl }
        }
        case constants.HIDE_MESSAGE_POPUP: {
            return {}
        }
    }
    return {};
}

function globalConfirmationMessage(state: GlobalConfirmationMessage = {}, action: GlobalConfirmationActions) {
    switch (action.type) {
        case constants.SHOW_GLOBAL_CONFIRMATION: {
            return { ...state, visible: true, message: action.message, title: action.title, confirmButtonText: action.confirmButtonText, denyButtonText: action.denyButtonText }
        }
        case constants.HIDE_GLOBAL_CONFIRMATION: {
            return {}
        }
    }
    return state;
}

function globalImageLightbox(state: GlobalImageLightbox = {}, action: GlobalLightboxActions) {
    switch (action.type) {
        case constants.SHOW_GLOBAL_LIGHTBOX: {
            return { ...state, visible: true, imageCollectionKey: action.imageCollectionKey, imageId: action.imageId }
        }
        case constants.HIDE_GLOBAL_LIGHTBOX: {
            return {}
        }
    }
    return state;
}

function globalAlertMessage(state: GlobalAlertMessage = {}, action: GlobalAlertDialogActions) {
    switch (action.type) {
        case constants.SHOW_GLOBAL_ALERT: {
            return { ...state, visible: true, message: action.message, title: action.title }
        }
        case constants.HIDE_GLOBAL_ALERT: {
            return {}
        }
    }
    return state;
}

function pageTitle(state: PageTitleState = {}, action: SetPageTitle) {
    switch (action.type) {
        case constants.SET_PAGE_TITLE: {
            return { ...state, title: action.title, customTitle: action.customTitle }
        }
    }
    return state;
}

const defaultSpinnerState: SpinnerState = {
    globalSpinner: {
        pendingRequestCount: 0
    },
    partialSpinners: {
        currentPackageSummary: false,
        currentOrder: false,
        tokenSummary: false,
        tokenLedger: false,
        holidays: false,
        packageOptions: false,
        userAccountInfo: false,
        deliveryInfos: false,
        cardDetails: false,
        autoRenewalStatus: false,
        deliverySchedules: false,
        deliveryScheduleDays: false,
        referralLink: false,
        discountCodes: false,
        affiliateTokenHistory: false,
        customerOrderHistory: false,
        supplementOrderHistory: false,
        mealsMetadata: false,
        creditDetails: false,
        expiringTokensList: false,
        pendingOrder: false,
        pendingOrderDeliverySchedules: false,
        bagCollectionLogs: false,
        histogramDetails: false
    }
}

function spinner(state: SpinnerState = defaultSpinnerState, action: SpinnerActions) {
    switch (action.type) {

        case constants.SHOW_GLOBAL_SPINNER:
            return { ...state, globalSpinner: { pendingRequestCount: state.globalSpinner.pendingRequestCount + 1 } };
        case constants.SHOW_PARTIAL_SPINNER: {
            let clonePartialSpinners = { ...state.partialSpinners };
            clonePartialSpinners[action.key] = true;
            return { ...state, partialSpinners: clonePartialSpinners }
        }
        case constants.HIDE_PARTIAL_SPINNER: {
            let clonePartialSpinners = { ...state.partialSpinners };
            clonePartialSpinners[action.key] = false;
            return { ...state, partialSpinners: clonePartialSpinners }
        }
        case constants.HIDE_GLOBAL_SPINNER: {
            if (state.globalSpinner.pendingRequestCount > 0) {
                return { ...state, globalSpinner: { pendingRequestCount: state.globalSpinner.pendingRequestCount - 1 } };
            } else {
                return state
            }
        }
    }
    return state;
}

function apiRequestState(
    state: ApiRequestsState = {},
    action: StartApiRequest | EndApiRequest
  ) {
    switch (action.type) {
      case constants.START_API_REQUEST: {
        return {
          ...state,
          [action.endpoint]: (state[action.endpoint] || 0) + 1,
        };
      }
      case constants.END_API_REQUEST: {
        let requestCount = (state[action.endpoint] || 1) - 1;
        requestCount = requestCount < 0 ? 0 : requestCount;
        return {
          ...state,
          [action.endpoint]: requestCount,
        };
      }
      default:
          return state;
    }
  }  
