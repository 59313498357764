export const fff_brand_yellow = '#F7F536';
export const fff_brand_green = '#036E38';
export const fff_brand_new_primary = '#003A1F';
export const fff_brand_dark_green = '#02552b';
export const fff_brand_shaded_green = '#D4EDDA';
export const fff_brand_yellow_green = '#E8EBDF';
export const fff_brand_grey_green = '#F6F7F6'
export const fff_brand_new_secondary = '#6E810F';
export const fff_brand_black_text = '#212529';
export const fff_brand_light_green = '#F2fff9';
export const fff_brand_blue = '#004085';
export const fff_brand_shaded_blue = '#CCE5FF';
export const fff_brand_brown = '#856404';
export const fff_brand_shadded_brown = '#FFF3CD';
export const fff_brand_dark_red = '#721C24';
export const fff_brand_shadded_red = '#F8D7DA';
export const fff_brand_grey = '#54565C';
export const fff_brand_new_grey = '#CED1D1';
export const fff_brand_dark_grey = '#424546';
export const nav_background = '#ffffff';
export const fff_brand_white = '#ffffff';
