import * as intl from 'react-intl-universal';

import { ErrorData } from "../../types/shared-dto.types";
import { history } from '../../App';
import { messagingService } from "./messaging.service";
import { store } from '../../store';
import { coreActions } from '../../store/core/core.actions';
import { MaintenanceMode } from '../../types/shared-store.types';

export const errorHandlingService = {
  handleErrors
}

function handleErrors(response: any) {
  let status = response.status;
  let data: ErrorData = response.data;

  if(status === 503) {
    if(data && data.errorCode === 'ERR_UNDER_SYSTEM_MAINTENANCE' && data.errorData) {
      const errObj = data.errorData as any;
      var maintenanceMode : MaintenanceMode = {
        on: errObj.on,
        cutoffTimeUtc: new Date(errObj.cutoffTimeUtc),
      }
      store.dispatch(coreActions.setMaintenanceMode(maintenanceMode));
    }
  } else if (status === 401) {
    messagingService.notifyError(intl.get("ERR_UN_AUTHORIZE_ACCESS"));
    history.push('/login');
  } else if (status === 403) {
    messagingService.notifyError(intl.get("ERR_FORBIDDEN_ACCESS"));
    history.push('/');
  } else if (status === 400 && data) {
    handleFffException(data);
  } else if (data) {
    data.errorCode && messagingService.notifyError(intl.get(data.errorCode) || data.errorCode);
  } else {
    messagingService.notifyError(intl.get("ERR_UNKNOWN_ERROR"));
  }
}

function handleFffException(data: ErrorData) {
  let message = 'Something went wrong'
  if (data.errorCode) {
    let errorMsg = intl.get(data.errorCode) || data.errorCode;
    let msgData = data.errorData && data.errorData
      .split(',').map((val) => intl.get(val)).join(', ');
    message = msgData ? `${errorMsg} \r\n ${msgData}` : errorMsg;
    messagingService.notifyError(message);
  }
}
