import * as constants from "./core.constants";
import { AuthUser, MaintenanceMode } from "../../types/shared-store.types";
import { User } from "../../types/user-store.types";
import { Dispatch } from "react";
import { sessionService } from "../../services/shared/session.service";
import { userService } from "../../services/user.service";
import { TenantBasicInfo } from "../../types/tenant.types";

export const coreActions = {
  setAppInitialized,
  setAuthInitialized,
  setLocaleInitialized,
  setF45Initialized,
  setFeatureRegistryInitialized,
  setCurrentTenant,
  setAllTenants,
  setAuthUser,
  updateAuthUser,
  clearAuthUser,
  refreshAuthUser,
  setTenantsLoaded,
  setMaintenanceMode
};

export interface SetLocaleInitialized {
  type: constants.CORE_SET_LOCALE_INITIALIZED;
  initialized: boolean;
}

export interface SetAppInitialized {
  type: constants.CORE_SET_APP_INITIALIZED;
  initialized: boolean;
}

export interface SetAuthInitialized {
  type: constants.CORE_SET_AUTH_INITIALIZED;
  initialized: boolean;
}

export interface SetTenantsLoaded {
  type: constants.CORE_SET_TENANTS_LOADED,
  loaded: boolean;
}

export interface SetF45Initialized {
  type: constants.CORE_SET_F45_INITIALIZED;
  initialized: boolean;
}

export interface SetFeatureRegistryInitialized {
  type: constants.CORE_SET_FEATURE_REG_INITIALIZED;
  initialized: boolean;
}

export interface SetCurrentTenant {
  type: constants.CORE_SET_CURRENT_TENANT;
  tenant: string;
}

export interface SetAllTenants {
  type: constants.CORE_SET_ALL_TENANTS;
  tenants: TenantBasicInfo[];
}

export interface SetAuthUser {
  type: constants.CORE_SET_AUTH;
  auth: AuthUser;
}

export interface ClearAuthUser {
  type: constants.CORE_CLEAR_AUTH;
}

export interface UpdateAuthUser {
  type: constants.CORE_UPDATE_AUTH_USER;
  user: User;
}

export interface SetMaintenanceMode {
  type: constants.CORE_SET_MAINTENANCE_MODE;
  maintenanceMode: MaintenanceMode;
}

export type CoreActions =
  | SetLocaleInitialized
  | SetAppInitialized
  | SetF45Initialized
  | SetAuthInitialized
  | SetTenantsLoaded
  | SetFeatureRegistryInitialized
  | SetCurrentTenant
  | SetAllTenants
  | SetAuthUser
  | ClearAuthUser
  | UpdateAuthUser
  | SetMaintenanceMode;

function setAppInitialized(initialized: boolean): SetAppInitialized {
  return { type: constants.CORE_SET_APP_INITIALIZED, initialized };
}

function setAuthInitialized(initialized: boolean): SetAuthInitialized {
  return { type: constants.CORE_SET_AUTH_INITIALIZED, initialized };
}

function setTenantsLoaded(loaded: boolean): SetTenantsLoaded {
  return { type: constants.CORE_SET_TENANTS_LOADED, loaded };
}

function setLocaleInitialized(initialized: boolean): SetLocaleInitialized {
  return { type: constants.CORE_SET_LOCALE_INITIALIZED, initialized };
}

function setF45Initialized(initialized: boolean): SetF45Initialized {
  return { type: constants.CORE_SET_F45_INITIALIZED, initialized };
}

function setFeatureRegistryInitialized(
  initialized: boolean
): SetFeatureRegistryInitialized {
  return { type: constants.CORE_SET_FEATURE_REG_INITIALIZED, initialized };
}

function setCurrentTenant(tenant: string): SetCurrentTenant {
  return { type: constants.CORE_SET_CURRENT_TENANT, tenant };
}

function setAllTenants(tenants: TenantBasicInfo[]): SetAllTenants {
  return { type: constants.CORE_SET_ALL_TENANTS, tenants };
}

function setAuthUser(auth: AuthUser): SetAuthUser {
  return { type: constants.CORE_SET_AUTH, auth };
}

function clearAuthUser(): ClearAuthUser {
  return { type: constants.CORE_CLEAR_AUTH };
}

function updateAuthUser(user: User): UpdateAuthUser {
  return { type: constants.CORE_UPDATE_AUTH_USER, user };
}

function setMaintenanceMode(maintenanceMode: MaintenanceMode): SetMaintenanceMode {
  return { type: constants.CORE_SET_MAINTENANCE_MODE, maintenanceMode };
}

function refreshAuthUser() {
  return (dispatch: Dispatch<any>) => {
    userService.getCurrentUserBasicInfo()
      .then((user: User) => {
        sessionService.updateAuthStateUser(user);
        dispatch(updateAuthUser(user));
      })
  }
}
