import * as permissions from "../types/permission-constants";
import { AuthUser } from "../types/shared-store.types";

export const authUtils = {
  isImpersonating,
  isAffiliate,
  isCustomer,
  isBusinessUser,
  isPartialCustomer,
  getUserRoles,
  isBackendUser,
  isExternalUser,
  getPermissions,
  hasPermission,
  hasAnyPermission,
  isInRole,
  hasAnySubscribedTenant
}

function getPermissions(authState?: AuthUser) {
  return (authState && authState.permissions) || [];
}

function getUserRoles(authState?: AuthUser) {
  return (authState && authState.user.roleNames) || [];
}

function isBackendUser(authState?: AuthUser) {
  return hasPermission(authState, permissions.RoleType.cmsUser);
}

function isExternalUser(authState?: AuthUser) {
  return hasPermission(authState, permissions.RoleType.publicUser);
}

function hasPermission(authState?: AuthUser, permission: string = '') {
  var allPermissions = getPermissions(authState);
  var hasPermission = allPermissions.indexOf(permission) >= 0;
  return hasPermission;
}

function hasAnyPermission(authState?: AuthUser, permissions: string[] = []) {
  var allPermissions = getPermissions(authState);
  var hasAnyPermission = allPermissions.some(p => permissions.includes(p));
  return hasAnyPermission;
}

function isInRole(authState?: AuthUser, role: string = '') {
  return ((authState && authState.user.roleNames) || []).includes(role);
}

function isImpersonating(authState?: AuthUser) {
  return (authState && authState.isImpersonating) || false;
}

function isAffiliate(authState?: AuthUser) {
  return isInRole(authState, permissions.UserRoles.Affiliate);
}

function isCustomer(authState?: AuthUser) {
  return isInRole(authState, permissions.UserRoles.Customer);
}

function isBusinessUser(authState?: AuthUser) {
  return isInRole(authState, permissions.UserRoles.BusinessUser);
}

function isPartialCustomer(authState?: AuthUser) {
  return isInRole(authState, permissions.UserRoles.PartialCustomer);
}

function hasAnySubscribedTenant(authState?: AuthUser) {
  return !!authState && authState.subscribedTenants.length > 0;
}
