import React, { useState, useEffect, useRef } from "react";
import { Container } from "reactstrap";
import logo from "../../../../assets/img/fff-logo.svg";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { useSelector } from "react-redux";
import { AppState } from "../../../../types/shared-store.types";

export default function MaintenancePage() {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const maintenanceMode = useSelector(
    (state: AppState) => state.core.maintenanceMode
  );

  const countdown = () => {
    if (maintenanceMode) {
      var currentUnixTime = Math.floor(new Date().getTime() / 1000);
      var cutoffTime = Math.floor(
        maintenanceMode.cutoffTimeUtc.getTime() / 1000
      );
      var secondsToComplete = cutoffTime - currentUnixTime;
      var hours = Math.floor(secondsToComplete / 3600);
      var minutes = Math.floor((secondsToComplete - hours * 3600) / 60);
      var seconds = Math.floor(
        secondsToComplete - (hours * 3600 + minutes * 60)
      );
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
  };

  const [timeLeft, setTimeLeft] = useState(countdown());

  const refreshCountdown = () => {
    setTimeLeft(countdown());
    const ref = setTimeout(() => {
      refreshCountdown();
    }, 1000);
    timeoutRef.current = ref;
  };

  useEffect(() => {
    refreshCountdown();

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div className="wrapper">
      <header className="main-header">
        <Container>
          <div className="logo-center">
            <img
              src={logo}
              alt="logo"
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        </Container>
      </header>
      <div
        className="fff-main-panel"
        style={{
          height: "calc(100vh - 70px)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: `url("https://prod-fff-blob-storage.s3.eu-west-2.amazonaws.com/Images/common/app-maintenance-mode.png")`,
        }}
      >
        <div className="content">
          <Container>
            <Row style={{ paddingTop: "500px" }}>
              <Col className="text-center">
                <h2>Please try again in,</h2>
                <h1>{timeLeft}</h1>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
