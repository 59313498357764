import * as constants from './client-dashboard.constants'

import { ClientDashboardState } from "../../types/dashboard.types";
import { DashboardActions } from "./client-dashboard.actions";

export const clientDashboardReducer = {
  clientDashboard
}

function clientDashboard(state: ClientDashboardState = {}, action: DashboardActions) {
  switch (action.type) {
    case constants.REFRESH_TRANSACTION_HISTORY: {
      return { ...state, refreshTransactionHistory: !(state.refreshTransactionHistory || false) }
    }
  }
  return state;
}
