import queryString from 'query-string';
import * as intl from 'react-intl-universal';

import axios, { AxiosRequestConfig, Method } from 'axios';

import { saveAs } from 'file-saver';
import { config } from "../../config";
import { accountService } from '../account.service';
import { errorHandlingService } from './error-handling.service';
import { fileUtilsService } from './file-utils.service';
import { messagingService } from './messaging.service';
import { sessionService } from './session.service';
import { spinnerService } from './spinner.service';

var instance = axios.create({
  baseURL: config.api.BASE_URL,
});
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

export const apiService = {
  get,
  post,
  put,
  patch,
  download,
  delete: deleteRecord,
  delete2: deleteRecord2
}

function get<T>(controller: string, action: string = '', urlParams: string[] = [], queryParams: any = null, showGlobalSpinner = false,
  requestName: string = '', suppressErrors = false) {
  return apiRequest<T>('get', controller, action, null, urlParams, queryParams, showGlobalSpinner, requestName, suppressErrors);
}

function post<T>(controller: string, action: string = '', data: any, urlParams: string[] = [], queryParams: any = null, showGlobalSpinner = false) {
  return apiRequest<T>('post', controller, action, data, urlParams, queryParams, showGlobalSpinner);
}

function put<T>(controller: string, action: string = '', data: any, urlParams: string[] = [], queryParams: any = null, showGlobalSpinner = false) {
  return apiRequest<T>('put', controller, action, data, urlParams, queryParams, showGlobalSpinner);
}

function patch<T>(controller: string, action: string = '', data: any, urlParams: string[] = [], queryParams: any = null, showGlobalSpinner = false) {
  return apiRequest<T>('patch', controller, action, data, urlParams, queryParams, showGlobalSpinner);
}
function deleteRecord(controller: string, action: string = '', urlParams: string[] = [], queryParams: any = null, showGlobalSpinner = false) {
  return apiRequest<any>('delete', controller, action, null, urlParams, queryParams, showGlobalSpinner);
}

function deleteRecord2<T>(controller: string, action: string = '', data: any, urlParams: string[] = [], queryParams: any = null, showGlobalSpinner = false) {
  return apiRequest<T>('delete', controller, action, data, urlParams, queryParams, showGlobalSpinner);
}

function download<T>(controller: string, action: string = '', urlParams: string[] = [], queryParams: any = null) {
  return apiDownloadRequest<T>('get', controller, action, null, urlParams, queryParams, true);
}

function apiRequest<T>(method: Method, controller: string, action: string = '', data: any, urlParams: string[] = [],
  queryParams: any = null, showGlobalSpinner = false, requestName: string = '', suppressErrors = false) {
  let url = createUrl(controller, action, urlParams, queryParams);
  let options = createRequestOptions(url, method, data);

  const endpoint = requestName || `${controller}/${action}/${method}`.replace('//', '/').toLowerCase();

  let requestInfo = showGlobalSpinner && spinnerService.startGlobalSpinner() || undefined;
  const requestInfo2 = spinnerService.startApiRequest(endpoint);

  return instance.request<T>(options)
    .then(res => res && res.data)
    .catch(error => {
      if (!suppressErrors) {
        if (error.response) {
          errorHandlingService.handleErrors(error.response);
        } else {
          messagingService.notifyError(intl.get("ERR_UNKNOWN_ERROR"));
        }
      }
      throw error;
    })
    .finally(() => {
      requestInfo && spinnerService.endGlobalSpinner(requestInfo);
      spinnerService.endApiRequest(requestInfo2);
    });
}

function apiDownloadRequest<T>(method: Method, controller: string, action: string = '', data: any, urlParams: string[] = [],
  queryParams: any = null, showGlobalSpinner = false) {
  var url = createUrl(controller, action, urlParams, queryParams);
  var options = createRequestOptions(url, method, data, 'blob');

  let requestInfo = showGlobalSpinner && spinnerService.startGlobalSpinner() || undefined;

  return instance.request<any>(options)
    .then(response => {
      const filename = response.headers['content-disposition']
        .split(';')
        .find((n: any) => n.includes('filename='))
        .replace('filename=', '')
        .trim();

      const url = window.URL.createObjectURL(new Blob([response.data]));
      saveAs(url, filename);
    })
    .catch(async error => {
      let convertedDataBlob: any = await fileUtilsService.readFile(error.response.data);
      let errData = JSON.parse(convertedDataBlob);

      let errResponse = {
        status: error.response.status,
        data: errData
      };
      throw errResponse;
    })
    .finally(() => {
      requestInfo && spinnerService.endGlobalSpinner(requestInfo);
    });
}

function createUrl(controller: string, action: string = '', urlParams: string[] = [], queryParams: any = null) {
  let url = controller + (action ? '/' + action : '');

  urlParams.forEach(param => {
    url += '/' + param;
  });

  let params = '';
  if (queryParams) {
    params += '?' + queryString.stringify(queryParams);
  }

  return url += params;
}

function createRequestOptions(url: string, method: Method, data: any, responseType?: any) {
  const authToken = sessionService.getAuthToken();

  const tenantCode = config.web.SINGLE_TENANT_MODE ? config.web.SINGLE_TENANT_MODE : sessionService.getTenantCode() || '';

  const options: AxiosRequestConfig = {
    url,
    method,
    data,
    headers: {
      'Authorization': 'bearer ' + authToken,
      'Client-Application': 'WEB',
      'x-tenant': tenantCode.toUpperCase(),
      'x-portal-type': sessionService.getPortalType(),
    },
  }

  if (responseType) {
    options.responseType = responseType;
  }

  return options;
}

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string = '') => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
}

instance.interceptors.response.use(undefined, (error) => {
  const originalRequest = error.config;
  if (originalRequest && error.response && error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      }).then(authToken => {
        originalRequest.headers.Authorization = 'bearer ' + authToken;
        return axios(originalRequest);
      }).catch(err => {
        return err;
      })
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(function (resolve, reject) {
      accountService.refreshToken()
        .then(result => {
          if (result.succeeded) {
            originalRequest.headers.Authorization = 'bearer ' + result.authToken;
            axios(originalRequest).then(resolve, reject);
            processQueue(null, result.authToken);
          } else {
            reject(error);
          }
        }).catch((err) => {
          processQueue(err);
          reject(err);
        }).then(() => { isRefreshing = false });
    });
  }
  return Promise.reject(error);
});

/***
 * This is to fix the promise finally not working in IE Edge
 * Can remove this once IE Edge fix this issue
 */
if (!Promise.prototype.finally) {
  console.log('IE only hack');

  Promise.prototype.finally = function (cb: any) {
    const res = () => this
    const fin = () => Promise.resolve(cb()).then(res)
    return this.then(fin, fin);
  };
}