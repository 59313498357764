export const LOAD_SYSTEM_AUDIT = "LOAD_SYSTEM_AUDIT";
export type LOAD_SYSTEM_AUDIT = typeof LOAD_SYSTEM_AUDIT;

export const LOAD_ACTIVITY_LOG = "LOAD_ACTIVITY_LOG";
export type LOAD_ACTIVITY_LOG = typeof LOAD_ACTIVITY_LOG;

export const LOAD_CONFIG_SETTINGS = "LOAD_CONFIG_SETTINGS";
export type LOAD_CONFIG_SETTINGS = typeof LOAD_CONFIG_SETTINGS;

export const LOAD_GENERAL_CONFIG_SETTINGS = 'LOAD_GENERAL_CONFIG_SETTINGS'
export type LOAD_GENERAL_CONFIG_SETTINGS = typeof LOAD_GENERAL_CONFIG_SETTINGS

export const LOAD_ENTITY_TYPES = 'LOAD_ENTITY_TYPES'
export type LOAD_ENTITY_TYPES = typeof LOAD_ENTITY_TYPES

export const LOAD_HOMEPAGE_MANAGED_CONTENT = "LOAD_HOMEPAGE_MANAGED_CONTENT";
export type LOAD_HOMEPAGE_MANAGED_CONTENT = typeof LOAD_HOMEPAGE_MANAGED_CONTENT;

export const LOAD_DEFAULT_PAYMENT_PLATFORM = 'LOAD_DEFAULT_PAYMENT_PLATFORM'
export type LOAD_DEFAULT_PAYMENT_PLATFORM = typeof LOAD_DEFAULT_PAYMENT_PLATFORM

export const LOAD_FEATURE_REGISTRY = 'LOAD_FEATURE_REGISTRY'
export type LOAD_FEATURE_REGISTRY = typeof LOAD_FEATURE_REGISTRY

export const LOAD_SYSTEM_ERROR_LOG = "LOAD_SYSTEM_ERROR_LOG";
export type LOAD_SYSTEM_ERROR_LOG = typeof LOAD_SYSTEM_ERROR_LOG;

export const SYSTEM_ERROR_LOG_RESOLVE = "SYSTEM_ERROR_LOG_RESOLVE";
export type SYSTEM_ERROR_LOG_RESOLVE = typeof SYSTEM_ERROR_LOG_RESOLVE;

export const SYSTEM_ERROR_LOG_UNRESOLVE = "SYSTEM_ERROR_LOG_UNRESOLVE";
export type SYSTEM_ERROR_LOG_UNRESOLVE = typeof SYSTEM_ERROR_LOG_UNRESOLVE;
