import * as constants from "./affiliate.constants";

import { AffiliateActions } from "./affiliate.actions";
import { AffiliatesState } from "../../types/affiliate-store.types";

export const AffiliateReducer = {
    affiliates
}

function affiliates(state: AffiliatesState = {}, action: AffiliateActions) {
    switch (action.type) {
        case constants.LOAD_AFFILIATE_REQUESTS: {
            return { ...state, affiliateRequests: action.list }
        }
        case constants.LOAD_SELECTED_AFFILIATE_REQUEST: {
            return { ...state, selectedAffiliateRequest: action.request }
        }
        case constants.LOAD_AFFILIATES: {
            return { ...state, list: action.affiliateList }
        }
    }
    return state;
}