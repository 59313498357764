import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './assets/scss/styles.scss';
import './index.scss';

import "bootstrap/dist/js/bootstrap.min.js";

import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';

import ReactDOM, { hydrate, render } from 'react-dom';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import { config } from './config';
import { createBrowserHistory } from 'history';
import { store } from './store';

Sentry.init({ dsn: config.sentry.DSN });

const history = createBrowserHistory();

const rootElement = document.getElementById("root");
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}>
    <App history={history} />
  </Provider>, rootElement);
} else {
  render(<Provider store={store}>
    <App history={history} />
  </Provider>, rootElement);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
