import { orderService } from '../../services/order.service';
import { OrderEditWizardState, OrderFormState } from "../../types/order-store.types";
import { OrderActions, OrderEditWizardActions } from "./order.actions";
import * as constants from "./order.constants";

export const OrderReducer = {
  orderForm: orderFormReducer
}

export const GuestOrderReducer = {
  guestOrders: guestOrderReducer
}

export const WeeklyOrdersReducer = {
  weeklyOrders: weeklyOrdersReducer
}

export const OrderEditWizard = {
  orderEditWizard: orderEditWizardReducer
}

function orderFormReducer(state: OrderFormState = { visible: false }, action: OrderActions) {
  let returnState = { ...state };
  switch (action.type) {
    case constants.SET_FILTERED_META_DATA: {
      returnState = { ...returnState, filteredMetadata: { ...returnState.filteredMetadata, ...action.data } }
      break;
    }
    case constants.LOAD_ALL_META_DATA: {
      returnState = { ...returnState, allMetadata: action.data }
      break;
    }
    case constants.LOAD_PACKAGE_META_DATA: {
      returnState = { ...returnState, packageMetadata: action.data }
      break;
    }
    case constants.LOAD_ORDER_STAGING: {
      returnState = { ...returnState, order: action.orderStaging }
      break;
    }
    case constants.UPDATE_ORDER_STAGING: {
      let order = { ...state.order, ...action.updatedOrder };
      returnState = { ...returnState, order, isValid: action.isValid };
      break;
    }
    case constants.CLEAR_ORDER_FORM_ON_LOGOUT: {
      returnState = { ...returnState, order: undefined, isValid: false, isAdminRequest: false };
      break;
    }
    case constants.SET_PAYMENT_ERROR_CODE: {
      returnState = { ...returnState, paymentErrorCode: action.errorCode }
      break;
    }
    case constants.SET_ORDER_VALIDITY: {
      returnState = { ...returnState, isValid: action.isValid }
      break;
    }
    case constants.UPDATE_ORDER_FORM_STEPS: {
      returnState = { ...returnState, orderFormSteps: action.steps }
      break;
    }
    case constants.RESET_ORDER_STAGING: {

      let resetOrder = state.order;
      if (resetOrder) {
        resetOrder.traversedSteps = "";
        resetOrder.isTermsAndConditionsChecked = false;
        resetOrder.currentWizardStep = 0;
      }

      returnState = { ...returnState, order: resetOrder, isAdminRequest: false }
      break;
    }
    case constants.LOAD_PACKAGE_PRICES: {
      returnState = { ...returnState, packagePrices: action.packagePrices }
      break;
    }
    case constants.LOAD_CALORIE_MATRIX_DATA: {
      returnState = { ...returnState, calorieMatrixData: action.data }
      break;
    }
    case constants.FILTER_META_DATA: {
      const mapping = returnState.allMetadata && returnState.allMetadata.mappings;
      const metadata = returnState.allMetadata && returnState.allMetadata.metadata;

      if (mapping && metadata) {
        let data = orderService.getFilteredMetadataForOrderForm(metadata, mapping, action.filterByHeader, action.filterById);
        let itemsToFlush = orderService.getIsolatedItemsToFlush();
        returnState = { ...returnState, filteredMetadata: { ...returnState.filteredMetadata, ...itemsToFlush, ...data } };
      }

      break;
    }
    case constants.FILTER_PACKAGE_META_DATA: {
      const packageMetadata = returnState.packageMetadata;
      const allMetadata = returnState.allMetadata && returnState.allMetadata.metadata;
      const filteredMetadata = returnState.filteredMetadata;

      if (allMetadata && packageMetadata && filteredMetadata) {
        let data = orderService.getFilteredPackageMetadata(allMetadata, filteredMetadata, packageMetadata, action.packageMetadataFilter);
        let itemsToFlush = orderService.getIsolatedItemsToFlush();
        returnState = { ...returnState, filteredMetadata: { ...returnState.filteredMetadata, ...itemsToFlush, ...data } };
      }

      break;
    }
    case constants.TOGGLE_ORDER_FORM: {
      returnState = { ...returnState, visible: !returnState.visible }
      break;
    }
    case constants.UPDATE_AND_STAGE_ORDER: {
      let order = { ...returnState.order, ...action.updatedOrder }
      returnState = { ...returnState, order };
      break;
    }
    case constants.SET_ADMIN_ORDER_REQUEST_STATUS: {
      returnState = { ...returnState, isAdminRequest: action.isAdminRequest }
      break;
    }
    case constants.LOAD_PRICING_MATRIX_DATA: {
      returnState = { ...returnState, packagePricingMatrixData: action.data }
      break;
    }
    case constants.LOAD_MEAL_EXTRAS_UNIT_PRICE_DATA: {
      returnState = { ...returnState, mealExtrasUnitPriceData: action.data }
      break;
    }
    case constants.LOAD_WEEKLY_PAYMENT_SURPLUS_AMOUNT: {
      returnState = { ...returnState, weeklyPaymentSurplus: action.data }
      break;
    }
  }

  return returnState;
}

function orderEditWizardReducer(state: OrderEditWizardState = { visible: false }, action: OrderEditWizardActions) {
  switch (action.type) {
    case constants.TOGGLE_ORDER_EDIT_WIZARD: {
      return { ...state, visible: !state.visible, orderId: action.orderId, order: undefined, successCallback: action.successCallback };
    }
    case constants.LOAD_ALL_META_DATA_FOR_ORDER_EDIT: {
      return { ...state, allMetadata: action.data }
    }
    case constants.LOAD_ORDER_TO_EDIT: {
      return { ...state, order: action.order }
    }
    case constants.FILTER_META_DATA_FOR_ORDER_EDIT: {
      const mapping = state.allMetadata && state.allMetadata.mappings;
      const metadata = state.allMetadata && state.allMetadata.metadata;

      if (mapping && metadata) {
        let data = orderService.getFilteredMetadataForOrderForm(metadata, mapping, action.header, action.headerId);
        let itemsToFlush = orderService.getIsolatedItemsToFlush();
        return { ...state, filteredMetadata: { ...state.filteredMetadata, ...itemsToFlush, ...data } };
      }
      break;
    }
    case constants.LOAD_CALORIE_MATRIX_DATA_FOR_ORDER_EDIT: {
      return { ...state, calorieMatrixData: action.data }
    }
    case constants.ORDER_EDIT_UPDATE_ORDER: {
      return { ...state, order: { ...state.order, ...action.data } };
    }
  }

  return state;
}

function guestOrderReducer(state: any = {}, action: OrderActions) {
  switch (action.type) {
    case constants.LOAD_GUEST_ORDERS: {
      return { list: action.data }
    }
  }

  return state;
}

function weeklyOrdersReducer(state: any = {}, action: OrderActions) {
  switch (action.type) {
    case constants.LOAD_WEEKLY_ORDERS: {
      return { list: action.data }
    }
  }

  return state;
}