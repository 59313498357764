import * as constants from './salesperson.constants';

import { SalesPersonListActions } from './salesperson.actions';
import { SalesPersonState } from '../../types/salesperson-store.types';

const salesPersonReducer = (state: SalesPersonState = {}, action: SalesPersonListActions) => {
  switch (action.type) {
    case constants.LOAD_SALES_PERSON_LIST: {
      return {
        ...state, list: {
          items: action.items,
          total: action.total
        }
      }
    }
  }

  return state;
}

export const SalesPersonReducer = {
  salesPerson: salesPersonReducer
};