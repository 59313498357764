export const LOAD_CUSTOMER_BASIC_INFO = 'LOAD_CUSTOMER_BASIC_INFO';
export type LOAD_CUSTOMER_BASIC_INFO = typeof LOAD_CUSTOMER_BASIC_INFO;

export const UPDATE_CUSTOMER_ADVANCE_MEAL_SETTINGS = 'UPDATE_CUSTOMER_ADVANCE_MEAL_SETTINGS';
export type UPDATE_CUSTOMER_ADVANCE_MEAL_SETTINGS = typeof UPDATE_CUSTOMER_ADVANCE_MEAL_SETTINGS;

export const LOAD_CUSTOMER_DELIVERY_INFO = 'LOAD_CUSTOMER_DELIVERY_INFO';
export type LOAD_CUSTOMER_DELIVERY_INFO = typeof LOAD_CUSTOMER_DELIVERY_INFO;

export const LOAD_CUSTOMER_NOTES_LIST = 'LOAD_CUSTOMER_NOTES_LIST';
export type LOAD_CUSTOMER_NOTES_LIST = typeof LOAD_CUSTOMER_NOTES_LIST;

export const LOAD_CUSTOMER_MEAL_BLACKLIST = 'LOAD_CUSTOMER_MEAL_BLACKLIST';
export type LOAD_CUSTOMER_MEAL_BLACKLIST = typeof LOAD_CUSTOMER_MEAL_BLACKLIST;

export const LOAD_FFF_MEAL_LIST_ITEMS = 'LOAD_FFF_MEAL_LIST_ITEMS';
export type LOAD_FFF_MEAL_LIST_ITEMS = typeof LOAD_FFF_MEAL_LIST_ITEMS;

export const LOAD_EXTRA_RECIPES_LIST_ITEMS = 'LOAD_EXTRA_RECIPES_LIST_ITEMS';
export type LOAD_EXTRA_RECIPES_LIST_ITEMS = typeof LOAD_EXTRA_RECIPES_LIST_ITEMS;

export const LOAD_CUSTOMER_EXTRAS_BLACKLIST = 'LOAD_CUSTOMER_EXTRAS_BLACKLIST';
export type LOAD_CUSTOMER_EXTRAS_BLACKLIST = typeof LOAD_CUSTOMER_EXTRAS_BLACKLIST;

export const LOAD_CUSTOMER_MEAL_PLAN_INFO = 'LOAD_CUSTOMER_MEAL_PLAN_INFO';
export type LOAD_CUSTOMER_MEAL_PLAN_INFO = typeof LOAD_CUSTOMER_MEAL_PLAN_INFO;