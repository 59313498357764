import { PaymentPlan } from "../../types/fff.enums";
import { OrderMetadataItem, OrderStaging } from "../../types/order-store.types";

export const LOAD_ALL_META_DATA = 'LOAD_ALL_META_DATA';
export type LOAD_ALL_META_DATA = typeof LOAD_ALL_META_DATA;

export const LOAD_PACKAGE_META_DATA = 'LOAD_PACKAGE_META_DATA';
export type LOAD_PACKAGE_META_DATA = typeof LOAD_PACKAGE_META_DATA;

export const SET_FILTERED_META_DATA = 'SET_FILTERED_META_DATA';
export type SET_FILTERED_META_DATA = typeof SET_FILTERED_META_DATA;

export const FILTER_META_DATA = 'FILTER_META_DATA';
export type FILTER_META_DATA = typeof FILTER_META_DATA;

export const FILTER_PACKAGE_META_DATA = 'FILTER_PACKAGE_META_DATA';
export type FILTER_PACKAGE_META_DATA = typeof FILTER_PACKAGE_META_DATA;

export const LOAD_PACKAGE_PRICES = 'LOAD_PACKAGE_PRICES';
export type LOAD_PACKAGE_PRICES = typeof LOAD_PACKAGE_PRICES;

export const LOAD_GUEST_ORDERS = 'LOAD_GUEST_ORDERS';
export type LOAD_GUEST_ORDERS = typeof LOAD_GUEST_ORDERS;

export const LOAD_CALORIE_MATRIX_DATA = 'LOAD_CALORIE_MATRIX_DATA';
export type LOAD_CALORIE_MATRIX_DATA = typeof LOAD_CALORIE_MATRIX_DATA;

export const TOGGLE_ORDER_FORM = 'TOGGLE_ORDER_FORM'
export type TOGGLE_ORDER_FORM = typeof TOGGLE_ORDER_FORM;

export const UPDATE_STEP_NUMBER = 'UPDATE_STEP_NUMBER'
export type UPDATE_STEP_NUMBER = typeof UPDATE_STEP_NUMBER;

export const LOAD_ORDER_STAGING = 'LOAD_ORDER_STAGING';
export type LOAD_ORDER_STAGING = typeof LOAD_ORDER_STAGING;

export const UPDATE_ORDER_STAGING = 'UPDATE_ORDER_STAGING';
export type UPDATE_ORDER_STAGING = typeof UPDATE_ORDER_STAGING;

export const UPDATE_AND_STAGE_ORDER = 'UPDATE_AND_STAGE_ORDER';
export type UPDATE_AND_STAGE_ORDER = typeof UPDATE_AND_STAGE_ORDER;

export const RESET_ORDER_STAGING = 'RESET_ORDER_STAGING';
export type RESET_ORDER_STAGING = typeof RESET_ORDER_STAGING;

export const SET_PAYMENT_ERROR_CODE = 'SET_PAYMENT_ERROR_CODE';
export type SET_PAYMENT_ERROR_CODE = typeof SET_PAYMENT_ERROR_CODE;

export const SET_ORDER_VALIDITY = 'SET_ORDER_VALIDITY';
export type SET_ORDER_VALIDITY = typeof SET_ORDER_VALIDITY;

export const UPDATE_ORDER_FORM_STEPS = 'UPDATE_ORDER_FORM_STEPS';
export type UPDATE_ORDER_FORM_STEPS = typeof UPDATE_ORDER_FORM_STEPS;

export const SET_ADMIN_ORDER_REQUEST_STATUS = 'SET_ADMIN_ORDER_REQUEST_STATUS';
export type SET_ADMIN_ORDER_REQUEST_STATUS = typeof SET_ADMIN_ORDER_REQUEST_STATUS;

export const TOGGLE_ORDER_EDIT_WIZARD = 'TOGGLE_ORDER_EDIT_WIZARD';
export type TOGGLE_ORDER_EDIT_WIZARD = typeof TOGGLE_ORDER_EDIT_WIZARD;

export const LOAD_ALL_META_DATA_FOR_ORDER_EDIT = 'LOAD_ALL_META_DATA_FOR_ORDER_EDIT';
export type LOAD_ALL_META_DATA_FOR_ORDER_EDIT = typeof LOAD_ALL_META_DATA_FOR_ORDER_EDIT;

export const FILTER_META_DATA_FOR_ORDER_EDIT = 'FILTER_META_DATA_FOR_ORDER_EDIT';
export type FILTER_META_DATA_FOR_ORDER_EDIT = typeof FILTER_META_DATA_FOR_ORDER_EDIT;

export const LOAD_ORDER_TO_EDIT = 'LOAD_ORDER_TO_EDIT'
export type LOAD_ORDER_TO_EDIT = typeof LOAD_ORDER_TO_EDIT

export const LOAD_CALORIE_MATRIX_DATA_FOR_ORDER_EDIT = 'LOAD_CALORIE_MATRIX_DATA_FOR_ORDER_EDIT';
export type LOAD_CALORIE_MATRIX_DATA_FOR_ORDER_EDIT = typeof LOAD_CALORIE_MATRIX_DATA_FOR_ORDER_EDIT;

export const ORDER_EDIT_UPDATE_ORDER = 'ORDER_EDIT_UPDATE_ORDER';
export type ORDER_EDIT_UPDATE_ORDER = typeof ORDER_EDIT_UPDATE_ORDER;

export const LOAD_WEEKLY_ORDERS = 'LOAD_WEEKLY_ORDERS';
export type LOAD_WEEKLY_ORDERS = typeof LOAD_WEEKLY_ORDERS;

export const CLEAR_ORDER_FORM_ON_LOGOUT = 'CLEAR_ORDER_FORM_ON_LOGOUT';
export type CLEAR_ORDER_FORM_ON_LOGOUT = typeof CLEAR_ORDER_FORM_ON_LOGOUT;

export const LOAD_PRICING_MATRIX_DATA = 'LOAD_PRICING_MATRIX_DATA';
export type LOAD_PRICING_MATRIX_DATA = typeof LOAD_PRICING_MATRIX_DATA;

export const LOAD_MEAL_EXTRAS_UNIT_PRICE_DATA = 'LOAD_MEAL_EXTRAS_UNIT_PRICE_DATA';
export type LOAD_MEAL_EXTRAS_UNIT_PRICE_DATA = typeof LOAD_MEAL_EXTRAS_UNIT_PRICE_DATA;

export const LOAD_WEEKLY_PAYMENT_SURPLUS_AMOUNT = 'LOAD_WEEKLY_PAYMENT_SURPLUS_AMOUNT';
export type LOAD_WEEKLY_PAYMENT_SURPLUS_AMOUNT = typeof LOAD_WEEKLY_PAYMENT_SURPLUS_AMOUNT;

export const ORDER_METADATA_HEADER_CODE = {
  ORDER_GOAL: 'ORDER_GOAL',
  ACTIVE_LEVEL: 'ACTIVE_LEVEL',
  EXERCISE_TYPE: 'EXERCISE_TYPE',
  PACKAGE_TYPE: 'PACKAGE_TYPE',
  EXERCISE_HABIT: 'EXERCISE_HABIT',
  WORKOUT_HOURS_PER_WEEK: 'WORKOUT_HOURS_PER_WEEK',
  NUTRITION_PREFERENCE_VARIANT: 'NUTRITION_PREFERENCE_VARIANT',
  NUTRITION_PREFERENCE: 'NUTRITION_PREFERENCE',
  PLANT_BASED_NUTRITION_PREFERENCES: 'PLANT_BASED_NUTRITION_PREFERENCES',
  GENDER: 'GENDER',
  MATERNITY_STATUS: 'MATERNITY_STATUS',
  PREGNANCY_STATUS: 'PREGNANCY_STATUS',
  BREASTFEEDING_STATUS: 'BREASTFEEDING_STATUS',
  MEAL_COUNT: 'MEAL_COUNT',
  MISCELLANEOUS: 'MISCELLANEOUS',
  MEAL_EXTRAS: 'MEAL_EXTRAS',
  CUSTOM_MEAL_OPTION: 'CUSTOM_MEAL_OPTION',
  JUICES: 'JUICES',
  BOOSTERS: 'BOOSTERS',
  SNACKS: 'SNACKS',
  SHAKES: 'SHAKES',
  PAYMENT_OPTION: 'PAYMENT_OPTION'
}

export const MISCELLANEOUS_CODE = {
  INCLUDE_BREAKFAST: "INCLUDE_BREAKFAST",
  INCLUDE_VEGGIE_MEALS: "INCLUDE_VEGGIE_MEALS"
};

export const NUTRITION_PREFERENCE_CODE = {
  PLANT_BASED: 'PLANT_BASED',
  OWN_MACROS: 'OWN_MACROS',
  VEGAN: 'VEGAN',
  VEGGIE: 'VEGGIE',
  PALEO: 'PALEO',
  BALANCED: 'BALANCED',
  LOW_CARB: 'LOW_CARB',
  PESCITARIAN: 'PESCITARIAN',
  OFFICE: 'OFFICE',
  FLEXITARIAN: 'FLEXITARIAN'
}

export const PAYMENT_OPTION_CODE = {
  UPFRONT: 'UPFRONT',
  WEEKLY: 'WEEKLY',
  FLEXI: 'FLEXI'
}

export const PLANT_BASED_NUTRITION_PREFERENCE: OrderMetadataItem = {
  id: 'PLANT_BASED',
  code: 'PLANT_BASED',
  description: 'Plant Based',
  sortOrder: '2',
  thumbnailUrl: 'Images/meta-data/NUTRITION_PREFERENCE/PLANT_BASED.svg',
  caption: '',
  remark: ''
}

export const NUTRITION_PREFERENCE_GROUP_MAPPING = {
  'PLANT_BASED': [NUTRITION_PREFERENCE_CODE.VEGAN, NUTRITION_PREFERENCE_CODE.VEGGIE]
}

export const PAYMENT_OPTION_TO_PLAN_MAPPING: Record<PaymentPlan, string[]> = {
  [PaymentPlan.Commit]: [PAYMENT_OPTION_CODE.UPFRONT, PAYMENT_OPTION_CODE.WEEKLY],
  [PaymentPlan.Flexi]: [PAYMENT_OPTION_CODE.FLEXI]
};

export const ACTIVE_LEVEL_CODE = {
  NOT_VERY_ACTIVE: 'NOT_VERY_ACTIVE'
}

// Order Form Prompts Constants
export const PROMPT_POST_CODE = 'PROMPT_POST_CODE';
export const PROMPT_USER = 'PROMPT_USER';
export const PROMPT_GOAL = 'PROMPT_GOAL';
export const PROMPT_NUTRITION_PREFERENCE = 'PROMPT_NUTRITION_PREFERENCE';
export const PROMPT_ACTIVE_LEVEL = 'PROMPT_ACTIVE_LEVEL';
export const PROMPT_EXERCISE_HABITS = 'PROMPT_EXERCISE_HABITS';
export const PROMPT_EXERCISE_TYPES = 'PROMPT_EXERCISE_TYPES';
export const PROMPT_USER_DETAILS = 'PROMPT_USER_DETAILS';
export const PROMPT_EXCLUSIONS = 'PROMPT_EXCLUSIONS';
export const PROMPT_MEAL_MANAGEMENT = 'PROMPT_MEAL_MANAGEMENT';
export const PROMPT_PACKAGE_SELECTION = 'PROMPT_PACKAGE_SELECTION';
export const PROMPT_PAYMENT_PLAN_SELECTION = 'PROMPT_PAYMENT_PLAN_SELECTION';
export const PROMPT_DELIVERY_SCHEDULE = 'PROMPT_DELIVERY_SCHEDULE';
export const PROMPT_PAYMENT = 'PROMPT_PAYMENT';
export const PROMPT_ORDER_SUMMARY = 'PROMPT_ORDER_SUMMARY';
export const PROMPT_DELIVERY_ADDRESS = 'PROMPT_DELIVERY_ADDRESS';

export const PROMPT_ORDER_ACKNOWLEDGEMENT = 'PROMPT_ORDER_ACKNOWLEDGEMENT';

export const PROMPT_PREGNANCY_STATE = 'PROMPT_PREGNANCY_STATE';
export const PROMPT_PREGNANCY_PERIOD = 'PROMPT_PREGNANCY_PERIOD';
export const PROMPT_BREASTFEEDING_STATE = 'PROMPT_BREASTFEEDING_STATE';
export const MATERNITY_STATUS = {
  PREGNANT: 'PREGNANT',
  BREASTFEEDING: 'BREASTFEEDING',
  NEITHER: 'NEITHER'

}
export const PROMPT_PREFIX = 'PROMPT_';

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE'
}

export const PROMPT_POST_CODE_TITLE = 'TXT_ORDER_FORM_PROMPT_HEADER_POST_CODE_TITLE';
export const PROMPT_USER_TITLE = 'TXT_ORDER_FORM_PROMPT_HEADER_USER_TITLE';
export const PROMPT_GOAL_TITLE = 'TXT_ORDER_FORM_PROMPT_HEADER_GOAL_TITLE';
export const PROMPT_NUTRITION_PREFERENCE_TITLE = 'TXT_ORDER_FORM_PROMPT_HEADER_PREFERENCE_TITLE';
export const PROMPT_ACTIVE_LEVEL_TITLE = 'TXT_ORDER_FORM_PROMPT_HEADER_ACTIVE_LEVEL_TITLE';
export const PROMPT_EXERCISE_HABITS_TITLE = 'TXT_ORDER_FORM_PROMPT_HEADER_EXERCISE_HABITS_TITLE';
export const PROMPT_EXERCISE_TYPES_TITLE = 'TXT_ORDER_FORM_PROMPT_HEADER_EXERCISE_TYPES_TITLE';
export const PROMPT_USER_DETAILS_TITLE = 'TXT_ORDER_FORM_PROMPT_HEADER_USER_DETAIL_TITLE';
export const PROMPT_EXCLUSIONS_TITLE = 'TXT_ORDER_FORM_PROMPT_HEADER_EXCLUSIONS_TITLE';
export const PROMPT_MEAL_MANAGEMENT_TITLE = 'TXT_YOUR_DAILY_REQUIREMENT';
export const PROMPT_PACKAGE_SELECTION_TITLE = 'TXT_CHOOSE_PAYMENT_PLAN';
export const PROMPT_PAYMENT_PLAN_SELECTION_TITLE = 'TXT_CHOOSE_PAYMENT_PLAN';
export const PROMPT_DELIVERY_SCHEDULE_TITLE = 'TXT_ORDER_FORM_PROMPT_HEADER_DELIVERY_SCHEDULE_TITLE';
export const PROMPT_DELIVERY_ADDRESS_TITLE = 'TXT_ORDER_FORM_PROMPT_DELIVERY_ADDRESS_TITLE';
export const PROMPT_ORDER_SUMMARY_TITLE = 'TXT_ORDER_SUMMARY';
export const PROMPT_PAYMENT_TITLE = 'TXT_ORDER_PAYMENT';
export const PROMPT_PREGNANCY_STATE_TITLE = 'TXT_ORDER_FORM_PROMPT_PREGNANCY_STATE_TITLE';
export const PROMPT_PREGNANCY_PERIOD_TITLE = 'TXT_ORDER_FORM_PROMPT_PREGNANCY_PERIOD_TITLE';
export const PROMPT_BREASTFEEDING_STATE_TITLE = 'TXT_ORDER_FORM_PROMPT_PREGNANCY_BREASTFEEDING_TITLE';

export const DEFAULT_ORDER: OrderStaging = {
  total: 0.0,
  subTotal: 0.0,
  billingAddressSameAsDelivery: true,
  isTimedDelivery: false,
  includeBreakfast: true,
  includeVeggieMeals: true,
  onDeliveryLocationImageUploading: false,
  completed: false,
  isTermsAndConditionsChecked: false,
  subscribeToNewsletter: true
};