import { sortBy } from "lodash";
import { SupplementState } from "../../types/supplement.types";
import { SupplementActions } from "./supplement.actions";
import * as constants from "./supplement.constants";

export const SupplementReducer = {
  supplements
}

function supplements(state: SupplementState = {}, action: SupplementActions) {
  switch (action.type) {
    case constants.LOAD_SUPPLEMENT_INVENTORY: {
      return { ...state, inventory: action.inventory }
    }
    case constants.UPDATE_SUPPLEMENT_INVENTORY_SELECTION: {
      let selectedItems = state.selectedInventory || [];
      let index = selectedItems.findIndex(i => i.supplementId == action.selectedItem.supplementId);

      if (index >= 0) {
        selectedItems.splice(index, 1);
      }

      selectedItems = action.selectedItem.quantity > 0 ? [...selectedItems, action.selectedItem] : [...selectedItems];
      let sortedList = sortBy(selectedItems, ['name']);
      return { ...state, selectedInventory: sortedList };
    }
    case constants.LOAD_UNDELIVERABLE_SUPPLEMENT_ORDERS: {
      return { ...state, undeliverableSupplementOrders: action.undeliverableSupplementOrders }
    }
    case constants.CLEAR_SUPPLEMENT_INVENTORY_SELECTION: {
      return { ...state, selectedInventory: [] };
    }
    case constants.REPLACE_SUPPLEMENT_INVENTORY_SELECTION: {
      return { ...state, selectedInventory: action.selectedItemList }
    }
    case constants.LOAD_SUPPLEMENT_DELIVERY_DAYS: {
      return { ...state, supplementDeliveryDays: action.supplementDeliveryDays }
    }
    case constants.LOAD_SUPPLEMENT_ORDER_HISTORY: {
      return { ...state, supplementOrderHistory: action.supplementOrderHistory }
    }
    default:
      return state;
  }
}