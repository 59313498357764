export const CORE_SET_LOCALE_INITIALIZED  = 'CORE_SET_LOCALE_INITIALIZED';
export type CORE_SET_LOCALE_INITIALIZED = typeof CORE_SET_LOCALE_INITIALIZED;

export const CORE_SET_FEATURE_REG_INITIALIZED  = 'CORE_SET_FEATURE_REG_INITIALIZED';
export type CORE_SET_FEATURE_REG_INITIALIZED = typeof CORE_SET_FEATURE_REG_INITIALIZED;

export const CORE_SET_APP_INITIALIZED  = 'CORE_SET_APP_INITIALIZED';
export type CORE_SET_APP_INITIALIZED = typeof CORE_SET_APP_INITIALIZED;

export const CORE_SET_AUTH_INITIALIZED  = 'CORE_SET_AUTH_INITIALIZED';
export type CORE_SET_AUTH_INITIALIZED = typeof CORE_SET_AUTH_INITIALIZED;

export const CORE_SET_F45_INITIALIZED  = 'CORE_SET_F45_INITIALIZED';
export type CORE_SET_F45_INITIALIZED = typeof CORE_SET_F45_INITIALIZED;

export const CORE_SET_TENANTS_LOADED  = 'CORE_SET_TENANTS_LOADED';
export type CORE_SET_TENANTS_LOADED = typeof CORE_SET_TENANTS_LOADED;

export const CORE_SET_CURRENT_TENANT  = 'CORE_SET_CURRENT_TENANT';
export type CORE_SET_CURRENT_TENANT = typeof CORE_SET_CURRENT_TENANT;

export const CORE_SET_ALL_TENANTS  = 'CORE_SET_ALL_TENANTS';
export type CORE_SET_ALL_TENANTS = typeof CORE_SET_ALL_TENANTS;

export const CORE_SET_AUTH  = 'CORE_SET_AUTH';
export type CORE_SET_AUTH = typeof CORE_SET_AUTH;

export const CORE_CLEAR_AUTH  = 'CORE_CLEAR_AUTH';
export type CORE_CLEAR_AUTH = typeof CORE_CLEAR_AUTH;

export const CORE_UPDATE_AUTH_USER  = 'CORE_UPDATE_AUTH_USER';
export type CORE_UPDATE_AUTH_USER = typeof CORE_UPDATE_AUTH_USER;

export const CORE_SET_MAINTENANCE_MODE  = 'CORE_SET_MAINTENANCE_MODE';
export type CORE_SET_MAINTENANCE_MODE = typeof CORE_SET_MAINTENANCE_MODE;