import * as React from 'react';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';

import './spinner.scss';
import { fff_brand_green } from '../../../../colors';

const style = css`
    display: block;
    margin: 0 auto;
`;

export interface Props {
  show: boolean;
}

const GlobalSpinner = ({ show }: Props) => {
  if (!show)
    return null;

  return (
    <div className='spinner-panel'>
      <div className="spinner">
      <PulseLoader
        css={style}
        sizeUnit={"px"}
        size={15}
        color={fff_brand_green}
        loading={show}
      />
       </div>
    </div>
  );
}

export default GlobalSpinner