const FALLBACK_BASE_URL = "http://fff.core.daily3/api/v1";
const DEFAULT_STRIPE_API_KEY = "DEFAULT_STRIPE_API_KEY";
const DEFAULT_PAYPAL_CLIENT_ID = "sb";
const DEFAULT_GET_ADDRESS_API_URL = "https://api.getAddress.io/";
const DEFAULT_WEB_BASE_URL = document.location.origin;
const DEFAULT_GOOGLE_TAG_MANAGER_ID = "";
const DEFAULT_SENTRY_DSN =
  "https://75aba0b583b5415bbf8208d91f595267@sentry.io/2094759";
const BLOG_URL = "https://blog.freshfitnessfood.com";
const SHOP_URL = "https://supplements.freshfitnessfood.com/";

// TODO Change Prod Env Google Tag Manager Id
interface ApiConfig {
  BASE_URL: string;
  MEDIA_URL: string;
}

interface GtmConfig {
  GOOGLE_TAG_MANAGER_ID: string;
}

interface WebConfig {
  BASE_URL: string;
  BLOG_URL: string;
  SHOP_URL: string;
  SINGLE_TENANT_MODE?: string;
}

interface PaymentConfig {
  STRIPE_API_KEY: string,
  PAYPAL_CLIENT_ID: string
  CKO_BESPOKE_PUBLIC_KEY: string;
  CKO_FROZEN_RANGE_PUBLIC_KEY: string;
  CKO_TOGO_PUBLIC_KEY: string;
}

interface AddressConfig {
  GET_ADDRESS_API_URL: string;
  GET_ADDRESS_API_KEY: string;
}

interface SentryConfig {
  DSN: string;
}

interface SocialSignInConfig {
  googleClientId?: string;
  facebookAppId?: string;
  appleClientId?: string;
  enable: boolean;
}

interface Config {
  api: ApiConfig;
  payment: PaymentConfig;
  address: AddressConfig;
  web: WebConfig;
  gtm: GtmConfig;
  sentry: SentryConfig;
  socialSignIn: SocialSignInConfig;
  environment: string;
}

export const config: Config = {
  api: {
    BASE_URL: process.env.REACT_APP_BASE_URL || FALLBACK_BASE_URL,
    MEDIA_URL: process.env.REACT_APP_MEDIA_URL || FALLBACK_BASE_URL,
  },
  payment: {
    STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY || DEFAULT_STRIPE_API_KEY,
    PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID || DEFAULT_PAYPAL_CLIENT_ID,
    CKO_BESPOKE_PUBLIC_KEY: process.env.REACT_APP_CKO_BESPOKE_PUBLIC_KEY || '',
    CKO_FROZEN_RANGE_PUBLIC_KEY: process.env.REACT_APP_CKO_FROZEN_RANGE_PUBLIC_KEY || '',
    CKO_TOGO_PUBLIC_KEY: process.env.REACT_APP_CKO_TOGO_PUBLIC_KEY || ''
  },
  address: {
    GET_ADDRESS_API_URL: DEFAULT_GET_ADDRESS_API_URL,
    GET_ADDRESS_API_KEY: process.env.REACT_APP_GET_ADDRESS_API_KEY || "",
  },
  web: {
    BASE_URL: process.env.REACT_APP_WEB_BASE_URL || DEFAULT_WEB_BASE_URL,
    BLOG_URL: BLOG_URL,
    SHOP_URL: SHOP_URL,
    SINGLE_TENANT_MODE: process.env.REACT_APP_SINGLE_TENANT_MODE
  },
  gtm: {
    GOOGLE_TAG_MANAGER_ID:
      process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ||
      DEFAULT_GOOGLE_TAG_MANAGER_ID,
  },
  sentry: {
    DSN: process.env.REACT_APP_SENTRY_DSN || DEFAULT_SENTRY_DSN,
  },
  socialSignIn: {
    googleClientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
    facebookAppId: process.env.REACT_APP_FACEBOOK_AUTH_APP_ID,
    appleClientId: process.env.REACT_APP_APPLE_CLIENT_ID,
    enable: (process.env.REACT_APP_ENABLE_SOCIAL_SIGN_IN || 'false').toLocaleLowerCase() === 'true'
  },
  environment: process.env.REACT_APP_ENVIRONMENT || '',
};
