// This Service is for Future Use
// Move all the yup validation schemas here
import * as intl from 'react-intl-universal';
import * as Yup from 'yup';
import { CUSTOM_OPTIONS_MEAL_COUNT, MAX_HEIGHT_CM, MAX_HEIGHT_FT, MAX_WEIGHT_KG, MAX_WEIGHT_LBS, MIN_AGE, MIN_HEIGHT_CM, MIN_HEIGHT_FT, MIN_WEIGHT_KG, MIN_WEIGHT_LBS, REGEX } from '../../constants';
import { sessionService } from '../../services/shared/session.service';
import { MATERNITY_STATUS } from '../../store/order/order.constants';
import { WaiverType } from '../../types/discount-code.types';
import { Order, OrderStaging } from '../../types/order-store.types';
import { PaymentPlan } from './../../types/fff.enums';

// handle on blur validation
async function validateOrderFormField(schema: any, fieldPathName: string, fieldValue: any) {
  try {
    await Yup.reach(schema.schema, fieldPathName).validate(fieldValue, { abortEarly: schema.abortEarly || false });
    return null;
  }
  catch (e) {
    return e.message
  }
}

// handle on next clicked
async function validateObjectForSpecifiedPath(schema: any, fieldPathName: string, orderStaging: OrderStaging) {
  let errorMessage;
  const validationSchema: Yup.ObjectSchema<any> = schema.schema;

  if (validationSchema) {
    try {
      await validationSchema.validate(orderStaging, { abortEarly: false });
    } catch (error) {
      errorMessage = '';
      if (error.inner) {
        error.inner.forEach((e: Yup.ValidationError) => {
          e.path === fieldPathName &&
            (errorMessage = e.message);
        });
      }
    }
  }
  return errorMessage;
}

// handle on next clicked
async function validateOrder(schema: any, orderStaging: OrderStaging | Order) {
  let isValid = true;
  const validationSchema: Yup.ObjectSchema<any> = schema.schema;
  if (validationSchema) {
    try {
      await validationSchema.validate(orderStaging, { abortEarly: schema.abortEarly || false });
    } catch (error) {
      isValid = false;
    }
  }

  return isValid;
}

// handle validation
async function validateField(schema: Yup.Schema<any>, fieldPathName: string, fieldValue: any) {
  try {
    await Yup.reach(schema, fieldPathName).validate(fieldValue);
    return null;
  }
  catch (e) {
    return e.message
  }

}

async function validateObject(schema: Yup.Schema<any>, object: any) {
  try {
    await schema.validate(object);
    return true;
  } catch (error) {
    return false;
  }
}

function validateObjectSync(schema: Yup.Schema<any>, object: any) {
  try {
    schema.validateSync(object);
    return true;
  } catch (error) {
    return false;
  }
}

export const validationService = {
  validateOrderFormField,
  validateOrder,
  validateObjectForSpecifiedPath,
  validateField,
  validateObject,
  validateObjectSync
}

export function loadLocalizedErrorMessage(code: string, values: any = {}) {
  return (params: any): string => {
    return intl.get(code, values);
  }
}

export const heightWeightValidationSchema = Yup.object().shape({
  heightInCm: Yup.number()
    .min(MIN_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MIN_IN_CM'))
    .max(MAX_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MAX_IN_CM'))
    .positive(loadLocalizedErrorMessage('ERR_HEIGHT_MUST_BE_POSITIVE'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  heightInFt: Yup.number()
    .min(MIN_HEIGHT_FT, loadLocalizedErrorMessage('ERR_HEIGHT_MIN_VALUE_IN_FT'))
    .max(MAX_HEIGHT_FT, loadLocalizedErrorMessage('ERR_HEIGHT_MAX_VALUE_IN_FT'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  weightInKg: Yup.number()
    .min(MIN_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MIN_WEIGHT_VALUE_IN_KG'))
    .max(MAX_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MAX_WEIGHT_VALUE_IN_KG'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  weightInLbs: Yup.number()
    .min(MIN_WEIGHT_LBS, loadLocalizedErrorMessage('ERR_MIN_WEIGHT_VALUE_IN_LBS'))
    .max(MAX_WEIGHT_LBS, loadLocalizedErrorMessage('ERR_MAX_WEIGHT_VALUE_IN_LBS'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
});

export const contactDetailsHeightWeightValidationSchema = Yup.object().shape({
  heightInCm: Yup.number()
    .min(MIN_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MIN_IN_CM', { value: MIN_HEIGHT_CM }))
    .max(MAX_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MAX_IN_CM', { value: MAX_HEIGHT_CM }))
    .typeError(loadLocalizedErrorMessage('ERR_HEIGHT_REQUIRED'))
    .required(loadLocalizedErrorMessage('ERR_HEIGHT_REQUIRED')),
  heightInFt: Yup.number()
    .min(MIN_HEIGHT_FT, loadLocalizedErrorMessage('ERR_HEIGHT_MIN_VALUE_IN_FT'))
    .max(MAX_HEIGHT_FT, loadLocalizedErrorMessage('ERR_HEIGHT_MAX_VALUE_IN_FT'))
    .typeError(loadLocalizedErrorMessage('ERR_HEIGHT_REQUIRED'))
    .required(loadLocalizedErrorMessage('ERR_HEIGHT_REQUIRED')),
  weightInKg: Yup.number()
    .min(MIN_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MIN_WEIGHT_VALUE_IN_KG'))
    .max(MAX_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MAX_WEIGHT_VALUE_IN_KG'))
    .typeError(loadLocalizedErrorMessage('ERR_WEIGHT_REQUIRED'))
    .required(loadLocalizedErrorMessage('ERR_WEIGHT_REQUIRED')),
  weightInLbs: Yup.number()
    .min(MIN_WEIGHT_LBS, loadLocalizedErrorMessage('ERR_MIN_WEIGHT_VALUE_IN_LBS'))
    .max(MAX_WEIGHT_LBS, loadLocalizedErrorMessage('ERR_MAX_WEIGHT_VALUE_IN_LBS'))
    .typeError(loadLocalizedErrorMessage('ERR_WEIGHT_REQUIRED'))
    .required(loadLocalizedErrorMessage('ERR_WEIGHT_REQUIRED')),
});

export const birthDateValidationSchema = Yup.object().shape({
  birthDay: Yup.date().max(getCurrentDateBeforeNoOfYears(MIN_AGE), loadLocalizedErrorMessage('ERR_AGE_LIMIT'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
});

const postCodePromptSchema = {
  schema: Yup.object().shape({
    postcode: Yup.string()
      .nullable(true)
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
  })
}

const userPromptSchema = {
  schema: Yup.object().shape({
    firstName: Yup.string()
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .matches(REGEX.CHARACTERS, loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .max(100, 'Max length'),
    lastName: Yup.string()
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .matches(REGEX.CHARACTERS, loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .max(100, 'Max length'),
    email: Yup.string()
      .email(loadLocalizedErrorMessage('ERR_INVALID_EMAIL'))
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .max(256, 'Max Length')
  }), abortEarly: true
}

const goalPromptSchema = {
  schema: Yup.object().shape({
    goalId: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
  })
}

const nutritionPreferenceSchema = {
  schema: Yup.object().shape({
    nutritionPreferenceId: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
  })
}

const isSessionUser = sessionService.getAuthState() ? true : false

// can add generic validation schemas that can be used in multiple places
export const commonValidationSchema = {
  shortString: Yup.string()
    .max(50, loadLocalizedErrorMessage('ERR_TEXT_TOO_LONG'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  longString: Yup.string()
    .max(200, loadLocalizedErrorMessage('ERR_TEXT_TOO_LONG'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  string: Yup.string()
    .trim()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  email: Yup.string()
    .max(200, loadLocalizedErrorMessage('ERR_TEXT_TOO_LONG'))
    .email(loadLocalizedErrorMessage('ERR_INVALID_EMAIL'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  phoneNumber: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .min(7, loadLocalizedErrorMessage('ERR_INVALID_PHONE_NUMBER'))
    .matches(REGEX.PHONE_NUMBER_INPUT, loadLocalizedErrorMessage('ERR_INVALID_PHONE_NUMBER')),
  customerName: Yup.object().shape({
    firstName: Yup.string()
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .matches(REGEX.CHARACTERS, loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .max(100, 'Max length'),
    lastName: Yup.string()
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .matches(REGEX.CHARACTERS, loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .max(100, 'Max length'),
  }),
  weightInKg: Yup.number()
    .min(MIN_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MIN_WEIGHT_VALUE_IN_KG'))
    .max(MAX_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MAX_WEIGHT_VALUE_IN_KG'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  heightInCm: Yup.number()
    .min(MIN_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MIN_IN_CM', { value: MIN_HEIGHT_CM }))
    .max(MAX_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MAX_IN_CM', { value: MAX_HEIGHT_CM }))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
}

const userDetailSchema = {
  schema: Yup.object().shape({
    genderId: Yup.string().typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')).required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    phoneNumber: commonValidationSchema.phoneNumber
      .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    referral: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    referralName: Yup.string().when('referral', {
      is: (val) => val &&
        val !== 'ONLINE_SEARCH' &&  // These constants has to change with ORDER_FORM_REFERRAL_DATA updates
        val !== 'SOCIAL_MEDIA' && // These constants has to change with ORDER_FORM_REFERRAL_DATA updates
        val !== 'FLYER' && // These constants has to change with ORDER_FORM_REFERRAL_DATA updates
        val !== 'APP_ADVERTISEMENT' &&
        val !== 'APP_STORE' &&
        val !== 'FFF_STAFF_MEMBER' &&
        val !== 'OTHER' &&  // These constants has to change with ORDER_FORM_REFERRAL_DATA updates
        !isSessionUser,
      then: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')).matches(REGEX.CHARACTERS, loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
      otherwise: Yup.string().notRequired().nullable(true)
    })
  }).concat(heightWeightValidationSchema)
    .concat(birthDateValidationSchema),
  abortEarly: true
}

const pregnancyStateSchema = {
  schema: Yup.object().shape({
    maternityStatusId: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
  })
}

const pregnancyPeriodSchema = {
  schema: Yup.object({
    pregnancyStatusId: Yup.string().when('maternityStatus', {
      is: val => val == MATERNITY_STATUS.PREGNANT,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired().nullable(true)
    })
  })
}

const breastfeedingStateSchema = {
  schema: Yup.object({
    breastfeedingStatusId: Yup.string().when('maternityStatus', {
      is: val => val == MATERNITY_STATUS.BREASTFEEDING,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired().nullable(true)
    })
  })
}

const userDetailUpdateSchema = {
  schema: Yup.object().shape({
    genderId: Yup.string().typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')).required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    heightInFt: Yup.number()
      .min(MIN_HEIGHT_FT, loadLocalizedErrorMessage('ERR_HEIGHT_MIN_VALUE_IN_FT'))
      .max(MAX_HEIGHT_FT, loadLocalizedErrorMessage('ERR_HEIGHT_MAX_VALUE_IN_FT'))
      .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    weightInLbs: Yup.number()
      .min(MIN_WEIGHT_LBS, loadLocalizedErrorMessage('ERR_MIN_WEIGHT_VALUE_IN_LBS'))
      .max(MAX_WEIGHT_LBS, loadLocalizedErrorMessage('ERR_MAX_WEIGHT_VALUE_IN_LBS'))
      .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    weightInKg: Yup.number()
      .min(MIN_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MIN_WEIGHT_VALUE_IN_KG'))
      .max(MAX_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MAX_WEIGHT_VALUE_IN_KG'))
      .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    heightInCm: Yup.number()
      .positive(loadLocalizedErrorMessage('ERR_HEIGHT_MUST_BE_POSITIVE'))
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .min(MIN_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MIN_IN_CM'))
      .max(MAX_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MAX_IN_CM')),
    birthDay: Yup.date().max(getCurrentDateBeforeNoOfYears(MIN_AGE), loadLocalizedErrorMessage('ERR_AGE_LIMIT'))
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .typeError(loadLocalizedErrorMessage('ERR_INVALID_DATE')),
    phoneNumber: commonValidationSchema.phoneNumber,
  }),
  abortEarly: true
}

const deliveryAddressSchema = {
  schema: Yup.object().shape({
    deliveryInfoStaging: Yup.object().shape({
      displayName: Yup.string()
        .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
        .test('characterLimit', loadLocalizedErrorMessage('ERR_DISPLAY_NAME_CHARACTER_LIMIT'), val => val.length <= 10),
      instruction: Yup.string()
        .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
        .matches(REGEX.CHARACTERS, (loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')))
    }),
    deliveryAddress: Yup.object().shape({
      line1: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
      city: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    }).nullable().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    deliveryLocationImageUrl: Yup.string()
      .when('onDeliveryLocationImageUploading', {
        is: (val) => val,
        then: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')).nullable(false),
        otherwise: Yup.string().notRequired().nullable(true)
      }),
    isTimedDelivery: Yup.string().required()
  }),
  abortEarly: true
}

const DeliveryScheduleDaySchema = {
  schema: Yup.object().shape({
    totalBagCountPerWeek: Yup.number()
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
      .moreThan(0, loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
  })
}

function getCurrentDateBeforeNoOfYears(noOfYears: number) {
  let date = new Date();
  date.setFullYear(date.getFullYear() - noOfYears);

  return date;
}

const mealManagementSchema = {
  schema: Yup.object({
    mealsPerday: Yup.number().required().min(2),
    mealExtrasIds: Yup.array().when(['mealsPerday', 'promotionalCodeId'], {
      is: (mealsPerday, promotionalCodeId) => mealsPerday == CUSTOM_OPTIONS_MEAL_COUNT && !!promotionalCodeId,
      then: Yup.array().min(2).required()
    }),
    customMealOptionId: Yup.string().when('mealsPerday', {
      is: val => val == CUSTOM_OPTIONS_MEAL_COUNT,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired()
    })
  })
}

const exerciseHabitSchema = {
  schema: Yup.object({
    workoutHoursPerWeekId: Yup.string().when('isWorkingOut', {
      is: (val) => val === true,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired()
    })
  }),
  abortEarly: true
}

const packageSelectionSchema = {
  schema: Yup.object({
    packageId: Yup.string().when('paymentPlanOption', {
      is: (val: PaymentPlan) => val == PaymentPlan.Commit,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired()
    }),
  })
}

const paymentPlanSelectionSchema = {
  schema: Yup.object({
    paymentPlan: Yup.string().required()
  })
}

const activeLevelSchema = {
  schema: Yup.object().shape({
    activeLevelId: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
  })
}

const orderSummarySchema = {
  schema: Yup.object().shape({
    isTermsAndConditionsChecked: Yup.boolean().oneOf([true], "works")
  })
}

const billingAddressSchema = {
  schema: Yup.object().shape({
    billingAddress: Yup.object({
      postCode: Yup.string().required('Required'),
      line1: Yup.string().required('Required'),
      line2: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      country: Yup.string().required('Required')
    })
  }),
  abortEarly: true
}

export const orderFromValidationSchemas = {
  postCodePromptSchema,
  userPromptSchema,
  nutritionPreferenceSchema,
  userDetailSchema,
  deliveryAddressSchema,
  DeliveryScheduleDaySchema,
  mealManagementSchema,
  exerciseHabitSchema,
  packageSelectionSchema,
  paymentPlanSelectionSchema,
  activeLevelSchema,
  orderSummarySchema,
  billingAddressSchema,
  goalPromptSchema,
  userDetailUpdateSchema,
  pregnancyStateSchema,
  pregnancyPeriodSchema,
  breastfeedingStateSchema
}

export const customerCreditUpdateSchema = {
  schema: Yup.object().shape({
    reason: Yup.string()
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    amount: Yup.number()
      .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
  })
}


export const customerDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIRST_NAME'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIRST_NAME'))
    .max(100, 'MAX length'),
  lastName: Yup.string()
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_LAST_NAME'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_LAST_NAME'))
    .max(100, 'MAX length'),
  email: Yup.string()
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .email(loadLocalizedErrorMessage('ERR_INVALID_EMAIL'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .max(256, 'Max Length'),
  dateOfBirth: Yup.date()
    .max(getCurrentDateBeforeNoOfYears(MIN_AGE), loadLocalizedErrorMessage('ERR_AGE_LIMIT'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  phoneNumber: commonValidationSchema.phoneNumber
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  genderId: Yup.string()
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  weightInKg: Yup.number()
    .min(MIN_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MIN_WEIGHT_VALUE_IN_KG'))
    .max(MAX_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MAX_WEIGHT_VALUE_IN_KG'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  heightInCm: Yup.number()
    .positive(loadLocalizedErrorMessage('ERR_HEIGHT_MUST_BE_POSITIVE'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .min(MIN_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MIN_IN_CM'))
    .max(MAX_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MAX_IN_CM')),
});

export const f45OrderValidationSchema = (minCals: number, maxCals: number) => Yup.object().shape({
  email: Yup.string()
    .email(loadLocalizedErrorMessage('ERR_INVALID_EMAIL'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .max(256, 'Max Length'),
  postCode: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  dailyCalories: Yup.number()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .min(minCals, `Minimum calorie amount: ${minCals}`)
    .max(maxCals, `Maximum calorie amount: ${maxCals}`),
  firstName: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .max(100, 'MAX length'),
  lastName: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .max(100, 'MAX length'),
  phoneNumber: commonValidationSchema.phoneNumber
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  deliveryAddressString: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  displayName: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .test('characterLimit', loadLocalizedErrorMessage('ERR_DISPLAY_NAME_CHARACTER_LIMIT'), val => val.length <= 10),
  instruction: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  billingAddress: Yup.object().default(null).nullable().shape({
    line1: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    line2: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    city: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    country: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    postCode: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  }),
  studio: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  isAcknowledged1: Yup.boolean().oneOf([true], loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  isAcknowledged2: Yup.boolean().oneOf([true], loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
});

export const userDetailsValidationSchema = Yup.object({
  email: Yup.string()
    .email(loadLocalizedErrorMessage('ERR_INVALID_EMAIL'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .max(256, 'Max Length'),
  firstName: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .max(100, 'MAX length'),
  lastName: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .max(100, 'MAX length'),
  genderId: Yup.string()
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  weightInKg: Yup.number()
    .min(MIN_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MIN_WEIGHT_VALUE_IN_KG'))
    .max(MAX_WEIGHT_KG, loadLocalizedErrorMessage('ERR_MAX_WEIGHT_VALUE_IN_KG'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  heightInCm: Yup.number()
    .positive(loadLocalizedErrorMessage('ERR_HEIGHT_MUST_BE_POSITIVE'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .min(MIN_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MIN_IN_CM'))
    .max(MAX_HEIGHT_CM, loadLocalizedErrorMessage('ERR_HEIGHT_LIMIT_MAX_IN_CM')),
  birthDay: Yup.date().max(getCurrentDateBeforeNoOfYears(MIN_AGE), loadLocalizedErrorMessage('ERR_AGE_LIMIT'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  phoneNumber: commonValidationSchema.phoneNumber
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
});

export const affiliateValidationSchema = Yup.object({
  businessName: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
});


export const userLoginValidation = Yup.object({
  username: Yup.string()
    .max(200, loadLocalizedErrorMessage('ERR_TEXT_TOO_LONG'))
    .email(loadLocalizedErrorMessage('ERR_INVALID_EMAIL'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  password: Yup.string()
    .max(200, loadLocalizedErrorMessage('ERR_TEXT_TOO_LONG'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
});

export const addFreeBagsValidation = Yup.object().shape({
  freeBagCount: Yup.number()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .typeError('Please add numeric values only')
    .min(1, 'minimum bag count should be 1'),
  reason: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  departmentId: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
});

export const discountCodeValidationSchema = Yup.object().shape({
  code: Yup.string()
    .max(20)
    .required()
    .matches(
      REGEX.DISCOUNT_CODE_ADMIN_INPUT,
      loadLocalizedErrorMessage("ERR_INVALID_DISCOUNT_CODE")
    ),
  loyaltyTokensOffer: Yup.number().required(),
  discountCategory: Yup.string(),
  maxUsageCount: Yup.number().when("discountCategory", {
    is: undefined,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required().moreThan(0)
  }),
  waiverType: Yup.number(),
  discountAmount: Yup.number().when("waiverType", {
    is: WaiverType.Amount,
    then: Yup.number().required().moreThan(0),
    otherwise: Yup.number().notRequired()
  }),
  discountPercentage: Yup.number().when("waiverType", {
    is: WaiverType.Percentage,
    then: Yup.number().required().moreThan(0).max(100),
    otherwise: Yup.number().notRequired()
  }),
  foodBoxDiscountValue: Yup.number()
    .when("waiverType", {
      is: WaiverType.Amount,
      then: Yup.number().required().moreThan(0),
      otherwise: Yup.number().notRequired()
    })
    .when("waiverType", {
      is: WaiverType.Percentage,
      then: Yup.number().required().min(1).max(100),
      otherwise: Yup.number().notRequired()
    })
});

export const frozenFoodsUserDetailsValidationSchema = Yup.object({
  email: Yup.string()
    .email(loadLocalizedErrorMessage('ERR_INVALID_EMAIL'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .max(256, 'Max Length'),
  firstName: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .max(100, 'MAX length'),
  lastName: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .max(100, 'MAX length'),
  phoneNumber: commonValidationSchema.phoneNumber
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  address: Yup.object().shape({
    line1: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    city: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    country: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
    postCode: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  }),
  postcodeGroupId: Yup.string()
    .nullable(true)
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  deliveryInstructions: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .matches(REGEX.CHARACTERS, (loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')))
});

export const AddCreditWithAdditionalDetailsValidationSchema = Yup.object().shape({
  reason: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  amount: Yup.number()
    .typeError(loadLocalizedErrorMessage('ERR_NUMBER_TYPE_ERROR'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .moreThan(0, loadLocalizedErrorMessage('ERR_NUMBER_GREATER_THAN', { value: 0 })),
  departmentId: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  additionalDetail: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
});

export const AddCreditValidationSchema = Yup.object().shape({
  reason: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  amount: Yup.number()
    .typeError(loadLocalizedErrorMessage('ERR_NUMBER_TYPE_ERROR'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .moreThan(0, loadLocalizedErrorMessage('ERR_NUMBER_GREATER_THAN', { value: 0 })),
  departmentId: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
});

export const toGoMealValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  code: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  sortOrder: Yup.number()
    .nullable(true)
    .integer(loadLocalizedErrorMessage('ERR_NUMBER_NO_DECIMAL_VALUES'))
    .min(0, loadLocalizedErrorMessage('ERR_NUMBER_NO_NEGATIVE_VALUES'))
    .max(99, loadLocalizedErrorMessage('ERR_NUMBER_LESS_THAN_OR_EQUAL', { value: 99 }))
    .typeError(loadLocalizedErrorMessage('ERR_NUMBER_TYPE_ERROR')),
  recipes: Yup.array()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .of(
      Yup.object().shape({
        recipeId: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
        defaultQuantity: Yup.number()
          .integer(loadLocalizedErrorMessage('ERR_NUMBER_NO_DECIMAL_VALUES'))
          .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
          .min(0, loadLocalizedErrorMessage('ERR_NUMBER_NO_NEGATIVE_VALUES'))
          .typeError(loadLocalizedErrorMessage('ERR_NUMBER_TYPE_ERROR')),
      }))
});

export const toGoExtraValidationSchema = Yup.object().shape({
  sortOrder: Yup.number()
    .nullable(true)
    .integer(loadLocalizedErrorMessage('ERR_NUMBER_NO_DECIMAL_VALUES'))
    .min(0, loadLocalizedErrorMessage('ERR_NUMBER_NO_NEGATIVE_VALUES'))
    .max(99, loadLocalizedErrorMessage('ERR_NUMBER_LESS_THAN_OR_EQUAL', { value: 99 }))
    .typeError(loadLocalizedErrorMessage('ERR_NUMBER_TYPE_ERROR')),
  recipeId: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
});

export const addressValidationSchema = {
  schema: Yup.object().shape({
    id: Yup.string().nullable(true).notRequired(),
    line1: Yup.string().required("required"),
    line2: Yup.string().required("required"),
    city: Yup.string().required("required"),
    county: Yup.string(),
    country: Yup.string().required("required"),
    postCode: Yup.string(),
  })
}
export const salesTargetValidationSchema = Yup.object().shape({
  targetMonth: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .typeError(loadLocalizedErrorMessage('ERR_NUMBER_TYPE_ERROR')),
  targetAmount: Yup.number()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .min(1, loadLocalizedErrorMessage('ERR_NUMBER_NO_NEGATIVE_VALUES'))
    .typeError(loadLocalizedErrorMessage('ERR_NUMBER_TYPE_ERROR'))
});

export const extraValidationSchema = Yup.object().shape({
  recipeId: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
});

export const toGoEventValidationSchema = Yup.object().shape({
  name: Yup.string().required(intl.get('ERR_REQUIRED_FIELD')),
  eventRequirements: Yup.string().required(intl.get('ERR_REQUIRED_FIELD'))
});

export const supplementValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  code: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  sortOrder: Yup.number()
    .nullable(true)
    .integer(loadLocalizedErrorMessage('ERR_NUMBER_NO_DECIMAL_VALUES'))
    .min(0, loadLocalizedErrorMessage('ERR_NUMBER_NO_NEGATIVE_VALUES'))
    .max(99, loadLocalizedErrorMessage('ERR_NUMBER_LESS_THAN_OR_EQUAL', { value: 99 }))
    .typeError(loadLocalizedErrorMessage('ERR_NUMBER_TYPE_ERROR')),
  unitPrice: Yup.number()
    .nullable(true)
    .min(0, loadLocalizedErrorMessage('ERR_NUMBER_NO_NEGATIVE_VALUES'))
    .typeError(loadLocalizedErrorMessage('ERR_NUMBER_TYPE_ERROR')),
  recipes: Yup.array()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .of(
      Yup.object().shape({
        recipeId: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
      }))
});

export const lookupsValidationSchema = Yup.object().shape({
  code: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .matches(REGEX.CODE_INPUT, loadLocalizedErrorMessage("ERR_INVALID_CODE")),

});

export const orderMetadataValidationSchema = Yup.object().shape({
  code: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  fallbackOrderMetadata: Yup.object().shape({
    id: Yup.string(),
    code: Yup.string()
  })
});

export const staticContentManagementValidationSchema = Yup.object().shape({
  code: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  type: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
});

export const editDeliveryDetailsValidationSchema = Yup.object().shape({
  postcode: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  deliveryAddressText: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  displayName: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .test('characterLimit', loadLocalizedErrorMessage('ERR_DISPLAY_NAME_CHARACTER_LIMIT'), val => val.length <= 10),
  deliveryInstructions: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .matches(REGEX.CHARACTERS, (loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))),
  phoneNumber: commonValidationSchema.phoneNumber,
});

export const postcodeValidationSchema = Yup.object().shape({
  postcode: Yup.string()
    .nullable(true)
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .min(4, loadLocalizedErrorMessage('ERR_INVALID_POSTCODE'))
});

export const clientOfferValidationSchema = Yup.object().shape({
  code: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  bannerTitleText: Yup.string().required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
});

export const tokenCashOutValidationSchema = (maxTokens: number, minTokens: number) => Yup.object().shape({
  tokenAmount: Yup.number()
    .max(maxTokens, loadLocalizedErrorMessage('ERR_MAX_AMOUNT_IS', { value: maxTokens }))
    .min(minTokens, loadLocalizedErrorMessage('ERR_MIN_AMOUNT_IS', { value: minTokens }))
    .typeError(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
});

export const bankDetailsValidationSchema = Yup.object().shape({
  accountName: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  accountNo: Yup.string()
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD')),
  sortCode: Yup.string()
    .matches(REGEX.BANK_SORT_CODE, loadLocalizedErrorMessage('ERR_INCORRECT_SORTCODE_FORMAT'))
    .required(loadLocalizedErrorMessage('ERR_REQUIRED_FIELD'))
});