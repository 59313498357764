import { LookupState, Lookup } from "../../types/lookup-store.types";

export const LookupReducer = {
  lookup: lookupReducer
}

function lookupReducer(state: LookupState = { lookUps: [] }, action: LookupActions) {
  switch (action.type) {
    case LOAD_LOOKUPS: {
      return {
        ...state,
        lookUps: action.lookUps
      }
    }
  }

  return state;
}


export const LOAD_LOOKUPS = 'LOAD_LOOKUPS';
export type LOAD_LOOKUPS = typeof LOAD_LOOKUPS;

export interface LoadLookups {
  type: LOAD_LOOKUPS,
  lookUps: Lookup[]
}
export type LookupActions = LoadLookups;