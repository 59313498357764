import { TenantConfigurationMap } from './../../types/tenant.types';
import { AuthResult } from "../../types/account-dto.types";
import * as permissions from "../../types/permission-constants";
import { AuthState } from "../../types/shared-store.types";
import { User } from "../../types/user-store.types";
import { storageService } from "./storage.service";



export const sessionService = {
  getAuthToken,
  getRefreshToken,
  setAuthState,
  getAuthState,
  clearAuthState,
  setAuthTokens,
  updateAuthStateUser,
  setTenantCode,
  getTenantCode,
  setTenantConfiguration,
  getTenantConfiguration,
  clearTenantCode,
  setPortalType,
  getPortalType,
  /****************************
  removeTemporaryAuthState,
  These methods are obsolete. Instead using them, consider to use withAuth HOC to access
  session user related information
  *****************************/
  hasPermission_obsolete,
  isCustomer_obsolete,
  getUserId_obsolete,
};

let PORTAL_TYPE = '';

const KEY_SESSION_USER = "SESSION_USER";
const KEY_TENANT_CODE = "TENANT_CODE";
const KEY_TENANT_CONFIG = "TENANT_CONFIG";
const KEY_AUTH_TOKEN = "AUTH_TOKEN";
const KEY_REFRESH_TOKEN = "KEY_REFRESH_TOKEN";

function setTenantCode(code: string) {
  if (code) storageService.setItem(KEY_TENANT_CODE, code);
  else storageService.removeItem(KEY_TENANT_CODE);
}

function getTenantCode() {
  const code = storageService.getItem(KEY_TENANT_CODE);
  return code;
}

function clearTenantCode() {
  storageService.removeItem(KEY_TENANT_CODE);
}

function setTenantConfiguration(configs: TenantConfigurationMap) {
  if (configs) {
    let jsonData = JSON.stringify(configs);
    storageService.setItem(KEY_TENANT_CONFIG, jsonData);
  }
  else {
    storageService.removeItem(KEY_TENANT_CONFIG);
  }
}

function getTenantConfiguration() {
  var jsonData = storageService.getItem(KEY_TENANT_CONFIG);
  const configs = jsonData != null ? (JSON.parse(jsonData) as TenantConfigurationMap) : undefined;
  return configs;
}

function setAuthState(authResult: AuthResult) {
  authResult.defaultTenant && setTenantCode(authResult.defaultTenant);
  setAuthTokens(authResult);

  const authState = {
    permissions: authResult.permissions,
    user: authResult.user,
    isImpersonating: authResult.isImpersonating || false,
    defaultTenant: authResult.defaultTenant,
    subscribedTenants: authResult.subscribedTenants,
  };

  let jsonData = JSON.stringify(authState);
  storageService.setItem(KEY_SESSION_USER, jsonData);
}

function getAuthState() {
  var jsonData = storageService.getItem(KEY_SESSION_USER);
  const auth =
    jsonData != null ? (JSON.parse(jsonData) as AuthState) : undefined;
  return auth;
}

function clearAuthState() {
  storageService.removeItem(KEY_SESSION_USER);
  storageService.removeItem(KEY_AUTH_TOKEN);
  storageService.removeItem(KEY_REFRESH_TOKEN);
}

function setAuthTokens(authResult: AuthResult) {
  storageService.setItem(KEY_AUTH_TOKEN, authResult.authToken);
  storageService.setItem(KEY_REFRESH_TOKEN, authResult.refreshToken);
}

function updateAuthStateUser(updatedUser: User) {
  var auth = getAuthState();

  if (auth != null) {
    auth.user = updatedUser;
    let jsonData = JSON.stringify(auth);

    storageService.setItem(KEY_SESSION_USER, jsonData);
  }
}

function getAuthToken() {
  //return storageService.getItem(KEY_AUTH_TOKEN) || '';

  //TODO: Following logic is not required going forward as we are saving the auth token separately in
  //local storage for fast and easy access. Just keep this to cater the users just after the release
  //who already have the auth tokens inside AuthState object. Remove following code and leave only the
  //commented line above after some time from the prod release (after 1 month)

  const authToken = storageService.getItem(KEY_AUTH_TOKEN);
  if (authToken != null) return authToken;

  const auth = getAuthState();
  if (auth) return auth.authToken;

  return "";
}

function getRefreshToken() {
  //return storageService.getItem(KEY_REFRESH_TOKEN) || '';

  //TODO: Following logic is not required going forward as we are saving the auth token separately in
  //local storage for fast and easy access. Just keep this to cater the users just after the release
  //who already have the auth tokens inside AuthState object. Remove following code and leave only the
  //commented line above after some time from the prod release (after 1 month)

  const refreshToken = storageService.getItem(KEY_REFRESH_TOKEN);
  if (refreshToken != null) return refreshToken;

  const auth = getAuthState();
  if (auth) return auth.refreshToken;

  return "";
}

function setPortalType(type: string) {
  PORTAL_TYPE = type;
}

function getPortalType() {
  return PORTAL_TYPE;
}

function getPermissions_obsolete() {
  var auth = getAuthState();
  return auth != null ? auth.permissions : [];
}

function hasPermission_obsolete(permission: string) {
  var allPermissions = getPermissions_obsolete();
  var hasPermission = allPermissions.indexOf(permission) >= 0;
  return hasPermission;
}

function isCustomer_obsolete() {
  const auth = sessionService.getAuthState();
  const user = auth ? auth.user : null;
  return user && user.roleNames
    ? user.roleNames.includes(permissions.UserRoles.Customer)
    : false;
}

function getUserId_obsolete() {
  let auth = getAuthState();
  return auth && auth.user.id;
}
