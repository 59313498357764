import { apiService } from "./shared/api.service";
import {
  ListItemDto,
  SelectItemViewModel,
  EntitySaveResult,
} from "../types/shared-dto.types";
import { sessionService } from "./shared/session.service";
import { TenantListItem, Tenant, TenantSetting, TenantConfigurationMap, TenantBasicInfo } from "../types/tenant.types";
import { FeatureRegistry, FeatureSetting } from "../types/feature-store.types";

export const tenantService = {
  loadAvailableTenants,
  getSelectedTenant,
  loadAllTenants,
  loadDBServers,
  saveTenant,
  loadTenant,
  loadTenantSettings,
  saveTenantSettings,
  deleteTenantSettings,
  loadFeatureRegistry,
  saveFeatureSetting,
  getCheckoutTenantConfigurations,
  getStripeTenantConfigurations,
  getTenantConfigurations,
  setTenantLive
};

function loadAvailableTenants() {
  return apiService.get<ListItemDto[]>("Tenants", "Active/ListItems");
}

function loadAllTenants() {
  return apiService.get<TenantListItem[]>("Tenants");
}

function loadTenant(tenantId: string) {
  return apiService.get<Tenant[]>("Tenants", undefined, [tenantId]);
}

function loadTenantSettings(tenantId: string) {
  return apiService.get<Tenant[]>("Tenants", `${tenantId}/Settings`);
}

function deleteTenantSettings(tenantId: string, key: string) {
  return apiService.delete("Tenants", `${tenantId}/Settings`, [key]);
}

function saveTenantSettings(tenantId: string, setting: TenantSetting) {
  return apiService.post("Tenants", `${tenantId}/Settings`, setting);
}

function loadFeatureRegistry(tenantId: string) {
  return apiService.get<FeatureRegistry>(
    "Tenants",
    `${tenantId}/FeatureRegistry`
  );
}

function saveFeatureSetting(tenantId: string, feature: FeatureSetting) {
  return apiService.post("Tenants", `${tenantId}/FeatureRegistry`, feature);
}

function loadDBServers() {
  return apiService.get<SelectItemViewModel[]>("DevOps", "DbServers");
}

function saveTenant(tenant: Tenant) {
  if (!tenant.id) {
    return apiService.post<EntitySaveResult>(
      "Tenants",
      "register",
      tenant,
      undefined,
      undefined,
      true
    );
  } else {
    return apiService.put<EntitySaveResult>("Tenants", undefined, tenant);
  }
}

function setTenantLive(tenantId: string, live: boolean) {
  return apiService.patch('tenants', undefined, undefined, [ tenantId, 'setlive' ], { live });
}

function getSelectedTenant(allTenants: TenantBasicInfo[]) {
  const sessionTenant = sessionService.getTenantCode();
  if (sessionTenant && allTenants.some((t) => t.code.toUpperCase() === sessionTenant.toUpperCase()))
    return sessionTenant;

  const selectedTenant = allTenants.length ? allTenants.map((t) => t.code)[0] : 'NO_TENANT';
  return selectedTenant;
}

function getCheckoutTenantConfigurations() {
  return apiService.get<TenantConfigurationMap>("Tenants", "Configurations/Checkout");
}

function getStripeTenantConfigurations() {
  return apiService.get<TenantConfigurationMap>("Tenants", "Configurations/Stripe");
}

function getTenantConfigurations() {
  return apiService.get<TenantConfigurationMap>("Tenants", "Configurations/Tenant");
}
