import { TenantState } from "../../types/tenant.types";
import { TenantActions } from "./tenant.actions";
import * as constants from "./tenant.constants";

export const tenantsReducer = {
  tenants,
};

function tenants(state: TenantState = {}, action: TenantActions) {
  switch (action.type) {
    case constants.TENANT_LOAD_ALL: {
      return { ...state, tenants: action.tenants };
    }
    case constants.TENANT_DB_SERVERS: {
      return { ...state, dbServers: action.dbServers };
    }
    case constants.TENANT_LOAD_EDITING_TENANT: {
      return { ...state, editingTenant: action.tenant };
    }
    case constants.TENANT_LOAD_SETTINGS: {
      return { ...state, tenantSettings: action.settings };
    }
    case constants.TENANT_LOAD_FEATURE_REGISTRY: {
      return { ...state, featureRegistry: action.features };
    }
    default: {
      return state;
    }
  }
}
