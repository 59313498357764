import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { FoodBoxCustomerPortalState } from './customer-portal.types';
import { FoodBoxDeliveryWeekInfo } from '../../types/food-box.types';

const store = (set: any, get: any) => ({
  // Add values here

  // actions: setters
  setSelectedOrderWeekInfo: (weekInfo: FoodBoxDeliveryWeekInfo) => set(() => ({
    selectedOrderWeekInfo: weekInfo
  })),
  resetAllData: () => set(() => ({
    selectedOrderWeekInfo: undefined
  })),
});

// @ts-ignore
export const useFoodBoxCustomerPortalStore = create<FoodBoxCustomerPortalState>()(devtools(store, { name: 'FoodBoxCustomerPortalState' }));
