import { store } from "../../store";
import * as constants from '../../store/shared/shared.constants'
import { FFFRequestInfo as ApiRequestInfo } from "../../types/shared-store.types";
import { PartialLoads } from "../../types/spinner.types";

export const spinnerService = {
  startGlobalSpinner,
  endGlobalSpinner,
  getApiRequestInfo,
  showPartialSpinner,
  hidePartialSpinner,
  startApiRequest,
  endApiRequest
}

function showGlobalSpinner() {
  store.dispatch({ type: constants.SHOW_GLOBAL_SPINNER });
}

function hideGlobalSpinner() {
  store.dispatch({ type: constants.HIDE_GLOBAL_SPINNER });
}

function showPartialSpinner(key: PartialLoads) {
  store.dispatch({ type: constants.SHOW_PARTIAL_SPINNER, key });
}

function hidePartialSpinner(key: PartialLoads) {
  store.dispatch({ type: constants.HIDE_PARTIAL_SPINNER, key });
}

function dispatchApiRequestStart(endpoint: string) {
  store.dispatch({ type: constants.START_API_REQUEST, endpoint });
}

function dispatchApiRequestEnd(endpoint: string) {
  store.dispatch({ type: constants.END_API_REQUEST, endpoint });
}

function getApiRequestInfo(endpoint: string = ''): ApiRequestInfo {
  return {
    requestPending: true,
    spinnerVisible: false,
    endpoint
  };
}

function startGlobalSpinner() {
  var requestInfo = spinnerService.getApiRequestInfo();
  setTimeout(() => {
    if (requestInfo.requestPending) {
      showGlobalSpinner();
      requestInfo.spinnerVisible = true;
    }
  }, 500);
  return requestInfo;
}

function endGlobalSpinner(requestInfo: ApiRequestInfo) {
  requestInfo.requestPending = false;
  if (requestInfo.spinnerVisible) {
    hideGlobalSpinner();
  }
}

function startApiRequest(endpoint: string) {
  var requestInfo = spinnerService.getApiRequestInfo(endpoint);
  setTimeout(() => {
    if (requestInfo.requestPending) {
      dispatchApiRequestStart(endpoint);
      requestInfo.spinnerVisible = true;
    }
  }, 500);
  return requestInfo;
}

function endApiRequest(requestInfo: ApiRequestInfo) {
  requestInfo.requestPending = false;
  if (requestInfo.spinnerVisible) {
    dispatchApiRequestEnd(requestInfo.endpoint || '')
  }
}