import { AppState } from "../../../../types/shared-store.types";
import { connect } from "react-redux";
import GlobalSpinner from "../../components/Spinner/GlobalSpinner";

const mapStateToProps = (state: AppState) => {
  return {
    show: state.spinner.globalSpinner.pendingRequestCount > 0
  }
}

export default connect(mapStateToProps)(GlobalSpinner)