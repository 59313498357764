import { BusinessUserActions } from "./business-user.actions";
import * as actionTypes from "./business-user.constants";
import { BusinessUserState } from "../../types/business-user.types";

export const BusinessUserReducer = {
  businessUser,
};

function businessUser(
  state: BusinessUserState = {},
  action: BusinessUserActions
) {
  switch (action.type) {
    case actionTypes.LOAD_MANAGE_BUSINESS_USER_INFO: {
      return { ...state, businessUserInfo: action.businessUserInfo };
    }
    case actionTypes.LOAD_PAYMENT_CARDS: {
      return {
        ...state,
        cardDetails: action.cardDetails ||undefined,
      };
    }
  }
  return state;
}
