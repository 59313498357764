import { utilsService } from './../services/shared/utils.service';
import { DEFAULT_LANGUAGE_ID } from "../constants";
import { sessionService } from "../services/shared/session.service";

/**
 * Contains formatters for different date time formats used in FFF.
 * This module will convert UTC times (suffixed with a 'Z') to local
 * times prior to formatting them into string but local times 
 * (just date and time with no suffixes) will be treated as they are.
 */

export const dateTimeUtils = {
  toIsoDateFormatString,
  toLocalIsoDateFormatString,
  toBasicDateFormatString,
  toDateFormatString,
  toDateFormatWithTimeString,
  toMonthYearFormatString,
  toDateTimeMediumFormatString,
  toDateMediumFormatString,
  toDateMediumWithYearFormatString,
  toDateMediumFormatWithOrdinalString,
  toDayFormatWithOrdinalString,
  toDayShortFormatWithOrdinalString,
  toDateAndMonthFormatWithOrdinalString,
  toDateAndMonthShortFormatWithOrdinalString,
  toDateFormatWithOrdinalString,
  toLongDayMonthDateFormatString,
  toTimeMediumFormatString,
  toDateRangeAndOptionalMonthShortFormatString
}

/**
 * (2021-02-19)
 * @param date Date to be formatted
 * @returns formatted string
 */
function toIsoDateFormatString(date: Date) {
  return date.toISOString().split('T')[0];
}

/**
 * (2021-02-19)
 * @param date Date to be formatted
 * @returns formatted string
 */
function toLocalIsoDateFormatString(date: Date) {
  const formatterOptions: any = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  const formatter = new Intl.DateTimeFormat('en-GB', formatterOptions);
  const parts = formatter.formatToParts(date);

  const yearPart = parts.find(part => part.type === 'year');
  const monthPart = parts.find(part => part.type === 'month');
  const dayPart = parts.find(part => part.type === 'day');

  const year = yearPart && yearPart.value;
  const month = monthPart && monthPart.value;
  const day = dayPart && dayPart.value;

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

/**
 * (Fri, 19 November 2021)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toDateMediumWithYearFormatString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    month: "long",
    day: "numeric",
    year: "numeric",
    weekday: "short"
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions)
  const dateStr = formatter.format(date);

  return dateStr;
}

/**
 * (Fri, 19 November)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toDateMediumFormatString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    month: "long",
    day: "numeric",
    weekday: "short"
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions)
  const dateStr = formatter.format(date);

  return dateStr;
}

/**
 * (Friday, 19th November)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toDateMediumFormatWithOrdinalString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    month: "long",
    day: "numeric",
    weekday: "long"
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions);
  const dateStr = formatter.formatToParts(date)
    .map(({ type, value }) => {
      switch (type) {
        case 'day': return utilsService.getWithOrdinalSuffix(Number(value));
        default: return value;
      }
    })
    .join('');

  return dateStr;
}

/**
 * (19th Friday)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toDayFormatWithOrdinalString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    weekday: "long",
    day: "numeric"
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions);
  const dateStr = formatter.formatToParts(date)
    .map(({ type, value }) => {
      switch (type) {
        case 'day': return utilsService.getWithOrdinalSuffix(Number(value));
        default: return value;
      }
    })
    .join('');

  return dateStr;
}

/**
 * (19th Fri)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toDayShortFormatWithOrdinalString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    weekday: "short",
    day: "numeric"
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions);
  const dateStr = formatter.formatToParts(date)
    .map(({ type, value }) => {
      switch (type) {
        case 'day': return utilsService.getWithOrdinalSuffix(Number(value));
        default: return value;
      }
    })
    .join('');

  return dateStr;
}

/**
* 19th
* @param date Date to be formatted
* @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
* @returns formatted string
*/
function toDateFormatWithOrdinalString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    day: "numeric",
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions);
  const dateStr = formatter.formatToParts(date)
    .map(({ type, value }) => {
      switch (type) {
        case 'day': return utilsService.getWithOrdinalSuffix(Number(value));
        default: return value;
      }
    })
    .join('');

  return dateStr;
}

/**
* 19th November
* @param date Date to be formatted
* @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
* @returns formatted string
*/
function toDateAndMonthFormatWithOrdinalString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    month: "long",
    day: "numeric",
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions);
  const dateStr = formatter.formatToParts(date)
    .map(({ type, value }) => {
      switch (type) {
        case 'day': return utilsService.getWithOrdinalSuffix(Number(value));
        default: return value;
      }
    })
    .join('');

  return dateStr;
}

/**
 * (19 - 20 Sep || 19 Aug - 4 Sep)
 * @param fromDate Start date of the range
 * @param toDate End date of the range
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toDateRangeAndOptionalMonthShortFormatString(fromDate: Date, toDate: Date, culture?: string): string {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;

  // Get day and month from the `fromDate`
  const fromDay: number = fromDate.getDate();
  const fromMonth: string = fromDate.toLocaleDateString(culture, { month: 'short' });

  // Get day and month from the `toDate`
  const toDay: number = toDate.getDate();
  const toMonth: string = toDate.toLocaleDateString(culture, { month: 'short' });

  // If the months are the same, return "1 - 8 Sep" format
  if (fromMonth === toMonth) {
    return `${fromDay} - ${toDay} ${toMonth}`;
  }

  // If the months are different, return "27 Aug - 2 Sep" format
  return `${fromDay} ${fromMonth} - ${toDay} ${toMonth}`;
}

/**
* 19th Nov
* @param date Date to be formatted
* @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
* @returns formatted string
*/
function toDateAndMonthShortFormatWithOrdinalString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    month: "short",
    day: "numeric",
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions);
  const dateStr = formatter.formatToParts(date)
    .map(({ type, value }) => {
      switch (type) {
        case 'day': return utilsService.getWithOrdinalSuffix(Number(value));
        default: return value;
      }
    })
    .join('');

  return dateStr;
}

/**
 * (Tue, 19 December 2021, 2:44 am)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toDateTimeMediumFormatString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "short",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: 'h12'
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions)
  const dateStr = formatter.format(date);

  return dateStr;
}

/**
 * (19-Dec-2001)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toDateFormatString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    year: "numeric",
    month: "short",
    day: "numeric"
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions)

  const dateStr = formatter.formatToParts(date)
    .map(({ type, value }) => {
      switch (type) {
        case 'literal': return `-`;
        default: return value;
      }
    })
    .join('');

  return dateStr;
}

/**
 * (19-Dec-2001 10:30 PM)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toDateFormatWithTimeString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const dateFormatterOptions: any = {
    year: "numeric",
    month: "short",
    day: "numeric",
  }

  const timeFormatterOptions: any = {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: 'h12',
  }

  const dateStr = new Intl.DateTimeFormat(culture, dateFormatterOptions)
    .formatToParts(date)
    .map(({ type, value }) => {
      switch (type) {
        case 'literal': return `-`;
        default: return value;
      }
    })
    .join('');

  const timeStr = new Intl.DateTimeFormat(culture, timeFormatterOptions)
    .format(date)

  return `${dateStr} ${timeStr}`;
}

/**
 * (19/12/2001)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toBasicDateFormatString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const dateStr = new Intl.DateTimeFormat(culture, {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  })
    .format(date)

  return dateStr;
}

/**
 * (December 2001)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toMonthYearFormatString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  var dateStr = date.toLocaleDateString(
    culture,
    {
      year: 'numeric',
      month: 'long'
    }
  );

  return dateStr;
}

/**
 * (Thursday Jan 26)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toLongDayMonthDateFormatString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatter = new Intl.DateTimeFormat(culture, { weekday: 'long', month: 'short', day: 'numeric' });
  const parts = formatter.formatToParts(date);

  const formattedDate = parts
    .map(part => {
      if (part.type === 'literal') {
        return ' ';
      }
      return part.value;
    })
    .join('');

  return formattedDate;
}

/**
 * (2:44 am)
 * @param date Date to be formatted
 * @param culture Culture string for localization (eg; en-GB, en-US, fr-FR)
 * @returns formatted string
 */
function toTimeMediumFormatString(date: Date, culture?: string) {
  let configs = sessionService.getTenantConfiguration();
  culture = culture || configs && configs['Culture'] || DEFAULT_LANGUAGE_ID;
  date = new Date(date);

  const formatterOptions: any = {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: 'h12'
  }

  const formatter = new Intl.DateTimeFormat(culture, formatterOptions)
  const dateStr = formatter.format(date);

  return dateStr;
}