export const LOAD_FFF_MEALS = 'LOAD_FFF_MEALS'
export type LOAD_FFF_MEALS = typeof LOAD_FFF_MEALS

export const LOAD_SUPPLEMENTARY_MEALS = 'LOAD_SUPPLEMENTARY_MEALS'
export type LOAD_SUPPLEMENTARY_MEALS = typeof LOAD_SUPPLEMENTARY_MEALS

export const LOAD_ELIGIBLE_FFF_MEALS_FOR_ALL_TEMPLATES = 'LOAD_ELIGIBLE_FFF_MEALS_FOR_ALL_TEMPLATES'
export type LOAD_ELIGIBLE_FFF_MEALS_FOR_ALL_TEMPLATES = typeof LOAD_ELIGIBLE_FFF_MEALS_FOR_ALL_TEMPLATES

export const LOAD_ELIGIBLE_FFF_MEALS_FOR_RECENT_TEMPLATES = 'LOAD_ELIGIBLE_FFF_MEALS_FOR_RECENT_TEMPLATES'
export type LOAD_ELIGIBLE_FFF_MEALS_FOR_RECENT_TEMPLATES = typeof LOAD_ELIGIBLE_FFF_MEALS_FOR_RECENT_TEMPLATES

export const LOAD_FFF_EXTRAS = 'LOAD_FFF_EXTRAS'
export type LOAD_FFF_EXTRAS = typeof LOAD_FFF_EXTRAS

export const LOAD_FFF_DAYS = 'LOAD_FFF_DAYS'
export type LOAD_FFF_DAYS = typeof LOAD_FFF_DAYS

export const LOAD_ORDER_INFO = 'LOAD_ORDER_INFO'
export type LOAD_ORDER_INFO = typeof LOAD_ORDER_INFO

export const LOAD_ORDER_MEAL_TEMPLATES = 'LOAD_ORDER_MEAL_TEMPLATES'
export type LOAD_ORDER_MEAL_TEMPLATES = typeof LOAD_ORDER_MEAL_TEMPLATES

export const CLEAR_MEAL_BALANCING_STATE = 'CLEAR_MEAL_BALANCING_STATE'
export type CLEAR_MEAL_BALANCING_STATE = typeof CLEAR_MEAL_BALANCING_STATE

export const LOAD_BALANCER_VERSION = 'LOAD_BALANCER_VERSION'
export type LOAD_BALANCER_VERSION = typeof LOAD_BALANCER_VERSION

export const UPDATE_MEAL_TEMPLATE = 'UPDATE_MEAL_TEMPLATE'
export type UPDATE_MEAL_TEMPLATE = typeof UPDATE_MEAL_TEMPLATE

export const LOAD_TEMPLATE_LOGS = 'LOAD_TEMPLATE_LOGS'
export type LOAD_TEMPLATE_LOGS = typeof LOAD_TEMPLATE_LOGS

export const LOAD_MEAL_ALLERGENS = 'LOAD_MEAL_ALLERGENS'
export type LOAD_MEAL_ALLERGENS = typeof LOAD_MEAL_ALLERGENS

