import { HolidayListActions } from "./holiday.actions";
import * as constants from "../holiday/holiday.constants";
import { HolidayState } from "../../types/holiday-store.type";



const holidayReducer = (state: HolidayState = {}, action: HolidayListActions) => {
    switch (action.type) {
        case constants.LOAD_HOLIDAYS: {
            return {
                ...state, list: {
                    items: action.items,
                    total: action.total
                }
            }
        }
        case constants.GET_HOLIDAYS: {
            return {
                ...state, dateList: action.holidays
            }
        }
    }

    return state;
}

export const HolidayReducer = {
    holiday: holidayReducer
};