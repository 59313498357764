import * as constants from "./promotional-code.constants";

import { PromotionalCodeActions } from "./promotional-code.actions";
import { PromotionalCodesState } from "../../types/promotional-code.types";

export const promotionalCodesReducer = {
  promotionalCodes
}

function promotionalCodes(state: PromotionalCodesState = {}, action: PromotionalCodeActions) {
  switch (action.type) {
    case constants.LOAD_PROMOTIONAL_CODES: {
      return { ...state, items: action.items, total: action.total }
    }
    case constants.LOAD_SELECTED_PROMOTIONAL_CODE: {
      return { ...state, selectedItem: action.item }
    }
    case constants.DESELECT_PROMOTIONAL_CODE: {
      return { ...state, selectedItem: undefined }
    }
    case constants.LOAD_PROMOTIONAL_CODE_INFO: {
      return { ...state, info: action.info }
    }
    case constants.INVALIDATE_PROMO_CODE: {
      return { ...state, info: {} }
    }
  }
  return state;
}