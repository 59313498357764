export const TENANT_LOAD_ALL = "TENANT_LOAD_ALL";
export type TENANT_LOAD_ALL = typeof TENANT_LOAD_ALL;

export const TENANT_DB_SERVERS = "TENANT_DB_SERVERS";
export type TENANT_DB_SERVERS = typeof TENANT_DB_SERVERS;

export const TENANT_LOAD_EDITING_TENANT = "TENANT_LOAD_EDITING_TENANT";
export type TENANT_LOAD_EDITING_TENANT = typeof TENANT_LOAD_EDITING_TENANT;

export const TENANT_LOAD_SETTINGS = "TENANT_LOAD_SETTINGS";
export type TENANT_LOAD_SETTINGS = typeof TENANT_LOAD_SETTINGS;

export const TENANT_LOAD_FEATURE_REGISTRY = "TENANT_LOAD_FEATURE_REGISTRY";
export type TENANT_LOAD_FEATURE_REGISTRY = typeof TENANT_LOAD_FEATURE_REGISTRY;