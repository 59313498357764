import { store } from "../../store";
import { LOAD_LOOKUPS } from "../../store/lookup/lookup.reducer";
import { FoodBoxLookupsWithHeadersType } from "../../types/food-box.types";
import { MealGenLookupHeaders } from "../../types/lookup-constants.types";
import { Lookup, LookupHeader } from "../../types/lookup-store.types";
import { EntitySaveResult, ListItemDto, ReactSelectOption, SearchFilterBase, SearchResultsDto } from "../../types/shared-dto.types";
import { apiService } from "./api.service";

function fetchLookups() {
  return apiService.get<Lookup[]>('lookups', 'withHeaders')
    .then(result => {
      store.dispatch({ type: LOAD_LOOKUPS, lookUps: result })
    })
}

function fetchGeneralLookups() {
  return apiService.get<Lookup[]>('lookups', 'General/withHeaders')
    .then(result => {
      store.dispatch({ type: LOAD_LOOKUPS, lookUps: result })
    })
}

function getLookupsAsListItemsByHeader(headerCode: string) {
  var lookups: ListItemDto[] = (store.getState() as any).lookup.lookUps
    .filter((l: any) => l.headerCode == headerCode)
    .map((val: any) => ({
      key: val.id,
      value: val.description
    }));
  return lookups;
}

function getLookupCodesAsListItemsByHeader(headerCode: string) {
  var lookups: ListItemDto[] = (store.getState() as any).lookup.lookUps
    .filter((l: any) => l.headerCode == headerCode)
    .map((val: any) => ({
      key: val.id,
      value: val.code
    }));
  return lookups;
}

function getDisplayText(headerCode: string, id: string) {
  var lookup = (store.getState() as any).lookup.lookUps.find((l: any) => l.headerCode == headerCode && l.id == id);
  return lookup && lookup.description || '';
}

function getLookupsByHeader(headerCode: string) {
  var lookups = store.getState().lookup.lookUps.filter(l => l.headerCode == headerCode);
  return lookups;
}

function getMealGenLookupsByHeaderCode(headerCode: string) {
  return apiService.get<Lookup[]>('lookups', `MealGen/${headerCode}`)
}

function getMealDayLookupsByOptionList() {
  return getMealGenLookupsByHeaderCode(MealGenLookupHeaders.MealDays).then((result: Lookup[]) => {
    return result.map(({ id, code }) => ({ label: code, value: id } as ReactSelectOption))
  })
}

function fetchLookupHeaders(filter: SearchFilterBase) {
  return apiService.get<SearchResultsDto<LookupHeader>>('lookups', 'Headers', [], filter)
}

function fetchLookupsByHeader(headerCode: string) {
  return apiService.get<Lookup[]>('lookups', `Header/${headerCode}`, [])
}

function createLookup(values: Lookup) {
  return apiService.post<EntitySaveResult>('lookups', '', values)
}

function deleteLookup(lookupId: string) {
  return apiService.delete('lookups', `Lookup/${lookupId}`)
}

function fetchLookupById(id: string) {
  return apiService.get<Lookup>('lookups', `Lookup/${id}`)
}

function updateLookup(id: string, lookup: Lookup) {
  return apiService.put<EntitySaveResult>('lookups', `Lookup/${id}`, lookup);
}

function updateLookupStatus(lookupId: string, status: boolean) {
  return apiService.patch<EntitySaveResult>('lookups', `Lookup/${lookupId}/Status`, undefined, undefined, { status });
}

function getFoodBoxLookupsWithHeaders() {
  return apiService.get<FoodBoxLookupsWithHeadersType>('lookups', 'FoodBox/WithHeaders', undefined);
}

export const lookupService = {
  fetchLookups,
  fetchGeneralLookups,
  getLookupsAsListItemsByHeader,
  getLookupCodesAsListItemsByHeader,
  getDisplayText,
  getLookupsByHeader,
  getMealGenLookupsByHeaderCode,
  getMealDayLookupsByOptionList,
  fetchLookupHeaders,
  fetchLookupsByHeader,
  createLookup,
  deleteLookup,
  fetchLookupById,
  updateLookup,
  updateLookupStatus,
  getFoodBoxLookupsWithHeaders
}
