export const LOAD_PROMOTIONAL_CODES = 'LOAD_PROMOTIONAL_CODES'
export type LOAD_PROMOTIONAL_CODES = typeof LOAD_PROMOTIONAL_CODES

export const LOAD_SELECTED_PROMOTIONAL_CODE = 'LOAD_SELECTED_PROMOTIONAL_CODE'
export type LOAD_SELECTED_PROMOTIONAL_CODE = typeof LOAD_SELECTED_PROMOTIONAL_CODE

export const DESELECT_PROMOTIONAL_CODE = 'DESELECT_PROMOTIONAL_CODE'
export type DESELECT_PROMOTIONAL_CODE = typeof DESELECT_PROMOTIONAL_CODE

export const LOAD_PROMOTIONAL_CODE_INFO = 'LOAD_PROMOTIONAL_CODE_INFO'

export type LOAD_PROMOTIONAL_CODE_INFO = typeof LOAD_PROMOTIONAL_CODE_INFO

export const INVALIDATE_PROMO_CODE = 'INVALIDATE_PROMO_CODE'

export type INVALIDATE_PROMO_CODE = typeof INVALIDATE_PROMO_CODE