import { DiscountCodeType } from '../types/discount-code.types';
import { ReferralLink } from '../types/loyalty-token.types';
import { DataSeedResult, ListItemDto, MarketingSubscriptionDto, SearchFilterBase, SearchResultsDto } from '../types/shared-dto.types';
import { UserAccountInfoUpdateResultDto, UserAddResult, UserBasicDetails, UserDeleteResult, UserEditResult, UserListResult, UserProfileDto, UsersSearchFilter } from '../types/user-dto.types';
import { User } from '../types/user-store.types';
import { PortalTypes } from './../constants/shared.constants';
import { TransactionPlatform } from './../types/fff.enums';
import { apiService } from './shared/api.service';
import { sessionService } from './shared/session.service';

const getAll = (request: UsersSearchFilter) => {
  return apiService.get<UserListResult>('users', '', [], request);
}

const getUser = (id: string) => {
  return apiService.get<User>('users', id);
}

const getCurrentUserBasicInfo = () => {
  return apiService.get<UserBasicDetails>('users', 'Current/BasicInfo');
}

const getAdminUserBasicInfo = () => {
  return apiService.get<User>(sessionService.getPortalType() == PortalTypes.franchisingAdmin ? 'partialCustomers' : 'users', 'Current/BasicInfo');
}

const getAccountInfoOfCurrentUser = () => {
  return apiService.get<UserProfileDto>('users', 'info');
}

const editAccountInfo = (user: UserProfileDto) => {
  return apiService.put<UserAccountInfoUpdateResultDto>('users', 'info', user)
}

const updatePrimaryEmail = (email: string, password: string) => {
  return apiService.patch('users', 'update/email', { email, password });
}

const addUser = (user: User) => {
  return apiService.post<UserAddResult>('users', '', user)
}

const editUser = (user: User) => {
  return apiService.put<UserEditResult>('users', '', user)
}

const deleteUser = (id: string) => {
  return apiService.patch<UserDeleteResult>('users', 'delete', null, [id]);
}

const getReferralLinkForCurrentUser = (discountcodeType: DiscountCodeType) => {
  return apiService.get<ReferralLink>('users', 'referralLink', undefined, { discountcodeType });
}

const subscribeToEmailMarketing = (dto: MarketingSubscriptionDto) => {
  return apiService.post<ReferralLink>('users', 'marketing/subscribe', dto);
}

const subscribeToSmsMarketing = (dto: MarketingSubscriptionDto) => {
  return apiService.post('users', 'marketing/sms/subscribe', dto);
}

const checkIfOrderPlacementAllowedByEmail = (email: string) => {
  return apiService.get<boolean>('users', 'order/allowed', [], { email }, true);
}

const isFoodBoxNewOrderPlacementAllowedByEmail = (email: string) => {
  return apiService.get<boolean>('users', 'FoodBox/NewOrderPlacement/Allowed', undefined, { email });
}

const getPaymentPlatform = (userId?: string) => {
  return apiService.get<TransactionPlatform>('users', 'PaymentPlatform', [], { userId });
}

const bulkUploadCheckoutUsers = (file: any) => {
  return apiService.post<DataSeedResult>('users', 'BulkUpload/CheckoutUsers', file, [], undefined, true);
}

function getAdminsAndStaffMembers(filter: SearchFilterBase) {
  return apiService.get<SearchResultsDto<ListItemDto>>('users', 'StaffMembers/listItem', [], filter);
}

function isAmbassador(userId: string) {
  return apiService.get<boolean>('users', `${userId}/IsAmbassador`);
}

export const userService = {
  getAll,
  getUser,
  getAccountInfoOfCurrentUser,
  editAccountInfo,
  addUser,
  editUser,
  deleteUser,
  getReferralLinkForCurrentUser,
  getCurrentUserBasicInfo,
  subscribeToEmailMarketing,
  updatePrimaryEmail,
  checkIfOrderPlacementAllowedByEmail,
  isFoodBoxNewOrderPlacementAllowedByEmail,
  getPaymentPlatform,
  bulkUploadCheckoutUsers,
  getAdminsAndStaffMembers,
  getAdminUserBasicInfo,
  isAmbassador,
  subscribeToSmsMarketing
}