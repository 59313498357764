import * as constants from './postcode.constants';

import { PostcodeListActions } from './postcode.actions';
import { PostcodeState } from '../../types/postcode-store.types';

const postcodeReducer = (state: PostcodeState = {}, action: PostcodeListActions) => {
  switch (action.type) {
    case constants.LOAD_POSTCODE_LIST: {
      return {
        ...state, list: {
          items: action.items,
          total: action.total
        }
      }
    }
  }

  return state;
}

export const PostcodeReducer = {
  postcode: postcodeReducer
};