import React from "react";

const EmptyLayout = React.lazy(
  () => import("../layouts/components/EmptyLayout/EmptyLayout")
);
const PublicLayout = React.lazy(
  () => import("../layouts/components/PublicLayout/PublicLayout")
);
const F45Layout = React.lazy(
  () => import("../layouts/components/F45Layout/F45Layout")
);
const AdminLayout = React.lazy(
  () => import("../layouts/components/AdminLayout/AdminLayout")
);
const FranchiseAdminLayout = React.lazy(
  () => import("../layouts/components/AdminLayout/FranchiseAdminLayout")
);
const NotFoundPage = React.lazy(
  () => import("../modules/shared/components/Response/NotFoundPage")
);

function getBaseRoutes(isF45Enabled: boolean, isF45Subscribed: boolean) {
  return [
    {
      path: "/account",
      component: EmptyLayout,
      exact: false,
    },
    {
      path: "/login",
      component: EmptyLayout,
      exact: false,
    },
    {
      path: "/franchise-admin",
      component: FranchiseAdminLayout,
    },
    {
      path: "/admin",
      component: AdminLayout,
      exact: false,
    },
    {
      path: "/f45-challenge-meal-plans",
      component: !isF45Subscribed ? NotFoundPage : isF45Enabled ? F45Layout : EmptyLayout,
      exact: false,
    },
    {
      path: "/",
      component: PublicLayout,
      exact: false,
    },
  ];
}

export default getBaseRoutes;
