import { SeasonalDiscountCodesState } from "../../types/discount-code.types";
import { DiscountCodeActions } from "./discount-code.actions";
import * as constants from "./discount-code.constants";

export const discountCodesReducer = {
  seasonalDiscountCodes
}

function seasonalDiscountCodes(state: SeasonalDiscountCodesState = {}, action: DiscountCodeActions) {
  switch(action.type) {
    case constants.LOAD_SEASONAL_DISCOUNT_CODES: {
      return {...state, items: action.items, total: action.total}
    }
    case constants.LOAD_SELECTED_DISCOUNT_CODE: {
      return {...state, selectedItem: action.item }
    }
    case constants.DESELECT_DISCOUNT_CODE: {
      return {...state, selectedItem: undefined}
    }
  }
  return state;
}