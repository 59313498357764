import { WaiverType } from '../../types/discount-code.types';
import { FrozenFoodState, FrozenFoodDiscountCodeInfo } from './../../types/frozen-food.types';
import { FrozenFoodActions } from './frozen-food.actions';
import * as constants from './frozen-food.constants';

export const frozenFoodReducer = {
  frozenFood
}

function frozenFood(state: FrozenFoodState = {}, action: FrozenFoodActions) {
  switch (action.type) {
    case constants.LOAD_FROZEN_FOOD_INVENTORY: {
      return { ...state, inventory: action.inventory }
    }
    case constants.UPDATE_INVENTORY_SELECTION: {
      let selectedItems = state.selectedInventory || [];
      let index = selectedItems.findIndex(i => i.inventoryId == action.selectedItem.inventoryId);

      if (index >= 0) {
        selectedItems.splice(index, 1);
      }

      selectedItems = action.selectedItem.quantity > 0 ? [...selectedItems, action.selectedItem] : [...selectedItems];

      let subTotal = selectedItems.reduce((subTotal, item) => {
        subTotal += (item.quantity * item.unitPrice);
        return subTotal;
      }, 0);

      const discountCodeInfo = state.discountCodeInfo;
      let discount = discountCodeInfo ? calculateDiscount(subTotal, discountCodeInfo) : 0;

      return { ...state, selectedInventory: selectedItems, amount: { ...state.amount, subTotal, discount } };
    }
    case constants.UPDATE_INVENTORY_SELECTION_ALL: {
      let selectedItems = [...action.selectedItems];

      let subTotal = selectedItems.reduce((subTotal, item) => {
        subTotal += (item.quantity * item.unitPrice);
        return subTotal;
      }, 0);

      const discountCodeInfo = state.discountCodeInfo;
      let discount = discountCodeInfo ? calculateDiscount(subTotal, discountCodeInfo) : 0;

      return { ...state, selectedInventory: selectedItems, amount: { ...state.amount, subTotal, discount } };
    }
    case constants.TOGGLE_FROZEN_FOOD_ORDER_FORM: {
      return { ...state, orderFormVisible: !state.orderFormVisible }
    }
    case constants.UPDATE_FROZEN_FOOD_CUSTOMER_INFO: {
      return { ...state, customer: action.customer }
    }
    case constants.RESET_FROZEN_FOOD_ORDER_FORM: {
      return { ...state, selectedInventory: [], customer: undefined, amount: { ...state.amount, subTotal: undefined, discount: undefined }, discountCodeInfo: {} }
    }
    case constants.UPDATE_FROZEN_FOOD_DELIVERY_CHARGE: {
      return { ...state, amount: { ...state.amount, delivery: action.charge } };
    }
    case constants.ADD_DISCOUNT: {
      const subTotal = state.amount && state.amount.subTotal || 0;
      const discountCodeInfo = action.discountCodeInfo;

      let discount = discountCodeInfo ? calculateDiscount(subTotal, discountCodeInfo) : 0;

      return { ...state, amount: { ...state.amount, discount }, discountCodeInfo };
    }
    case constants.REMOVE_DISCOUNT: {
      return { ...state, amount: { ...state.amount, discount: 0 }, discountCodeInfo: undefined }
    }
    case constants.LOAD_FROZEN_ORDER_ITEM_DETAILS: {   
      return {...state, orderDetails : action.orderDetails}
    }
  }
  return state;
}

function calculateDiscount(amount: number, discountInfo: FrozenFoodDiscountCodeInfo) {
  let discount = 0;

  switch (discountInfo.waiverType) {
    case WaiverType.Percentage:
      discount = amount * (discountInfo.discountPercentage || 0) / 100;
      break;
    case WaiverType.Amount:
      discount = Math.min(amount, (discountInfo.discountAmount || 0));
      break;
  }

  return discount;
}