import * as constants from './system.constants';
import { SystemState } from '../../types/system.types';
import { SystemActionType } from './system.actions';

export const systemReducer = {
  systemData
}

function systemData(state: SystemState = {}, action: SystemActionType) {
  switch (action.type) {
    case constants.LOAD_SYSTEM_AUDIT: {
      return { ...state, auditLog: action.auditLog }
    }
    case constants.LOAD_ACTIVITY_LOG: {
      return { ...state, activityLog: action.activityLog }
    }
    case constants.LOAD_CONFIG_SETTINGS: {
      return { ...state, configSettings: action.configSettings }
    }
    case constants.LOAD_GENERAL_CONFIG_SETTINGS: {
      return { ...state, generalConfigSettings: action.generalConfigSettings }
    }
    case constants.LOAD_ENTITY_TYPES: {
      return { ...state, auditLoggedEntities: action.entityTypes }
    }
    case constants.LOAD_HOMEPAGE_MANAGED_CONTENT: {
      return { ...state, homePageManagedContent: action.data }
    }
    case constants.LOAD_DEFAULT_PAYMENT_PLATFORM: {
      return { ...state, defaultPaymentPlatform: action.defaultPaymentPlatform }
    }
    case constants.LOAD_FEATURE_REGISTRY: {
      return { ...state, featureRegistry: action.featureRegistry }
    }
    case constants.LOAD_SYSTEM_ERROR_LOG: {
      return {...state, systemErrorLog: action.logs}
    }
    case constants.SYSTEM_ERROR_LOG_RESOLVE: {
      if(state && state.systemErrorLog) {
        const newItems = [...state.systemErrorLog.items];
        const item = newItems.find(l => l.id === action.id)
        if (item) item.resolved = true

        return {...state, systemErrorLog: {...state.systemErrorLog, items: newItems}}
      }
      return state;      
    }
    case constants.SYSTEM_ERROR_LOG_UNRESOLVE: {
      if(state && state.systemErrorLog) {
        const newItems = [...state.systemErrorLog.items];
        const item = newItems.find(l => l.id === action.id)
        if (item) item.resolved = false

        return {...state, systemErrorLog: {...state.systemErrorLog, items: newItems}}
      }
      return state;      
    }
    default: return state;
  }
}
