import { FeatureRegistry } from '../types/feature-store.types';
import { FeatureAccessThreshold } from '../types/fff.enums';
import { apiService } from "./shared/api.service";
import { sessionService } from './shared/session.service';

export const featureRegistryService = {
  getFeatureRegistry,
  isFeatureEnabled
}

function getFeatureRegistry() {
  return apiService.get<FeatureRegistry>("FeatureRegistry");
}

function isFeatureEnabled(featureCode?: string, featureRegistry?: FeatureRegistry, permission?: string, threshold?: FeatureAccessThreshold) {

  var isFeatureEnabled = permission ? sessionService.hasPermission_obsolete(permission) : true;

  if (featureRegistry && featureCode) {
    let featureThreshold = featureRegistry ? featureRegistry[featureCode] : FeatureAccessThreshold.Disabled;
    var isFeatureDisabled = (threshold || FeatureAccessThreshold.Full) < featureThreshold;
    isFeatureEnabled = isFeatureEnabled && !isFeatureDisabled
  }
  return isFeatureEnabled;
}